import dayjs, { Dayjs, isDayjs } from "dayjs";
import { DateUtils } from "../helpers/dateUtils";

export interface ILocalTime {
  hour: number;
  minute: number;
  second?: number;
}

export type LocalTimeTypeShort = "h" | "m" | "s";

export type LocalTimeTypeLong = "second" | "minute" | "hour";

export type LocalTimeTypeLongPlural = "seconds" | "minutes" | "hours";

export type LocalTimeType = LocalTimeTypeLong | LocalTimeTypeLongPlural | LocalTimeTypeShort;

export class LocalTime implements ILocalTime {
  hour: number;
  minute: number;
  second: number;

  /**
   * It takes a value, and if it's a string, it splits it on the colon and assigns the values to the hour, minute, and
   * second properties. If it's a number, it does some math to figure out the hour, minute, and second. If it's a Date, it
   * assigns the hour, minute, and second properties to the corresponding values from the Date. If it's a Dayjs object, it
   * assigns the hour, minute, and second properties to the corresponding values from the Dayjs object. If it's an object
   * with hour, minute, and second properties, it assigns the hour, minute, and second properties to the corresponding
   * values from the object
   * @param {{ hour?: number, minute?: number, second?: number } | string | number | Date | Dayjs} [value] - The value to
   * be converted to a LocalTime. This can be a string, number, Date, or Dayjs object.
   * @param {boolean} [fromUTC] - If true, the time will be converted to the local timezone.
   */
  constructor(value?: { hour?: number, minute?: number, second?: number } | string | number | Date | Dayjs, fromUTC?: boolean) {
    if (!value) {
      this.hour = 12;
      this.minute = 0;
      this.second = 0;
    } else if (typeof value === "string") {
      const arr = value.split(":");
      this.hour = +arr[0];
      this.minute = +arr[1];
      this.second = !!arr[2] ? +arr[2] : 0;
    } else if (typeof value === "number") {
      this.second = value % 60;
      this.hour = value / 60 / 60;
      this.minute = (value - this.hour * 60 * 60) / 60;
    } else if (value instanceof Date) {
      this.hour = value.getHours();
      this.minute = value.getMinutes();
      this.second = value.getSeconds();
    } else if (isDayjs(value)) {
      this.hour = value.get("h");
      this.minute = value.get("m");
      this.second = value.get("s");
    } else {
      this.hour = value.hour ?? 12;
      this.minute = value.minute ?? 0;
      this.second = value.second ?? 0;
    }
    if (!!value && !!fromUTC) {
      const offset = -dayjs.tzOffset(dayjs());
      const newLT = this.subtract(offset, "m");
      this.hour = newLT.hour;
      this.minute = newLT.minute;
      this.second = newLT.second;
    }
  }

  /**
   * It compares two LocalTime objects.
   * @param {LocalTime | string} a - LocalTime | string
   * @param {LocalTime | string} b - LocalTime | string
   * @returns -1 if a is before b, 1 if a is after b in otherwise 0
   */
  public static Compare(a: LocalTime | string, b: LocalTime | string): number {
    if (typeof a === "string") a = new LocalTime(a);
    if (typeof b === "string") b = new LocalTime(b);
    if (a.isBefore(b)) return -1;
    if (a.isAfter(b)) return 1;
    return 0;
  }

  /**
   * It takes a Date object and returns a LocalTime object
   * @param {Date} date - Date - The date to convert to a LocalTime.
   * @returns A new instance of the LocalTime class.
   */
  private static FromMockDate(date: Date): LocalTime {
    return new LocalTime({ hour: date.getHours(), minute: date.getMinutes(), second: date.getSeconds() });
  }


  /**
   * The hash code is the number of seconds.
   * @returns The number of seconds.
   */
  hashCode(): number {
    return this.second + this.minute * 60 + this.hour * 60 * 60;
  }


  /**
   * It returns the difference between two LocalTime objects.
   * @param {LocalTime} localTime - LocalTime - The LocalTime to compare to.
   * @param {LocalTimeType} [unit] - The unit of time to use in the calculation.
   * @param {boolean} [abs] - If true, the absolute value of the difference will be returned.
   * @returns The difference between the two LocalTime objects in the unit specified.
   */
  diff(localTime: LocalTime, unit?: LocalTimeType, abs?: boolean): number {
    unit = unit ?? "second";
    if (!!abs) return Math.abs(dayjs(this.mockDate()).diff(localTime.mockDate(), unit));
    return dayjs(this.mockDate()).diff(localTime.mockDate(), unit);
  }

  /**
   * It checks if the current time is before or the same as the time passed in.
   * @param {LocalTime} localTime - The local time to compare to.
   * @param {LocalTimeType} [unit] - The unit of time to compare.
   * @returns A boolean value.
   */
  isSameOrBefore(localTime: LocalTime, unit?: LocalTimeType): boolean {
    unit = unit ?? "second";
    return dayjs(this.mockDate()).isSameOrBefore(localTime.mockDate(), unit);
  }

  /**
   * It checks if the current time is the same as the time passed in.
   * @param {LocalTime} localTime - The LocalTime object to compare with.
   * @param {LocalTimeType} [unit] - The unit of time to compare.
   * @returns A boolean value.
   */
  isSame(localTime: LocalTime, unit?: LocalTimeType): boolean {
    unit = unit ?? "second";
    return dayjs(this.mockDate()).isSame(localTime.mockDate(), unit);
  }

  /**
   * It checks if the current time is after the given time.
   * @param {LocalTime} localTime - The local time to compare against.
   * @param {LocalTimeType} [unit] - The unit of time to compare.
   * @returns A boolean value.
   */
  isAfter(localTime: LocalTime, unit?: LocalTimeType): boolean {
    unit = unit ?? "second";
    return dayjs(this.mockDate()).isAfter(localTime.mockDate(), unit);
  }

  /**
   * It checks if the current time is before the given time.
   * @param {LocalTime} localTime - The local time to compare to.
   * @param {LocalTimeType} [unit] - The unit of time to compare.
   * @returns A boolean value.
   */
  isBefore(localTime: LocalTime, unit?: LocalTimeType): boolean {
    unit = unit ?? "second";
    return dayjs(this.mockDate()).isBefore(localTime.mockDate(), unit);
  }

  /**
   * It returns a string representation of the time in the format HH:MM:SS
   * @param {boolean} [withoutSeconds] - boolean - If true, the seconds will not be included in the string.
   * @returns A string with the time in the format HH:MM:SS
   */
  toString(withoutSeconds?: boolean): string {
    return `${this.pad0IfNecessery(this.hour)}:${this.pad0IfNecessery(this.minute)}${!withoutSeconds ? ":" + this.pad0IfNecessery(this.second) : ""}`;
  }

  /**
   * It takes a boolean value indicating whether to use 12-hour time, and a boolean value indicating whether to show
   * seconds, and returns a string representation of the time in the requested format
   * @param {boolean} h12 - boolean - If true, the time will be displayed in 12 hour format.
   * @param {boolean} showSeconds - boolean - whether to show seconds or not
   * @returns A string that represents the time in a human readable format.
   */
  toVisualString(h12: boolean, showSeconds: boolean): string {
    let h = this.hour;
    if (h12) {
      if (h > 12) h -= 12;
      if (h === 0) h = 12;
    }
    let r = `${this.pad0IfNecessery(h)}:${this.pad0IfNecessery(this.minute)}`;
    if (showSeconds) r += `:${this.pad0IfNecessery(this.second)}`;
    if (h12) r += ` ${this.hour >= 12 ? "PM" : "AM"}`;
    return r;
  }

  /**
   * It converts a local time string to a UTC time string
   * @param {boolean} [withSeconds] - If true, the time string will include seconds. If false, the time string will not
   * include seconds.
   * @returns A string in the format of "HH:MM:SS"
   */
  toUTCString(withSeconds?: boolean): string {
    return DateUtils.localTimeStringToUTCTimeString(this.toString(!withSeconds));
  }

  ToLocalDayOffset(): number {
    const offset = -dayjs.tzOffset(dayjs());
    const tmp1 = dayjs(this.mockDate());
    const tmp2 = tmp1.subtract(offset, "m");
    if (tmp1.isSame(tmp2, "d")) {
      return 0;
    } else {
      if (tmp1.isBefore(tmp2)) {
        return 1;
      }
      return -1;
    }
  }

  ToUTCDayOffset(): number {
    const offset = -dayjs.tzOffset(dayjs());
    const tmp1 = dayjs(this.mockDate());
    const tmp2 = tmp1.add(offset, "m");
    if (tmp1.isSame(tmp2, "d")) {
      return 0;
    } else {
      if (tmp1.isBefore(tmp2)) {
        return 1;
      }
      return -1;
    }
  }

  /**
   * It takes a time string, and returns a Date object with the current date and the time string as the time
   * @param [utc=false] - boolean - If true, the time will be in UTC. If false, the time will be in the local timezone.
   * @returns A date object with the current date and the time of the current object.
   */
  mockDate(utc = false): Date {
    return dayjs(dayjs().format("YYYY-MM-DD") + " " + this.toString(), { utc }).toDate();
  }

  /**
   * It adds a value to the current time.
   * @param {number} value - number - The amount of time to add
   * @param {LocalTimeType} unit - LocalTimeType
   * @returns A new LocalTime object with the value of the current LocalTime object added to it.
   */
  add(value: number, unit: LocalTimeType): LocalTime {
    return LocalTime.FromMockDate(dayjs(this.mockDate()).add(value, unit).toDate());
  }

  /**
   * It subtracts a value from a LocalTime object.
   * @param {number} value - number - The amount of time to subtract.
   * @param {LocalTimeType} unit - The unit of time to subtract.
   * @returns A new LocalTime object with the new date.
   */
  subtract(value: number, unit: LocalTimeType): LocalTime {
    return LocalTime.FromMockDate(dayjs(this.mockDate()).subtract(value, unit).toDate());
  }

  /**
   * Given a unit, return the corresponding property of the current object.
   * @param {LocalTimeType} unit - LocalTimeType - The unit of time to get the value of.
   * @returns The value of the property that corresponds to the unit.
   */
  get(unit: LocalTimeType): number {
    return this[LocalTime.typeToProperty(unit)];
  }

  /**
   * It sets the value of a unit of time.
   * @param {LocalTimeType} unit - LocalTimeType
   * @param {number} value - number
   * @returns A new LocalTime object with the same date as the original, but with the specified unit set to the specified
   * value.
   */
  set(unit: LocalTimeType, value: number): LocalTime {
    return LocalTime.FromMockDate(dayjs(this.mockDate()).set(unit, value).toDate());
  }

  /**
   * This function returns a new LocalTime object that is a copy of the current object.
   * @returns A new LocalTime object with the same values as the original.
   */
  clone(): LocalTime {
    return new LocalTime(this);
  }

  /**
   * If the number is less than 10, add a 0 to the beginning of the number
   * @param {number} v - number - the number to be padded
   * @returns A string with the value of v, with a leading 0 if the value is less than 10.
   */
  private pad0IfNecessery(v: number) {
    return String(v).padStart(2, "0");
  }

  private static typeToProperty(type: LocalTimeType) {
    switch (type) {
      case "s":
      case "second":
      case "seconds":
        return "second";
      case "m":
      case "minute":
      case "minutes":
        return "minute";
      case "h":
      case "hour":
      case "hours":
        return "hour";
    }
  }

}

