import { createAction, props } from "@ngrx/store";
import { toastSeverity } from "../models/state.model";
import { IDSType } from "../../../../../commons/consts/navigation.const";
import { AV2Theme } from "../../../../../commons/enums/theme";
import { TypedAction } from "@ngrx/store/src/models";

const actionHeader = "[Core]";

export const setIsMobile = createAction(
  `${actionHeader} set is mobile`,
  props<{ isMobile: boolean; }>()
);

export const setIsTablet = createAction(
  `${actionHeader} set is tablet`,
  props<{ isTablet: boolean; }>()
);

export const showLoader = createAction(
  `${actionHeader} show loader`
);

export const hideLoader = createAction(
  `${actionHeader} hide loader`
);

export const showToast = createAction(
  `${actionHeader} show toast`,
  props<{ severity: toastSeverity; message: string; interpolateParams?: Object; }>()
);

export const locationBack = createAction(
  `${actionHeader} location back`
);

export const locationUp = createAction(
  `${actionHeader} location up`
);

export const navigate = createAction(
  `${actionHeader} navigate`,
  props<{ path: string, idsToReplace?: IDSType, hideLoaderAfterNavigate?: boolean }>()
);

export const setTheme = createAction(
  `${actionHeader} set theme`,
  props<{ theme: AV2Theme; }>()
);

export const changeLanguage = createAction(
  `${actionHeader} change translation language`,
  props<{ locale: string; }>()
);

export const blockContent = createAction(
  `${actionHeader} block content`,
  props<{ block: boolean; }>()
);

export const delayAction = createAction(
  `${actionHeader} delay`,
  props<{ delay?: number; action: TypedAction<any> }>()
);
