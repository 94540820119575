import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {BehaviorSubject, combineLatest, Subscription} from 'rxjs';
import { AV2ListDataSortDirection, AV2ListDataSortRequest } from 'src/app/commons/models/data-list';
import { SortBaseComponent } from 'src/app/shared/sort/sort-base.component';
import {
  deleteTranslation,
  loadAllTranslations,
  updateTranslation
} from '../../store/actions/translations.actions';
import { AV2TranslationState } from '../../store/model/state.model';
import { AV2Translation, AV2TranslationTableColumn } from '../../store/model/translations.model';
import { getTranslationList, getTranslationsTableLoaderState } from '../../store/selectors/translations.selectors';
import { NAVI } from 'src/app/commons/consts';
import { AV2TableContextMenuCellOption } from 'src/app/commons/models/table-context-menu-cell-option';
import {environment} from "../../../../../../../environments/environment";
import _ from "lodash";
import {AV2SettingsViewPermissionsEnum} from "../../../../../../shared/enums/permissions.enum";
import {PaginatorState} from "primeng/paginator";

@Component({
  selector: 'app-translations',
  templateUrl: './translations.component.html',
  styleUrls: ['./translations.component.scss']
})
export class TranslationsComponent extends SortBaseComponent implements OnInit, OnDestroy {
  NAVI = NAVI;
  AV2SettingsViewPermissionsEnum = AV2SettingsViewPermissionsEnum;
  translationsList: AV2Translation[] = [];
  headers: AV2ListDataSortRequest[] = [];
  options: AV2TableContextMenuCellOption[] = [];

  pageableSubject = new BehaviorSubject<PaginatorState>({ page: 0, rows: environment.paginatorRowPerPageOptions[0]});

  loadAllSub?: Subscription;
  translationListSize: number = 0;

  constructor(
    protected readonly store$: Store<AV2TranslationState>
  ) {
    super(store$);
    this.loading$ = this.store$.pipe(select(getTranslationsTableLoaderState));
  }

  ngOnInit(): void {
    this.store$.dispatch(loadAllTranslations());
    this.prepareHeaders();
    this.prepareOptions();
    this.getTranslationsList();
  }

  private getTranslationsList(): void {
    this.loadAllSub = combineLatest([this.store$.pipe(select(getTranslationList)), this.pageableSubject])
      .subscribe(([translations, pageable]) => {
        this.translationListSize = translations.length;
        this.translationsList = _.take(_.drop(translations, pageable.page! * pageable.rows!), pageable.rows);
      });
  }

  private prepareOptions() {
    this.options = [
      { roleRequired: AV2SettingsViewPermissionsEnum.TRANSLATION_EDIT_FORM_VIEW_ROLE,
        name: 'settings.translations.update',
        label: '⋮',
        icon: 'fas fa-sync',
        action: (tag: string) => {
        this.store$.dispatch(updateTranslation({locale: tag}));
      }},
      { roleRequired: AV2SettingsViewPermissionsEnum.TRANSLATION_DELETE_FORM_VIEW_ROLE,
        name: 'settings.translations.delete',
        label: '⋮',
        icon: 'fas fa-trash',
        action: (tag: string) => {
        this.store$.dispatch(deleteTranslation({locale: tag}));
      }}
    ];
  }

  private prepareHeaders() {
    this.headers = [
      {
        sort: AV2TranslationTableColumn.LANGUAGE,
        direction: AV2ListDataSortDirection.ANY,
        labelKey: 'settings.translations.language',
        class: 'language'
      },
      {
        sort: AV2TranslationTableColumn.NATIVE_NAME,
        direction: AV2ListDataSortDirection.ANY,
        labelKey: 'settings.translations.native-name',
        class: 'name'
      },
      {
        sort: AV2TranslationTableColumn.PROGRESS,
        direction: AV2ListDataSortDirection.ANY,
        labelKey: 'settings.translations.progress',
        class: 'progress'
      }
    ];
  }

  ngOnDestroy(): void {
    this.loadAllSub?.unsubscribe();
  }

  onPageChange($event: PaginatorState) {
    this.pageableSubject.next($event);
  }
}
