import { NgModule } from '@angular/core';
import { PasswordModule } from 'primeng/password';
import { InputTextModule } from 'primeng/inputtext';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TabViewModule } from "primeng/tabview";
import { TableModule } from 'primeng/table';
import { ProgressBarModule } from 'primeng/progressbar';
import { SidebarModule } from 'primeng/sidebar';
import { AccordionModule } from 'primeng/accordion';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TagModule } from 'primeng/tag';
import { AvatarModule } from "primeng/avatar";
import { FileUploadModule } from "primeng/fileupload";
import { MessageModule } from "primeng/message";
import { RadioButtonModule } from 'primeng/radiobutton';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputNumberModule } from 'primeng/inputnumber';
import { MultiSelectModule } from 'primeng/multiselect';
import { DividerModule } from "primeng/divider";
import { TooltipModule } from 'primeng/tooltip';
import { ImageModule } from "primeng/image";
import { TabMenuModule } from "primeng/tabmenu";
import { BadgeModule } from 'primeng/badge';
import { AV2CalendarModule } from "./shared/components/calendar/calendar";
import { TriStateCheckboxModule } from "primeng/tristatecheckbox";
import { TieredMenuModule } from 'primeng/tieredmenu';
import {EditorModule} from "primeng/editor";
@NgModule({
  exports: [
    InputTextModule,
    PasswordModule,
    CardModule,
    ButtonModule,
    CheckboxModule,
    DialogModule,
    OverlayPanelModule,
    ProgressSpinnerModule,
    TabViewModule,
    TableModule,
    ProgressBarModule,
    SidebarModule,
    AccordionModule,
    DropdownModule,
    RadioButtonModule,
    InputSwitchModule,
    InputTextareaModule,
    TagModule,
    AvatarModule,
    FileUploadModule,
    MessageModule,
    AutoCompleteModule,
    InputNumberModule,
    MultiSelectModule,
    DividerModule,
    TooltipModule,
    ImageModule,
    TabMenuModule,
    BadgeModule,
    TriStateCheckboxModule,
    TieredMenuModule,
    EditorModule,

    //customPRIMNG
    AV2CalendarModule,

  ]
})
export class PrimeNgModule {
}
