<div class="container">
  <div>
    <h4>BUTTON PRIMARY</h4>
    <button pButton pRipple type="button"> Button Primary </button>
    <button pButton pRipple type="button" disabled> Button Primary Disabled </button>
  </div>
  <div>
    <h4>BUTTON SECONDARY</h4>
    <button pButton pRipple type="button" class="p-button-secondary"> Button Secondary </button>
    <button pButton pRipple type="button" class="p-button-secondary" disabled> Button Secondary Disabled </button>
  </div>
  <div>
    <h4>BUTTON TEXT</h4>
    <button pButton pRipple type="button" class="p-button-text"> Button Text </button>
    <button pButton pRipple type="button" class="p-button-text" disabled> Button Text Disabled</button>
  </div>
  <div>
    <h4>BUTTON TOAST</h4>
    <button pButton pRipple type="button" (click)="successToast()"> Toast Success</button>
    <button pButton pRipple type="button" (click)="infoToast()"> Toast Info</button>
    <button pButton pRipple type="button" (click)="warnToast()"> Toast Warning</button>
    <button pButton pRipple type="button" (click)="dangerToast()"> Toast Danger</button>
    <button pButton pRipple type="button" (click)="savingToast()"> Toast Saving </button>
    <button pButton pRipple type="button" (click)="clearToast()"> Clear </button>
    <p-toast position="bottom-left"></p-toast>
  </div>
  <div>
    <h4>BADGES</h4>
    <div class="av2-badge primary"> Badge primary </div>
    <div class="av2-badge secondary"> Badge secondary </div>
    <div class="av2-badge success"> Badge success </div>
    <div class="av2-badge danger"> Badge danger </div>
    <div class="av2-badge warning"> Badge warning </div>
  </div>
  <div>
    <h4>LINK BUTTON</h4>
    <button type="button" class="av2-link-button"> Link Button </button>
    <button type="button" class="av2-link-button" disabled> Link Button Disabled</button>
  </div>
  <div>
    <h4>INPUT TEXT</h4>
    <div class="av2-form-field">
      <label for="input-text">Input text label</label>
      <input id="input-text" type="text" placeholder="Placeholder" pInputText>
    </div>
    <div class="av2-form-field">
      <label for="input-text">Input disabled text label</label>
      <input id="input-text" type="text" placeholder="Placeholder" pInputText disabled>
    </div>
    <div class="av2-form-field">
      <label for="input-text">Input invalid text label</label>
      <input id="input-text" class="ng-dirty ng-invalid" type="text" placeholder="Placeholder" pInputText>
    </div>
  </div>
  <div>
    <h4>TEXTAREA</h4>
    <div class="av2-form-field">
      <label for="text-area">Text area</label>
      <textarea id="text-area" class="av2-text-area" placeholder="Text area" rows="5" cols="30"
        pInputTextarea></textarea>
    </div>
    <div class="av2-form-field">
      <label for="text-area">Text area disabled</label>
      <textarea id="text-area" class="av2-text-area" placeholder="Text area disabled" rows="5" cols="30" disabled
        pInputTextarea></textarea>
    </div>
    <div class="av2-form-field">
      <label for="text-area">Text area invalid</label>
      <textarea id="text-area" class="av2-text-area ng-dirty ng-invalid" placeholder="Text area invalid" rows="5"
        cols="30" pInputTextarea></textarea>
    </div>
  </div>
  <div>
    <h4>DROPDOWN</h4>
    <div class="section-quarter">
      <p-dropdown [options]="options" [(ngModel)]="selectedOption" optionLabel="name"></p-dropdown>
    </div>
  </div>
  <div>
    <h4>SECTION</h4>
    <div class="av2-section section-quarter">
      <div class="av2-section-text">Section 25%</div>
    </div>
    <div class="av2-section section-half">
      <div class="av2-section-text">Section 50%</div>
    </div>
    <div class="av2-section">
      <div class="av2-section-text">Section full</div>
    </div>
  </div>
  <div>
    <h4>CARDS LIST NORMAL</h4>
    <div class="av2-container">
      <div class="av2-card-list card-col-1">
        <div class="av2-card-list-icon">
          <i class="fa fa-lock"></i>
        </div>
        <div class="av2-card-list-text-title">Normal Card</div>
        <div class="av2-card-list-text-body">Description</div>
        <button type="button" class="av2-link-button"> Link Button ></button>
      </div>
      <div class="av2-card-list card-col-2">
        <div class="av2-card-list-icon">
          <i class="fa fa-lock"></i>
        </div>
        <div class="av2-card-list-text-title">Normal Card</div>
        <button type="button" class="av2-link-button"> Link Button ></button>
      </div>
    </div>
  </div>
  <div>
    <h4>CARDS LIST SMALL</h4>
    <div class="av2-container">
      <div class="av2-card-list av2-card-list-small card-col-1">
        <div class="av2-card-list-small-icon">
          <i class="fa fa-lock"></i>
        </div>
        <div class="av2-card-list-text-title-small">Small Card</div>
        <div class="av2-card-list-text-body-small">Description</div>
        <button type="button" class="av2-link-button"> Link Button ></button>
      </div>
      <div class="av2-card-list av2-card-list-small card-col-2">
        <div class="av2-card-list-small-icon">
          <i class="fa fa-lock"></i>
        </div>
        <div class="av2-card-list-text-title-small">Small Card</div>
        <button type="button" class="av2-link-button"> Link Button ></button>
      </div>
    </div>
  </div>
  <div>
    <h4>CARDS DETAILS NORMAL</h4>
    <div class="av2-container">
      <div class="av2-card-details card-col-3">
        <div class="av2-card-details-text-title">Details Card</div>
        <div class="av2-card-details-text-body">Description</div>
      </div>
    </div>
  </div>
  <div>
    <h4>CARDS DETAILS SMALL</h4>
    <div class="av2-container">
      <div class="av2-card-details av2-card-details-small card-col-4">
        <div class="av2-card-details-text-title-small">Details Card</div>
        <div class="av2-card-details-text-body-small">Description</div>
      </div>
    </div>
  </div>
  <div>
    <h4>SPECIAL RANGE PICKER</h4>
    <div class="range-picker" [formGroup]="fg">
      <av2-range-picker formControlName="range"></av2-range-picker>
    </div>
  </div>
</div>
