import { Pipe, PipeTransform } from '@angular/core';
import { AV2ClientType } from 'src/app/modules/client/store/models/client.model';

@Pipe({
  name: 'hasLoggedUserPermissionToClient'
})
export class HasLoggedUserPermissionToClientPipe implements PipeTransform {

  transform(loggedUserClientType: AV2ClientType, clientType: AV2ClientType, editMode?: boolean): boolean {
    let permissions: AV2ClientType[] = [];
    switch (loggedUserClientType) {
      case AV2ClientType.ADMIN:
        permissions = [AV2ClientType.PARTNER, AV2ClientType.DISTRIBUTOR, AV2ClientType.DIRECT_CLIENT];
        break;
      case AV2ClientType.PARTNER:
        permissions = [AV2ClientType.DISTRIBUTOR, AV2ClientType.DIRECT_CLIENT];
        break;
      case AV2ClientType.DISTRIBUTOR:
        permissions = [AV2ClientType.DISTRIBUTOR, AV2ClientType.DIRECT_CLIENT];
        break;
    }
    return permissions.indexOf(clientType) != -1;
  }

}
