export interface AV2DataListResponse {
  totalPages: number;
  totalElements: number;
  size: number;
  number: number;
  sort: AV2ListDataSortResponse;
  pageable: AV2ListDataPageableResponse;
  first: boolean;
  last: boolean;
  numberOfElements: number;
  empty: boolean;
}

export interface AV2ListDataPageableResponse {
  offset: number;
  sort: AV2ListDataSortResponse;
  pageNumber: number;
  pageSize: number;
  paged: boolean;
  unpaged: boolean;
}

export interface AV2ListDataSortResponse {
  sorted: boolean;
  unsorted: boolean;
  empty: boolean;
}

export interface AV2ListDataSortRequest {
  sort: string;
  direction: AV2ListDataSortDirection | null;
  labelKey?: string;
  disabledSort?: boolean;
  frozenColumn?: boolean;
  alignFrozen?: string;
  width?: string;
  class?: string;
}

export interface AV2ListDataPageableRequest {
  unpaged?: boolean;
  page: number;
  size: number;
}

export enum AV2ListDataSortDirection {
  ASC = "asc", DESC = "desc", ANY = ""
}

export interface AV2FilterOptionResponse {
  optionName: string;
  count: number;
}

export interface AV2DataListResponseWithContent<T> extends AV2DataListResponse {
  content: Array<T>
}
