import { Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { TranslateService } from '@ngx-translate/core';
import { blockContent } from "../../shared/modules/ui/store/actions/ui.actions";
import { PRIVACY_POLICY_HREF, TERMS_OF_USE_HREF } from "./store/consts/auth-docs-path.const";
import { AuthService } from "./store/services/auth.service";

@Component({
  selector: "app-auth",
  templateUrl: "./auth.component.html",
  styleUrls: ["./auth.component.scss"]
})
export class AuthComponent {

  isToastVisible = true;
  privacyPolicyHref: string;
  generalTermsHref: string;

  constructor(
    private store$: Store,
    private translateService: TranslateService,
    private authService: AuthService) {
    this.store$.dispatch(blockContent({ block: false }));

    //get and update storage language to work with auth langs (en/pl)
    const browserLang = this.translateService.getBrowserLang();
    const authLang = this.authService.getAuthDocsLanguage(true, browserLang);
    this.translateService.use(authLang);

    this.generalTermsHref = TERMS_OF_USE_HREF.replace("{LANGUAGE_CODE}", authLang);
    this.privacyPolicyHref = PRIVACY_POLICY_HREF.replace("{LANGUAGE_CODE}", authLang);
  }

  changeToastVisibility() {
    this.isToastVisible = false;
  }

}
