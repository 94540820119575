import { Injectable } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter, map } from "rxjs/operators";
import { CustomRoutingReuse } from "./custom-routing-reuse";

@Injectable({ providedIn: "root" })
export class DeepReuseControlService {
  constructor(private activatedRoute: ActivatedRoute, private router: Router) {
  }

  public startControlDeepReuse(component: any, deepMode = false) {
    const path = CustomRoutingReuse.getPath(this.getCurrentRouteByFirstChild(this.activatedRoute).snapshot);
    const sub = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute)
    ).subscribe((route) => {
      route = this.getCurrentRouteByFirstChild(route);
      if (this.hasNavigatedToDifferentComponent(route, component)
        && !this.hasComponentInParentsSection(route, component)
        && !this.isInDeepComponent(deepMode, path, route)) {
        CustomRoutingReuse.deleteRoute(path);
        sub.unsubscribe();
      }
    });
  }

  private isInDeepComponent(deepMode: boolean, path: string, route: ActivatedRoute): boolean {
    let deepPath = CustomRoutingReuse.getPath(route.snapshot);
    return deepMode && deepPath.startsWith(path);
  }

  private hasComponentInParentsSection(route: ActivatedRoute, component: any): boolean {
    const data = route.snapshot.data;
    return data?.parents?.some((x: any) => component instanceof x);
  }

  private hasNavigatedToDifferentComponent(route: ActivatedRoute, component: any): boolean {
    return component.constructor !== route.component;
  }

  private getCurrentRouteByFirstChild(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }

}
