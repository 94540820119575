import { Component, OnInit } from '@angular/core';
import { NAVI } from 'src/app/commons/consts';

@Component({
  selector: 'app-success-password',
  templateUrl: './success-password.component.html',
  styleUrls: ['./success-password.component.scss']
})
export class SuccessPasswordComponent {

  NAVI = NAVI;

}
