<div class="dropdown-selector-container">
    <div class="av2-form-field-filter">
        <input type="text" pInputText (keyup)="search($event)" class="dropdown-selector-input" (focus)="isShow = true"
            (focusout)="isShow = false" [placeholder]="placeholder | translate" [disabled]="disabled">
        <i class="far fa-search placeholder-icon"></i>
    </div>
    <div class="dropdown-selector-overlay" *ngIf="isShow">
        <div class="dropdown-selector-items-wrapper">
            <ul role="listbox" class="dropdown-selector-items">
                <li role="option" class="dropdown-selector-item" *ngFor="let option of filteredOptions" (mousedown)="select(option)" (touchend)="select(option)">
                    <span class="dropdown-selector-label">{{ option.label }}</span>
                </li>
                <div class="dropdown-selector-item not-clickable" *ngIf="!filteredOptions?.length">
                    <span class="dropdown-selector-label">
                        {{ noOptionsLabel | translate }}
                    </span>
                </div>
            </ul>
        </div>
    </div>
</div>