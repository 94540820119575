import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Paginator, PaginatorState} from "primeng/paginator";
import {environment} from "../../../../../../environments/environment";

@Component({
  selector: 'app-paginator',
  templateUrl: './av2-paginator.component.html',
  styleUrls: ['./av2-paginator.component.scss']
})
export class Av2PaginatorComponent {
  @ViewChild('paginator', { static: true }) paginator!: Paginator;

  @Input() rowsPerPageOptions: number[] = environment.paginatorRowPerPageOptions;

  private _totalRecords: number = 0;

  @Input() set totalRecords(value: number) {
    this._totalRecords = value;
    this.calculateLastPage();
  }

  @Input() title: string | null = "paginator.sites"

  get totalRecords() {
    return this._totalRecords;
  }

  @Output() pageChange = new EventEmitter<PaginatorState>()

  firstPage: number = 1;

  lastPage: number = 0;

  private _currentPage: number = 1;

  @Input() set currentPage(value: number) {
    this._currentPage = value;

    setTimeout(() => {
      this.paginator.changePage(value - 1);
      this.calculateLastPage();
      this.paginator.updatePaginatorState();
    });

  }

  get currentPage() {
    return this._currentPage;
  }

  private _currentRowOption: number = this.rowsPerPageOptions[0];

  set currentRowOption(value: number) {
    this._currentRowOption = value;
    this.paginator.rows = value;
    this.paginator.changePage(0);
    this.calculateLastPage();
  }

  get currentRowOption() {
    return this._currentRowOption;
  }

  onPageChange($event: PaginatorState) {
    this._currentPage = $event.page! + 1;
    this.pageChange.emit($event);
  }

  resetPagination() {
    this.paginator.changePage(0);
  }

  private calculateLastPage()  {
    this.lastPage = Math.ceil(this.totalRecords / this.currentRowOption);
  }
}
