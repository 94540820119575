export function throttleMethod(wait = 33, limit = 0, immediate = true): MethodDecorator {
  return (target: Object, propertyKey: string | symbol, descriptor: PropertyDescriptor) => {
    const originalMethod = descriptor.value;
    let timer: number | undefined;
    let startTime: number | null = null;
    descriptor.value = function(...args: any[]) {
      let context = this;
      let currentTime = new Date().getTime();

      if (startTime === null) {
        if (!immediate) startTime = currentTime;
        else startTime = currentTime - limit;
      }

      let waitFun = function() {
        originalMethod.apply(context, args);
        startTime = null;
      };

      clearTimeout(timer);

      if (limit && currentTime - startTime >= limit) {
        originalMethod.apply(context, args);
        startTime = currentTime;
      } else {
        timer = window.setTimeout(waitFun, wait);
      }
    };
  };
}
