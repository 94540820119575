import {createFeatureSelector, createSelector} from "@ngrx/store";
import {AV2AuthState} from "../models/state.model";
import {DATE_FORMATS, TIME_FORMATS} from "../../../../shared/pipes/customDate.pipe";
import {AV2UserSettingsDto} from "../../../../commons/models/user-settings";
import {Av2TimezonesModel} from "../models/av2-timezones.model";
import {InjectedClass} from "../../../../core/helpers/InjectedClass";
import {AUTH_STORE_NAME} from "../consts/store-name.const";

export const selectAuthState = createFeatureSelector<AV2AuthState>(
  AUTH_STORE_NAME
);

export const getAuthData = createSelector(
  selectAuthState,
  (state: AV2AuthState) => ({
    ...state.appUser,
    clientId: state.impersonatedUserClientId ? state.impersonatedUserClientId : state.appUser.clientId
  })
);

export const getAccessToken = createSelector(
  selectAuthState,
  (state: AV2AuthState) => {
    if (state.accessToken) {
      return {
        acceptedTerms: state.appUser.acceptedTerms,
        token: state.accessToken,
        impersonateMode: false
      }
    } else {
      return null;
    }
  }
);

export const getBearerToken = createSelector(
  selectAuthState, (state: AV2AuthState) =>
    !!state.accessToken && !!state.accessToken.token ?
      "Bearer " + state?.accessToken?.token : ""
);

export const getUserRoles = createSelector(
  selectAuthState,
  (state: AV2AuthState) =>
    !!state.role &&
      !!state.role.permissions ? state.role.permissions : []
);

export const getCurrentUserRole = createSelector(
  selectAuthState, (state: AV2AuthState) => state.role
);

export const getUserWithRoles = createSelector(
  selectAuthState,
  (state: AV2AuthState) => ({
    userId: state.appUser.id,
    userName: state.appUser.firstName + ' ' + state.appUser.lastName,
    roles: state.role.permissions
  })
);

export const getAuthUserClientId = createSelector(
  selectAuthState,
  (state: AV2AuthState) =>
    !!state.impersonatedUserClientId ? state.impersonatedUserClientId :
      !!state.appUser ? state.appUser.clientId : -1

);

export const getAuthUserId = createSelector(
  selectAuthState,
  (state: AV2AuthState) => !!state.impersonatedUserTechnicalName ? parseInt(state.impersonatedUserTechnicalName) :
    !!state.appUser ? state.appUser.id : -1
);

export const getAuthUserSettings = createSelector(
  selectAuthState,
  (state: AV2AuthState) => state?.appUser?.userSettingsDto
);

export const getAuthUserDateFormat = createSelector(
  selectAuthState,
  (state: AV2AuthState) => state?.appUser?.userSettingsDto?.dateFormat ?? DATE_FORMATS[0].value
);

export const getAuthUserTimeFormat = createSelector(
  selectAuthState,
  (state: AV2AuthState) => state?.appUser?.userSettingsDto?.timeFormat ?? TIME_FORMATS[0].name
);

export const getStatusUUID = createSelector(
  selectAuthState,
  (state: AV2AuthState) => state?.uuid
);

export const getAcceptedTerms = createSelector(
  selectAuthState,
  (state: AV2AuthState) => state?.appUser?.acceptedTerms
);

export const getInfoBarVisibility = createSelector(
  selectAuthState,
  (state: AV2AuthState) => state?.isInfobarVisible
);

export const getProfileTheme = createSelector(
  selectAuthState,
  (state: AV2AuthState) => state?.appUser?.userSettingsDto?.theme
);

export const getProfileLanguage = createSelector(
  selectAuthState,
  (state: AV2AuthState) => state?.appUser?.userSettingsDto?.language
);

export const getAuthProfileTimeZone = createSelector(
  selectAuthState,
  (state: AV2AuthState) => state?.appUser?.userSettingsDto?.timeZoneId
);

export const getUserSettingsDto = createSelector(
  selectAuthState,
  (state: AV2AuthState) => state?.appUser?.userSettingsDto
);

export const getClientTypeByLoggedUserClientId = createSelector(
  selectAuthState,
  (state: AV2AuthState) => state?.clientType
);

export const getImpersonatedUserData = createSelector(
  selectAuthState,
  (state: AV2AuthState) => ({ technicalName: state?.impersonatedUserTechnicalName, userName: state?.impersonatedUserName, impersonatedUserId: state?.impersonatedUserClientId  })
);

export const getAllTimezones = createSelector(
  selectAuthState,
  (state: AV2AuthState) => {
    return state.timezones.map(x => {
      if (x.offset.match(/([-+])\d{4}/gm)) {
        return x;
      } else return { ...x, displayName: InjectedClass.translateService.instant("ui.common.timezone.zone_auto") };
    });
  }
);

export const getAuthUserTimeZone = createSelector(
  getAuthUserSettings,
  getAllTimezones,
  (settingsDto: AV2UserSettingsDto, timezones: Av2TimezonesModel[]) => timezones.find(x => x.zoneId === settingsDto?.timeZoneId)
);

