import { Pipe, PipeTransform } from '@angular/core';
import { AV2AlarmTypeTimeToRespondUnit } from "../../commons/models/alarm-type/alarm-type-time-to-respond-unit";

@Pipe({
  name: 'calculateTimeToRespond'
})
export class CalculateTimeToRespondPipe implements PipeTransform {

  transform(time: number, currentUnit: AV2AlarmTypeTimeToRespondUnit, unitToChange: AV2AlarmTypeTimeToRespondUnit): number {
    let timeToReturn = time;
    if (unitToChange === AV2AlarmTypeTimeToRespondUnit.HOUR) {
      if (currentUnit === AV2AlarmTypeTimeToRespondUnit.MINUTE) {
        timeToReturn = time / 60;
      }
      if (currentUnit === AV2AlarmTypeTimeToRespondUnit.SECOND) {
        timeToReturn = time / 3600;
      }
    }
    if (unitToChange === AV2AlarmTypeTimeToRespondUnit.MINUTE) {
      if (currentUnit === AV2AlarmTypeTimeToRespondUnit.HOUR) {
        timeToReturn = time * 60;
      }
      if (currentUnit === AV2AlarmTypeTimeToRespondUnit.SECOND) {
        timeToReturn = time / 60;
      }
    }
    if (unitToChange === AV2AlarmTypeTimeToRespondUnit.SECOND) {
      if (currentUnit === AV2AlarmTypeTimeToRespondUnit.HOUR) {
        timeToReturn = time * 3600;
      }
      if (currentUnit === AV2AlarmTypeTimeToRespondUnit.MINUTE) {
        timeToReturn = time * 60;
      }
    }
    return timeToReturn;
  }
  
}
