export interface AV2Translation {
  tag: string;
  name: string;
  nativeName: string;
  progress: number;
  combinedNames?: string;
}

export enum AV2TranslationTableColumn {
  LANGUAGE = 'language', NATIVE_NAME = 'nativName', PROGRESS = 'progress'
}
