import { createAction, props } from '@ngrx/store';
import { AV2Notification } from '../models/notifications.model';

const actionHeader = "[Notifications]";

export const loadAllNotifications = createAction(
  `${actionHeader} load all notifications`
);

export const loadAllNotificationsSuccess = createAction(
  `${actionHeader} load all notifications success`,
  props<{ notifications: AV2Notification[] }>()
);

export const loadAllNotificationsFailed = createAction(
  `${actionHeader} load all notifications failed`,
);

export const loadUnreadMessagesCount = createAction(
  `${actionHeader} load unread messages count`
);

export const loadUnreadMessagesCountSuccess = createAction(
  `${actionHeader} load unread messages count success`,
  props<{ count: number }>()
);

export const loadUnreadMessagesCountFailed = createAction(
  `${actionHeader} load unread messages count failed`
);

export const addNotification = createAction(
  `${actionHeader} add notification`
);

export const addNotificationSuccess = createAction(
  `${actionHeader} add notification success`,
  props<{ notification: AV2Notification }>()
);

export const addNotificationFailed = createAction(
  `${actionHeader} add notification failed`,
);

export const increaseCounter = createAction(
  `${actionHeader} increase new notifications counter`,
  props<{ count: number; }>()
);

export const decreaseCounter = createAction(
  `${actionHeader} decrease new notifications counter`,
  props<{ count: number; }>()
);

export const markNotificationAsRead = createAction(
  `${actionHeader} mark notification as read`,
  props<{ notification: AV2Notification; }>()
);
export const markNotificationAsReadSuccess = createAction(
  `${actionHeader} mark notification as read success`,
  props<{ notification: AV2Notification; }>()
);
export const markNotificationAsReadFailed = createAction(
  `${actionHeader} mark notification as read failed`
);

