import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { passwordMatch } from 'src/app/core/validators/password-match';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { AV2AuthState } from '../../store/models/state.model';
import { checkResetPasswordUUID, resetPassword } from '../../store/actions/auth.actions';
import { NAVI } from 'src/app/commons/consts';
import { showToast } from 'src/app/shared/modules/ui/store/actions/ui.actions';
import { getStatusUUID } from '../../store/selectors/auth.selectors';
import { Subscription } from 'rxjs';
import { AUTH_ERROR_PASSWORD_PATH } from '../../../../commons/consts/navigation.const';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  NAVI = NAVI;
  changePasswordFormGroup!: UntypedFormGroup;
  uuid = "";
  subscriptions: Subscription[] = [];
  uuidSub!: Subscription;

  constructor(private route: ActivatedRoute, private store$: Store<AV2AuthState>, private readonly router: Router,) { }


  ngOnInit(): void {
    this.initForm();
    this.uuid = this.route.snapshot.url[1].path.toString();
    this.checkUUID();
  }

  private initForm() {
    this.changePasswordFormGroup = new UntypedFormGroup({
      newPassword: new UntypedFormControl('', [Validators.required, Validators.minLength(8)]),
      newPasswordReply: new UntypedFormControl('', [Validators.required, Validators.minLength(8)]),
    }, passwordMatch);
  }

  checkUUID() {
    const uuid = this.uuid;
    this.store$.dispatch(
      checkResetPasswordUUID({ uuid })
    )
    this.uuidSub = this.store$.pipe(select(getStatusUUID)).subscribe(status => {
      if (status === false) {
        this.router.navigate([AUTH_ERROR_PASSWORD_PATH])
      }
    });
  }

  resetPassword() {
    if (this.changePasswordFormGroup && this.changePasswordFormGroup.valid) {
      const newPassword = this.changePasswordFormGroup.get('newPassword')?.value;
      const newPasswordReply = this.changePasswordFormGroup.get('newPasswordReply')?.value;
      const uuid = this.uuid;
      this.store$.dispatch(
        resetPassword({ newPassword, newPasswordReply, uuid })
      );
    } else {
      this.store$.dispatch(showToast({ severity: 'error', message: 'ui.form.validation.failed' }));
    }
  }

  ngOnDestroy() {
    this.uuidSub.unsubscribe();
  }
}
