import { Pipe, PipeTransform } from '@angular/core';
import { AV2PatrolDto } from 'src/app/modules/patrols/store/models/patrols.model';

@Pipe({
  name: 'patrolSlaCheck'
})
export class PatrolSlaCheckPipe implements PipeTransform {

  transform(patrol?: AV2PatrolDto): 'warning' | 'danger' | 'success' {
    let val: 'warning' | 'danger' | 'success' = 'danger';
    if (patrol?.toPassPercentage) {
      if (patrol?.numberOfScannedCheckpoints / patrol?.numberOfCheckpoints * 100 < patrol?.toPassPercentage) val = 'danger';
      if (patrol?.numberOfScannedCheckpoints / patrol?.numberOfCheckpoints * 100 >= patrol?.toPassPercentage) val = 'warning';
    } else {
      patrol?.numberOfScannedCheckpoints > 0 ? val = 'warning' : 'danger';
    }
    if (patrol?.numberOfScannedCheckpoints === patrol?.numberOfCheckpoints) val = 'success';
    return val;
  }

}
