import { environment } from "../../../environments/environment";

export class ViewUtils {
  private static mobileWidth: number = environment.mobileWidth;
  private static tabletWidth: number = environment.tabletWidth;

  public static isMobileView(): boolean {
    return document.documentElement.clientWidth <= this.mobileWidth;
  }

  public static isTabletView(): boolean {
    return document.documentElement.clientWidth <= ViewUtils.tabletWidth && document.documentElement.clientWidth > ViewUtils.mobileWidth;
  }
}
