<div *ngIf="showSpinner || (blockContent | async) || !isOnline" class="av2-loader-container">
  <p-progressSpinner *ngIf="isOnline"></p-progressSpinner>
  <div class="offline-info" *ngIf="!isOnline">
    <h1 class="av2-list-empty-state-title" translate>
      {{offlineTitle}}
    </h1>
    <p class="av2-list-empty-state-description" translate>
      {{offlineDesc}}
    </p>
  </div>
</div>