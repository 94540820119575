import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Store } from '@ngrx/store';
import { blockContent } from 'src/app/shared/modules/ui/store/actions/ui.actions';

interface Option {
  name: string;
  code: string;
}

@Component({
  selector: 'app-style-guide',
  templateUrl: './style-guide.component.html',
  styleUrls: ['./style-guide.component.scss'],
})
export class StyleGuideComponent implements OnInit {
  options: Option[];
  selectedOption: Option | undefined;
  fg = new UntypedFormGroup({
    range: new UntypedFormControl([])
  });

  constructor(
    private readonly messageService: MessageService,
    private readonly store$: Store
  ) {
    this.store$.dispatch(blockContent({ block: false }));

    this.options = [
      { name: 'Polski', code: 'pl' },
      { name: 'Angielski', code: 'en' },
      { name: 'Włoski', code: 'wl' },
      { name: 'Hiszpański', code: 'hi' },
    ];
  }

  successToast() {
    this.messageService.add({ severity: 'success', summary: 'Service Message', sticky: true });
  }
  infoToast() {
    this.messageService.add({ severity: 'info', summary: 'Service Message', sticky: true });
  }
  warnToast() {
    this.messageService.add({ severity: 'warn', summary: 'Service Message', sticky: true });
  }
  dangerToast() {
    this.messageService.add({ severity: 'error', detail: 'Link', summary: 'Service Message', sticky: true });
  }
  savingToast() {
    this.messageService.add({ severity: 'custom', summary: 'Service Message', icon: 'fas fa-exclamation-triangle', sticky: true });
  }

  clearToast() {
    this.messageService.clear();
  }
  ngOnInit(): void { }
}
