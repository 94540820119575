import { Injectable } from "@angular/core";
import { ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, Router } from "@angular/router";
import { filter, map } from "rxjs/operators";
import { IDS } from "../../../commons/consts/navigation.const";
import { Interpolator } from "../../../shared/helpers/interpolator";

@Injectable({ providedIn: "root" })
export class BreadCrumbService {
  private _crumbs: Array<ICrumb> = new Array<ICrumb>();
  private replacer: Map<string, string> = new Map<string, string>();

  get crumbs(): Array<ICrumb> {
    return this._crumbs;
  }

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute)
    ).subscribe((route) => {
      this.buildCrumbs(route);
    });
  }

  private buildCrumbs(route = this.activatedRoute): void {
    this.clearCrumbs();
    let url = "";
    while (route.firstChild) {
      route = route.firstChild;
      let toSkip = false;
      if (!!route.routeConfig?.path && route.routeConfig.path.indexOf(":") >= 0) {
        let path = route.routeConfig.path;
        //@ts-ignore replece :id for value from params
        Object.keys(IDS).forEach(x => path = path.replace(":" + x, route.params.value[x]));
        toSkip = this.pathHas0IdAndBcIsSetToSkip(route, path);
        if (!toSkip) {
          url += "/" + path;
        }
      } else if (!!route.routeConfig?.path) {
        url += "/" + route.routeConfig?.path;
      }
      if (!toSkip && route.outlet === PRIMARY_OUTLET && !!route.routeConfig?.data?.breadcrumb) {
        this.addCrumb((this.createCrumb(route, url)));
      }
    }
  }

  public setReplacer(key: string, value: string): void {
    !!value && this.replacer.set(key, value);    
    this.buildCrumbs();
  }

  public addCrumb(crumb: ICrumb): void {
    this._crumbs.push(crumb);
  }

  public shouldShow = () => {
    return this._crumbs.length > 0;
  };

  private clearCrumbs(): void {
    this._crumbs = new Array<ICrumb>();
  }

  private createCrumb(route: ActivatedRoute, url: string): ICrumb {
    let label: string = route.routeConfig?.data?.breadcrumb;
    label = Interpolator.interpolate(label, this.replacer);
    return {
      label,
      url
    };
  }

  private pathHas0IdAndBcIsSetToSkip(route: ActivatedRoute, path: string): boolean {
    return !!route.routeConfig?.data?.breadcrumb && !!route.routeConfig?.data?.breadcrumbSkipIfUndefined && path.indexOf("0") >= 0;
  }

  public get lastCrumb(): ICrumb | boolean {
    if (this.crumbs.length < 1) return false;
    return this.getCrumb(this.crumbs.length - 1);
  }

  private getCrumb(index: number): ICrumb | boolean {
    return this.crumbs[index] || false;
  }

  public get penultCrumb(): ICrumb | boolean {
    if (this.crumbs.length < 2) return false;
    return this.getCrumb(this.crumbs.length - 2);
  }
}

export interface ICrumb {
  label: string,
  url: string,
}
