import { NgModule } from "@angular/core";
import { AttachToDirective } from "./portal/attach-to.directive";
import { TargetDirective } from "./portal/target.directive";
import { CommonModule } from "@angular/common";
import { ResolveUndefinedStringPipe } from "../shared/pipes/resolve-undefined.pipe";
import { AddressPipe } from './pipes/address.pipe';
import { DayNameFromDatePipe } from './pipes/day-name-from-date.pipe';
import { HasAccessDirective } from "../shared/directives/has-access.directive";
import { CustomDatePipe } from "../shared/pipes/customDate.pipe";
import { WorkDurationPipe } from "./pipes/work-duration.pipe";
import { HasLoggedUserPermissionToClientPipe } from "./pipes/check-logged-user-permission-to-client.pipe";
import {OrderByPipe} from "../shared/pipes/orderBy.pipe";
import { CaluculateTimeFromMinutesPipe } from './pipes/caluculate-time-from-minutes.pipe';
import { CalculateTimeToRespondPipe } from "./pipes/calculate-time-to-respond.pipe";
import { LocalTimePipe } from "../shared/pipes/localTime.pipe";
import { PatrolSlaCheckPipe } from "./pipes/patrol-sla-check.pipe";
import {CheckSmsEditConfigurationPermissionPipe} from "./pipes/check-sms-edit-configuration-permission.pipe";

@NgModule({
  declarations: [
    AttachToDirective,
    TargetDirective,
    ResolveUndefinedStringPipe,
    OrderByPipe,
    AddressPipe,
    HasAccessDirective,
    DayNameFromDatePipe,
    CustomDatePipe,
    WorkDurationPipe,
    HasLoggedUserPermissionToClientPipe,
    CalculateTimeToRespondPipe,
    CaluculateTimeFromMinutesPipe,
    LocalTimePipe,
    PatrolSlaCheckPipe,
    CheckSmsEditConfigurationPermissionPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    AttachToDirective,
    TargetDirective,
    OrderByPipe,
    ResolveUndefinedStringPipe,
    AddressPipe,
    HasAccessDirective,
    DayNameFromDatePipe,
    CustomDatePipe,
    WorkDurationPipe,
    HasLoggedUserPermissionToClientPipe,
    CalculateTimeToRespondPipe,
    CaluculateTimeFromMinutesPipe,
    LocalTimePipe,
    PatrolSlaCheckPipe,
    CheckSmsEditConfigurationPermissionPipe
  ]
})
export class CoreModule {
}
