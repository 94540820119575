import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslationCreateComponent } from './components/translation-create/translation-create.component';
import { TranslationsComponent } from './components/translations/translations.component';
import { CREATE, LIST } from "../../../../commons/consts/navigation.const";
import { PermissionGuard } from 'src/app/core/guards/permission.guard';
import {AV2SettingsViewPermissionsEnum} from "../../../../shared/enums/permissions.enum";

const routes: Routes = [
  {
    path: '',
    redirectTo: LIST,
    pathMatch: 'full'
  },
  {
    path: LIST,
    canActivate: [PermissionGuard],
    data: {
      breadcrumb: 'bc.settings.translations.list',
      roles: AV2SettingsViewPermissionsEnum.TRANSLATIONS_VIEW_ROLE
    },
    children: [
      {
        path: '',
        component: TranslationsComponent
      },
      {
        path: CREATE,
        canActivate: [PermissionGuard],
        component: TranslationCreateComponent,
        data: {
          breadcrumb: 'bc.settings.translations.create',
          roles: [
            AV2SettingsViewPermissionsEnum.TRANSLATION_CREATE_FORM_VIEW_ROLE
          ]
        },
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TranslationsRoutingModule { }
