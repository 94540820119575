import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {Av2PaginatorComponent} from "./components/paginator/av2-paginator.component";
import {PaginatorModule} from "primeng/paginator";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [
    Av2PaginatorComponent
  ],
    imports: [
        CommonModule,
        PaginatorModule,
        TranslateModule
    ],
  exports: [
    Av2PaginatorComponent
  ]
})
export class Av2PaginatorModule { }
