import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-list-empty-state',
  templateUrl: './list-empty-state.component.html',
  styleUrls: ['./list-empty-state.component.scss']
})
export class ListEmptyStateComponent {

  @Input() imageName?: string;
  @Input() title?: string;
  @Input() desc?: string;

}
