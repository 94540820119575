import { UntypedFormGroup } from "@angular/forms";
import { AV2ErrorInfo } from "src/app/commons/models/error-model";

export class FormHelper {
    static findInvalidControls(form: UntypedFormGroup) {
        const invalid: AV2ErrorInfo[] = [];
        const controls = form.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                const controlErrors = controls[name].errors;
                if (controlErrors) {
                    Object.keys(controlErrors).forEach(keyError => {
                        invalid.push({
                            control: name,
                            error: keyError
                        });
                    });   
                }
            }
        }
        return invalid;
    }
}