import { Observable } from "rxjs/internal/Observable";
import { cloneDeep, isEqual } from "lodash";

interface IDuplicateState {
  value: any,
  w8: boolean
}

class DuplicateState {
  static state: Map<string, any> = new Map<string, IDuplicateState>();
}

const ignoreDuplicateInTime = <T>(debounceTime: number, key?: string, withLog = false) => (source: Observable<T>) => new Observable<T>(observer => {
  let prev: any = undefined, w8 = false, timeout: number | undefined = undefined;
  const resetTimeout = (key?: string) => {
    clearTimeout(timeout);
    timeout = window.setTimeout(() => {
      w8 = false;
      if (!!key){
        let tmp = DuplicateState.state.get(key)?.value;
        DuplicateState.state.set(key, {value: tmp, w8});
      }
    }, debounceTime);
  };
  return source.subscribe({
    next(x: T) {
      if (!!key) {
        prev = DuplicateState.state.get(key)?.value;
        w8 = !!DuplicateState.state.get(key)?.w8;
      }
      withLog && console.log(prev, x);
      if (!isEqual(x, prev) || !w8) {
        withLog && console.log("next");
        prev = cloneDeep(x);
        observer.next(x);
        w8 = true;
        if (!!key) {
          DuplicateState.state.set(key, {value: prev, w8});
        }
        resetTimeout(key);
      } else {
        withLog && console.log("niet");
        resetTimeout(key);
      }
    },
    error: (err: any) => {
      observer.error(err);
    },
    complete: () => {
      observer.complete();
    }
  });
});

export default ignoreDuplicateInTime;
