import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AV2Theme } from './commons/enums/theme';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {

  private themeLink: HTMLLinkElement;

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.themeLink = this.document.getElementById('app-theme') as HTMLLinkElement;
  }

  setTheme(theme: AV2Theme) {
    this.themeLink.href = `/${theme.toLowerCase()}-theme.css`;
  }

}
