import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {BreadCrumbService, ICrumb} from "../../modules/navigation/bread-crumb/bread-crumb.service";
import {AUTH_PATH, DASHBOARD_PATH} from "../../commons/consts/navigation.const";
import {Store} from "@ngrx/store";
import {Location} from "@angular/common";
import {isEqual} from "lodash";
import {debugMethod} from "../../dev/debug-decorators";

@Injectable({providedIn: "root"})
export class LocationBackGuardService {

  constructor(private router: Router, private breadCrumbService: BreadCrumbService,
              private store$: Store, private location: Location) {

  }

  public startService() {
    this.location.subscribe((next) => {
      const penultCrumb = this.breadCrumbService.penultCrumb;
      if (isEqual(next.state, {navigationId: 1}) || next.url?.startsWith(AUTH_PATH)) {
        setTimeout(() => {
          this.locationUP(penultCrumb);
        }, 10)
      }
    });
  }

  public locationUP(penultCrumb = this.breadCrumbService.penultCrumb) {
    if (!!penultCrumb) {
      this.router.navigate([(penultCrumb as ICrumb).url])
    } else {
      this.router.navigate([DASHBOARD_PATH]);
    }
  }


}
