import { createAction, props } from '@ngrx/store';
import {AV2AccessToken, AV2UserRole, AV2User, AV2ResetPin} from 'src/app/commons/models/user';
import { Av2TimezonesModel } from "../models/av2-timezones.model";

const actionHeader = '[Auth]';

export const signIn = createAction(
  `${actionHeader} SignIn`,
  props<{ username: string; password: string; returnUrl: string }>()
);

export const signInSuccess = createAction(
  `${actionHeader} SignIn success`,
  props<{ user: AV2User; accessToken: AV2AccessToken; role: AV2UserRole; }>()
);

export const signInFailed = createAction(
  `${actionHeader} SignIn failed`,
);

export const logout = createAction(
  `${actionHeader} Logout`,
  props<{ authDataChanged?: boolean; }>()
);

export const logoutSuccess = createAction(
  `${actionHeader} Logout success`
);

export const logoutSuccessAfterAuthDataChanged = createAction(
  `${actionHeader} Logout success after auth data changed`
);

export const logoutFailed = createAction(
  `${actionHeader} Logout failed`
);

export const refreshToken = createAction(
  `${actionHeader} Refresh Token`,
  props<{ refreshToken: string; }>()
);

export const refreshTokenSuccess = createAction(
  `${actionHeader} Refresh Token success`,
  props<{ accessToken: AV2AccessToken; }>()
);

export const changePassword = createAction(
  `${actionHeader} Change Password`,
  props<{ oldPassword: string; newPassword: string; newPasswordReply: string; }>()
);

export const changePasswordSuccess = createAction(
  `${actionHeader} Change Password success`
);

export const changePasswordFailed = createAction(
  `${actionHeader} Change Password failed`
);

export const resetPassword = createAction(
  `${actionHeader} Reset Password`,
  props<{ newPassword: string; newPasswordReply: string; uuid: string; }>()
);
export const resetPasswordSuccess = createAction(
  `${actionHeader} Reset Password success`
);

export const resetPasswordFailed = createAction(
  `${actionHeader} Reset Password failed`
);

export const sendResetPasswordEmail = createAction(
  `${actionHeader} Send Reset Password Email`,
  props<{ email: string; }>()
);

export const sendResetPasswordEmailSuccess = createAction(
  `${actionHeader} Send Reset Password Email Success`,
);

export const sendResetPasswordEmailFailed = createAction(
  `${actionHeader} Send Reset Password Email Failed`,
);

export const checkResetPasswordUUID = createAction(
  `${actionHeader} Reset Password Check UUID`,
  props<{ uuid: string; }>()
);

export const checkResetPasswordUUIDSuccess = createAction(
  `${actionHeader} Reset Password Check UUID Success`,
  props<{ existUuid: boolean; }>()
);

export const checkResetPasswordUUIDFailed = createAction(
  `${actionHeader} Reset Password Check UUID Failed`,
);

export const acceptConditions = createAction(
  `${actionHeader} Accept Conditions`
);

export const acceptConditionsSuccess = createAction(
  `${actionHeader} Accept Conditions Success`
);

export const acceptConditionsFailed = createAction(
  `${actionHeader} Accept Conditions Failed`
);

export const showAuthInfobar = createAction(
  `${actionHeader} show Infobar`
);

export const navigateToAuthConditionsIfNecessary = createAction(
  `${actionHeader} navigate to auth conditions if necessary`,
  props<{ acceptedTerms: boolean, returnUrl: string }>()
);

export const clearAuthData = createAction(
  `${actionHeader} clear auth data`
);

export const loadAllTimezones = createAction(
  `${actionHeader} load all timezones`
);

export const loadAllTimezonesSuccess = createAction(
  `${actionHeader} load all timezones success`,
  props<{ timezones: Av2TimezonesModel[]; }>()
);

export const loadAllTimezonesFailed = createAction(
  `${actionHeader} load all timezones failed`,
);

export const loadImpersonatedUser = createAction(
  `${actionHeader} load impersonated user`,
  props<{ impersonatedUserTechnicalName: string; impersonatedUserName: string; impersonatedUserClientId: number }>()
);

export const loadImpersonatedUserSuccess = createAction(
  `${actionHeader} load impersonated user success`,
  props<{ impersonatedUserTechnicalName: string; impersonatedUserName: string; impersonatedUserClientId: number;
  }>()
);

export const loadImpersonatedUserFailed = createAction(
  `${actionHeader} load impersonated user failed`,
);

export const clearImpersonatedUser = createAction(
  `${actionHeader} clear impersonated user`,
);

export const resetPin = createAction(
  `${actionHeader} reset pin`,
  props<{ newPin: AV2ResetPin }>()
)

export const resetPinSuccess = createAction(
  `${actionHeader} reset pin success`
)

export const resetPinFailed = createAction(
  `${actionHeader} reset pin failed`
)
