<div class="av2-container">
  <div class="av2-list-empty-state-container">
    <div *ngIf="imageName" class="av2-list-empty-state-image-container">
      <img [src]="'../../../../assets/images/illustrations/'+imageName+'.png'" />
    </div>
    <h1 class="av2-list-empty-state-title" translate>
      {{title}}
    </h1>
    <p class="av2-list-empty-state-description" translate>
      {{desc}}
    </p>
  </div>
</div>
