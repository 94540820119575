import {Observable, Subscription} from "rxjs";
import {CountdownData} from "../../helpers/dateUtils";

export interface InfoDialogSubject {
  message: string;
  icon?: string;
  type: InfoDialogType;
}

export interface ConfirmDialogSubject {
  message: string;
  iconName?: string;
  confirmLabel?: string;
  rejectLabel?: string;
  accept: () => void;
}

export interface ConfirmRefreshSessionDialogSubject {
  counter: Observable<CountdownData>;
  title: string;
  message: string;
  iconName?: string;
  confirmLabel?: string;
  rejectLabel?: string;
  accept: () => void;
}

export interface ConfirmWithInputDialogSubject {
  message: string;
  iconName?: string;
  confirmLabel?: string;
  rejectLabel?: string;
  accept: (inputValue: string) => void;
  inputLabel: string;
  inputePlaceholder: string;
  inputMaxLength: number;
  requiredInput?: boolean
}

export enum InfoDialogType {
  ERROR, CONFIRM, CONFIRM_WITH_INPUT, SUCCESS, CONFIRM_REFRESH_SESSION
}
