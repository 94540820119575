import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import packageJson from '../../../../package.json';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let interceptedRequest: HttpRequest<any> = request;    
    if (!interceptedRequest.headers.has('Content-Type') && !interceptedRequest.url.includes('importCSV')) {
      interceptedRequest = interceptedRequest.clone(
        { headers: interceptedRequest.headers.set('Content-Type', 'application/json') });
    }
    if (!interceptedRequest.headers.has('Accept')) {
      interceptedRequest = interceptedRequest.clone(
        { headers: interceptedRequest.headers.set('Accept', 'application/json') });
    }
    interceptedRequest = interceptedRequest.clone(
      { headers: interceptedRequest.headers.set('Access-Control-Allow-Origin', '*') });
    interceptedRequest = interceptedRequest.clone(
      { headers: interceptedRequest.headers.set('Cache-Control', 'no-cache') });
    interceptedRequest = interceptedRequest.clone(
      { headers: interceptedRequest.headers.set('Expires', '0') });
    interceptedRequest = interceptedRequest.clone(
        { headers: interceptedRequest.headers.set('X-Client-Type', 'AV2Frontend') });
    interceptedRequest = interceptedRequest.clone(
        { headers: interceptedRequest.headers.set('X-Client-Version', packageJson.version) });
    environment.production && (interceptedRequest = interceptedRequest
      .clone({ url: interceptedRequest.url.replace('http://', 'https://') }));    
    return next.handle(interceptedRequest);
  }
}
