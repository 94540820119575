import { AV2Site } from "src/app/modules/administration/modules/site/store/models/site.model";
import { AV2UserSettingsDto } from "./user-settings";

export interface AV2User {
  id: number;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  active: boolean;
  type: AV2UserType;
  phoneNumber: string;
  score: number;
  userSettingsDto: AV2UserSettingsDto;
  clientId: number;
  role: string;
  userAssignments?: AV2UserAssignment[];
  userSites?: AV2Site[];
  photo: string | null;
  employeeNumber?: string;
  acceptedTerms?: boolean;
  identificationType?: AV2EmployeeIdentificationTypeEnum;
  identifyAtStart?: boolean;
  companyPosition?: string;
  comment?: string;
  supervisorId?: number;
  supervisor?: AV2User;
  workStartConfig: AV2WorkStartConfig;
  isSessionAutoRefresh: boolean;
}

export enum AV2EmployeeIdentificationTypeEnum {
  RFID = 'RFID',
  QRCODE = 'QRCODE'
}

export interface AV2UserRole {
  name: string;
  isAdmin: boolean;
  permissions: AV2Permission[];
}

export interface AV2Permission {
  name: string;
}

export interface AV2UserAssignment {
  companyId: number;
  name?: string,
  siteIds?: number[];
  sites?: AV2UserAssignmentSite[]
}

export interface AV2UserAssignmentSite {
  name: string,
  id: number
}

export enum AV2UserSiteType {
  AUTOMATIC = 'AUTOMATIC',
  MANUAL = 'MANUAL'
}

export interface AV2CurrentUser {
  accessToken: AV2AccessToken;
  appUser: AV2User;
  role: AV2UserRole;
}

export interface AV2AccessToken {
  token: string;
  refreshToken: string;
  expiresIn: number;
  refreshExpiresIn: number;
}

export enum AV2UserType {
  USER = "USER", GUARD = "GUARD", SUPERVISOR = "SUPERVISOR"
}


export interface AV2ResetPin {
  newPin: string;
  newPinReply: string;
  id: number;
}

export interface AV2WorkStartConfig {
  tag: boolean;
  selfie: boolean;
  tagSelfie: boolean;
  manual: boolean;
  auto: boolean;
}
