import {LocationStrategy} from '@angular/common';
import {
  Attribute,
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Renderer2,
  SimpleChanges
} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Params, QueryParamsHandling, Router, UrlTree} from '@angular/router';
import {Subject, Subscription} from 'rxjs';
import {filter} from "rxjs/operators";
import {IDS, IDSType} from "../../commons/consts/navigation.const";

@Directive({selector: ':not(a):not(area)[av2routerLink]'})
export class AV2RouterLink implements OnChanges {

  // added input
  @Input() idsToReplace?: IDSType;

  @Input() queryParams?: Params | null;

  @Input() fragment?: string;

  @Input() queryParamsHandling?: QueryParamsHandling | null;

  @Input() preserveFragment!: boolean;

  @Input() skipLocationChange!: boolean;

  @Input() replaceUrl!: boolean;

  @Input() state?: { [k: string]: any };

  @Input() relativeTo?: ActivatedRoute | null;

  private commands: any[] = [];
  private preserve!: boolean;

  onChanges = new Subject<AV2RouterLink>();

  constructor(
    private router: Router, private route: ActivatedRoute,
    @Attribute('tabindex') tabIndex: string, renderer: Renderer2, el: ElementRef) {
    if (tabIndex == null) {
      renderer.setAttribute(el.nativeElement, 'tabindex', '0');
    }
  }


  ngOnChanges(changes: SimpleChanges) {
    this.onChanges.next(this);
  }

  @Input('av2routerLink')
  set routerLink(commands: any[] | string | null | undefined) {
    if (commands != null) {
      this.commands = Array.isArray(commands) ? commands : [commands];
    } else {
      this.commands = [];
    }
  }

  @HostListener('click')
  onClick(): boolean {
    const extras = {
      skipLocationChange: attrBoolValue(this.skipLocationChange),
      replaceUrl: attrBoolValue(this.replaceUrl),
      state: this.state,
    };
    this.router.navigateByUrl(this.urlTree, extras);
    return true;
  }


  get urlTree(): UrlTree {
    // added if section
    if (!!this.idsToReplace && Object.keys(this.idsToReplace).length > 0) {
      if (this.commands.length > 1) {
        throw new Error('Wrong use of av2routerlink directive');
      } else {
        this.commands[0] = fillPathByIDs(this.commands[0], this.idsToReplace);
      }
    }
    return this.router.createUrlTree(this.commands, {
      relativeTo: this.relativeTo !== undefined ? this.relativeTo : this.route,
      queryParams: this.queryParams,
      fragment: this.fragment,
      queryParamsHandling: this.queryParamsHandling,
      preserveFragment: attrBoolValue(this.preserveFragment),
    });
  }
}

@Directive({selector: 'a[av2routerLink],area[av2routerLink]'})
export class AV2RouterLinkWithHref implements OnChanges, OnDestroy {

  // added input
  @Input() idsToReplace?: IDSType;

  @HostBinding('attr.target') @Input() target!: string;

  @Input() queryParams?: Params | null;

  @Input() fragment?: string;

  @Input() queryParamsHandling?: QueryParamsHandling | null;

  @Input() preserveFragment!: boolean;

  @Input() skipLocationChange!: boolean;

  @Input() replaceUrl!: boolean;

  @Input() state?: { [k: string]: any };

  @Input() relativeTo?: ActivatedRoute | null;

  private commands: any[] = [];
  private subscription: Subscription;
  private preserve!: boolean;


  @HostBinding() href!: string;

  onChanges = new Subject<AV2RouterLinkWithHref>();

  constructor(
    private router: Router, private route: ActivatedRoute,
    private locationStrategy: LocationStrategy) {
    this.subscription = router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.updateTargetUrlAndHref();
    });
  }

  @Input('av2routerLink')
  set routerLink(commands: any[] | string | null | undefined) {
    if (commands != null) {
      this.commands = Array.isArray(commands) ? commands : [commands];
    } else {
      this.commands = [];
    }
  }


  ngOnChanges(changes: SimpleChanges): any {
    this.updateTargetUrlAndHref();
    this.onChanges.next(this);
  }

  ngOnDestroy(): any {
    this.subscription.unsubscribe();
  }


  @HostListener(
    'click',
    ['$event.button', '$event.ctrlKey', '$event.shiftKey', '$event.altKey', '$event.metaKey'])
  onClick(button: number, ctrlKey: boolean, shiftKey: boolean, altKey: boolean, metaKey: boolean):
    boolean {
    if (button !== 0 || ctrlKey || shiftKey || altKey || metaKey) {
      return true;
    }

    if (typeof this.target === 'string' && this.target != '_self') {
      return true;
    }

    const extras = {
      skipLocationChange: attrBoolValue(this.skipLocationChange),
      replaceUrl: attrBoolValue(this.replaceUrl),
      state: this.state
    };
    this.router.navigateByUrl(this.urlTree, extras);
    return false;
  }

  private updateTargetUrlAndHref(): void {
    this.href = this.locationStrategy.prepareExternalUrl(this.router.serializeUrl(this.urlTree));
  }

  get urlTree(): UrlTree {
    // added if section
    if (!!this.idsToReplace && Object.keys(this.idsToReplace).length > 0) {
      if (this.commands.length > 1) {
        throw new Error('Wrong use of av2routerlink directive')
      } else {
        this.commands[0] = fillPathByIDs(this.commands[0], this.idsToReplace)
      }
    }
    return this.router.createUrlTree(this.commands, {
      relativeTo: this.relativeTo !== undefined ? this.relativeTo : this.route,
      queryParams: this.queryParams,
      fragment: this.fragment,
      queryParamsHandling: this.queryParamsHandling,
      preserveFragment: attrBoolValue(this.preserveFragment),
    });
  }
}

function attrBoolValue(value: any): boolean {
  return value === '' || !!value;
}

export function fillPathByIDs(path: string, idsToReplace: IDSType): string {
  Object.keys(idsToReplace).forEach(key => {
    //@ts-ignore
    path = path.replace(new RegExp(`\:${key}`,'gm'), idsToReplace[key])
  })
  return path;
}
