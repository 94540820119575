import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoDialogComponent } from './info-dialog.component';
import { PrimeNgModule } from 'src/app/prime-ng.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    InfoDialogComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    PrimeNgModule,
    FormsModule,
  ],
  exports: [InfoDialogComponent]
})
export class InfoDialogModule { }
