import { createAction, props } from '@ngrx/store';
import { AV2ListDataSortRequest } from 'src/app/commons/models/data-list';
import { AV2Client, AV2ClientBreadcrumbDto, AV2ClientLogo, AV2ClientResponse, AV2ClientType } from '../models/client.model';

const actionHeader = "[Client]";

export const showClientTableLoader = createAction(
  `${actionHeader} show table loader`,
);

export const hideClientTableLoader = createAction(
  `${actionHeader} hide table loader`,
);


export const createClient = createAction(
  `${actionHeader} create client`,
  props<{ client: AV2Client; }>()
);

export const createClientSuccess = createAction(
  `${actionHeader} create client success`,
  props<{ client: AV2Client; }>()
);

export const createClientFailed = createAction(
  `${actionHeader} create client failed`
);

export const updateClient = createAction(
  `${actionHeader} update client`,
  props<{ client: AV2Client; }>()
);

export const updateClientSuccess = createAction(
  `${actionHeader} update client success`,
  props<{ client: AV2Client; }>()
);

export const updateClientFailed = createAction(
  `${actionHeader} update client failed`
);

export const enableClient = createAction(
  `${actionHeader} enable client`,
  props<{ clientId: number; }>()
);

export const enableClientSuccess = createAction(
  `${actionHeader} enable client success`,
  props<{ clientId: number; }>()
);

export const enableClientList = createAction(
  `${actionHeader} enable client list`,
  props<{ clientIds: number[]; }>()
);

export const deleteClientList = createAction(
  `${actionHeader} delete client list`,
  props<{ id: number[]; }>()
);

export const disableClient = createAction(
  `${actionHeader} disable client`,
  props<{ clientId: number; }>()
);

export const disableClientSuccess = createAction(
  `${actionHeader} disable client success`,
  props<{ clientId: number; }>()
);

export const disableClientFailed = createAction(
  `${actionHeader} disable client failed`
);

export const disableClientList = createAction(
  `${actionHeader} disable client list`,
  props<{ clientIds: number[]; }>()
);

export const disableClientListSuccess = createAction(
  `${actionHeader} disable client list success`
);

export const disableClientListFailed = createAction(
  `${actionHeader} disable client list failed`
);

export const enableSelectedChildrenForClient = createAction(
  `${actionHeader} enable selected children for client`,
  props<{ clientId: number; childrenIds: number[]; }>()
);

export const enableSelectedChildrenForClientSuccess = createAction(
  `${actionHeader} enable selected children for client success`,
  props<{ clientId: number; childrenIds: number[]; }>()
);

export const enableSelectedChildrenForClientFailed = createAction(
  `${actionHeader} enable selected children for client failed`
);

export const disableSelectedChildrenForClient = createAction(
  `${actionHeader} disable selected children for client`,
  props<{ clientId: number; childrenIds: number[]; }>()
);

export const disableSelectedChildrenForClientSuccess = createAction(
  `${actionHeader} disable selected children for client success`,
  props<{ clientId: number; childrenIds: number[]; }>()
);

export const disableSelectedChildrenForClientFailed = createAction(
  `${actionHeader} disable selected children for client failed`
);

export const enableAllChildrenForClient = createAction(
  `${actionHeader} enable all children for client`,
  props<{ clientId: number; }>()
);

export const enableAllChildrenForClientSuccess = createAction(
  `${actionHeader} enable all children for client success`,
  props<{ clientId: number; }>()
);

export const enableAllChildrenForClientFailed = createAction(
  `${actionHeader} enable all children for client failed`
);

export const disableAllChildrenForClient = createAction(
  `${actionHeader} disable all children for client`,
  props<{ clientId: number; }>()
);

export const disableAllChildrenForClientSuccess = createAction(
  `${actionHeader} disable all children for client success`,
  props<{ clientId: number; }>()
);

export const disableAllChildrenForClientFailed = createAction(
  `${actionHeader} disable all children for client failed`
);

export const loadClientById = createAction(
  `${actionHeader} load client by id`,
  props<{ clientId: number; }>()
);

export const loadClientByIdSuccess = createAction(
  `${actionHeader} load client by id success`,
  props<{ client: AV2Client; }>()
);

export const loadClientByIdFailed = createAction(
  `${actionHeader} load client by id failed`
);

export const loadClientName = createAction(
  `${actionHeader} load client name`,
  props<{ clientId: number }>()
);

export const loadClientNameSuccess = createAction(
  `${actionHeader} load client name success`,
  props<{ av2ClientBreadcrumbDto: AV2ClientBreadcrumbDto }>()
);

export const loadClientNameFailed = createAction(
  `${actionHeader} load client name failed `
);

export const loadClientLogoListByClientId = createAction(
  `${actionHeader} load client logo list by id`,
  props<{ clientId: number, includeImage: boolean }>()
);

export const loadClientLogoListByClientIdSuccess = createAction(
  `${actionHeader} load client logo list by id success`,
  props<{ clientLogoList: AV2ClientLogo[] }>()
);

export const loadClientLogoListByClientIdFailed = createAction(
  `${actionHeader} load client logo list by id failed`
);

export const updateClientLogoListByClientId = createAction(
  `${actionHeader} update client logo list by id`,
  props<{ clientId: number, logoList: AV2ClientLogo[] }>()
);

export const updateClientLogoListByClientIdSuccess = createAction(
  `${actionHeader} update client logo list by id success`,
  props<{ logoList: AV2ClientLogo[] }>()
);

export const updateClientLogoListByClientIdFailed = createAction(
  `${actionHeader} update client logo list by id failed`
);

export const loadAllClients = createAction(
  `${actionHeader} load all clients`,
  props<{ excludeLoggedClient?: boolean; }>()
);

export const loadAllClientsSuccess = createAction(
  `${actionHeader} load all clients success`,
  props<{ clients: AV2ClientResponse; }>()
);

export const loadAllClientsFailed = createAction(
  `${actionHeader} load all clients failed`
);

export const loadAllClientsForClient = createAction(
  `${actionHeader} load all clients for client`,
  props<{ clientId: number; }>()
);

export const loadAllClientsForClientSuccess = createAction(
  `${actionHeader} load all clients for client success`,
  props<{ clients: AV2Client[]; }>()
);

export const loadAllClientsForClientFailed = createAction(
  `${actionHeader} load all clients for client failed`
);

export const filterClientListByName = createAction(
  `${actionHeader} filter client list by name`,
  props<{ filterValue: string; }>()
);

export const clearClientListByNameFilter = createAction(
  `${actionHeader} clear name filter for client list`
);

export const filterClientListByNip = createAction(
  `${actionHeader} filter client list by nip`,
  props<{ filterValue: string; }>()
);

export const clearClientListByNipFilter = createAction(
  `${actionHeader} clear nip filter for client list`
);

export const filterClientListByIdPart = createAction(
  `${actionHeader} filter client list by id part`,
  props<{ filterValue: string; }>()
);

export const clearClientListByIdPartFilter = createAction(
  `${actionHeader} clear id part filter for client list`
);

export const loadClientListWithoutFiltersClientList = createAction(
  `${actionHeader} clear all filters on client list`,
);

export const sortClientList = createAction(
  `${actionHeader} sort client list`,
  props<{ sortData: AV2ListDataSortRequest; }>()
);

export const filterClientListByCities = createAction(
  `${actionHeader} filter client list by cities`,
  props<{ filterValue: string; }>()
);

export const filterClientListByStatuses = createAction(
  `${actionHeader} filter client list by statuses`,
  props<{ filterValue: string; }>()
);

export const filterClientListByMyClients = createAction(
  `${actionHeader} filter client list by my clients`,
  props<{ filterValue: string; }>()
);

export const updateClientSeachDtoMyClients = createAction(
  `${actionHeader} update client search DTO by my clients`,
  props<{ myClients: boolean; }>()
);

export const updateClientListPage = createAction(
  `${actionHeader} update client list page`,
  props<{ page: number; pageSize: number; }>()
);

export const setClientListUnpaged = createAction(
  `${actionHeader} client list unpaged`
);

export const restartClientList = createAction(
  `${actionHeader} restart client list`
);

export const loadClientTypeByClientId = createAction(
  `${actionHeader} load client type by client id`,
  props<{ clientId: number }>()
);

export const loadClientTypeByClientIdSuccess = createAction(
  `${actionHeader} load client type by client id success`,
  props<{ clientType: AV2ClientType }>()
);

export const loadClientTypeByClientIdFailed = createAction(
  `${actionHeader} load client type by client id dailed`
);

export const clearClientDetails = createAction(
  `${actionHeader} clear client details`
);

export const clearClientLogoList = createAction(
  `${actionHeader} clear client logo list`
);

export const getStandardPriceListsVisibility = createAction(
  `${actionHeader} get standard price lists visibility`
);

export const getStandardPriceListsVisibilitySuccess = createAction(
  `${actionHeader} get standard price lists visibility success`,
  props<{ visibility: boolean }>()
);

export const getStandardPriceListsVisibilityFailed = createAction(
  `${actionHeader} get standard price lists visibility failed`
);
