import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../../modules/auth/store/services/auth.service';
import { Store } from '@ngrx/store';
import { UiState } from 'src/app/shared/modules/ui/store/models/state.model';
import { showToast } from 'src/app/shared/modules/ui/store/actions/ui.actions';
import { Router } from '@angular/router';
import { AUTH_BLOCKED_SIGN_IN_PATH } from 'src/app/commons/consts/navigation.const';
import { InjectedClass } from '../helpers/InjectedClass';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private store$: Store<UiState>, private readonly router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      if (err.status === 418) {
        this.router.navigate([AUTH_BLOCKED_SIGN_IN_PATH], { state: { timestamp: err?.error?.parameter } });
      }
      else if (err.status === 401) {
        if (request.url.includes('changePassword')) {
          throwError(err);
        } else {
          this.authService.logoutOnFailed();
        }
      }
      else if (err.status === 403) {
        this.showError(err.error.message, err.error.parameter);
        return throwError(err);
      }
      else if (err.status === 404) {
        if (request.url.includes('pricelist/current') || request.url.includes('alarm-event-service')) {
          return throwError(err);
        }
      }
      else if (err.status === 500) {
        this.showError('ui.error.internal-server-error');
      }
      if (err?.error?.errors) {
        err?.error?.errors.forEach((e: any) => {
          this.showError(e?.message);
        });
      } else {
        const error = err?.error?.message;
        this.showError(error);
      }
      return throwError(err);
    }));
  }

  showError(message: string, parameter?: string) {
    !!message && this.store$.dispatch(showToast({ severity: 'error', message, interpolateParams: parameter }));
    !!message && console.error(InjectedClass.translateService.instant(message));
  }

}
