import {Inject, Injectable, InjectionToken, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SignInComponent} from './components/sign-in/sign-in.component';
import {PrimeNgModule} from '../../prime-ng.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {AuthEffects} from './store/effects/auth.effects';
import {AUTH_STORE_NAME} from "./store/consts/store-name.const";
import {authReducer} from "./store/reducers/auth.reducer";
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import {ErrorValidationModule} from 'src/app/shared/components/error-validation/error-validation.module';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {ResetPasswordComponent} from './components/reset-password/reset-password.component';
import {RouterModule} from '@angular/router';
import {AuthRoutingModule} from './auth-routing.module';
import {AuthComponent} from './auth.component';
import {SuccessPasswordComponent} from './components/success-password/success-password.component';
import {
  SuccessSendPasswordEmailComponent
} from './components/success-send-password-email/success-send-password-email.component';
import {ErrorPasswordComponent} from './components/error-password/error-password.component';
import {AV2RouterModule} from 'src/app/shared/directives/router-link.module';
import {EmailPasswordComponent} from './components/email-password/email-password.component';
import {BlockedSignInComponent} from './components/blocked-sign-in/blocked-sign-in.component';
import {ConditionsSignInComponent} from './components/conditions-sign-in/conditions-sign-in.component';
import { AV2CalendarModule } from "../../shared/components/calendar/calendar";


@NgModule({
  declarations: [
    SignInComponent,
    ResetPasswordComponent,
    AuthComponent,
    EmailPasswordComponent,
    SuccessPasswordComponent,
    SuccessSendPasswordEmailComponent,
    ErrorPasswordComponent,
    BlockedSignInComponent,
    ConditionsSignInComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    PrimeNgModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    StoreModule.forFeature(AUTH_STORE_NAME, authReducer),
    EffectsModule.forFeature([AuthEffects]),
    DynamicDialogModule,
    ErrorValidationModule,
    RouterModule,
    AuthRoutingModule,
    AV2RouterModule,
    AV2CalendarModule
  ]

})
export class AuthModule {}
