import { Pipe, PipeTransform } from '@angular/core';
import { AV2Address } from 'src/app/modules/administration/modules/company/store/models/company.model';

@Pipe({
  name: 'address'
})
export class AddressPipe implements PipeTransform {

  transform(address?: AV2Address, ...args: unknown[]): string {
    let fullAddress: string = '';
    address?.street && (fullAddress += `${address?.street}`);
    address?.buildingNumber && (fullAddress += ` ${address?.buildingNumber}`);
    address?.apartmentNumber && (fullAddress += `/${address?.apartmentNumber}`);
    address?.postalCode && (fullAddress += `, ${address?.postalCode} `);
    address?.city && (fullAddress += address?.city);
    return fullAddress;
  }

}
