import { AV2DataListResponse } from "src/app/commons/models/data-list";
import { AV2Contact } from "../../../administration/modules/company/store/models/contact.model";

export interface AV2Client {
  id: number;
  name: string;
  parentId: number;
  parentName?: string;
  childrenIds?: number[];
  description: string;
  address: AV2ClientAddress;
  type: AV2ClientType;
  billingType: AV2ClientBilingType;
  additionalInfo: string;
  currencyId?: number;
  discount: number;
  defaultPriceListId?: number;
  discountDateRange?: AV2DiscountDateRange;
  contacts: AV2Contact[];
  fullAccess?: boolean;
  nip: string;
  defaultLanguage: string;
  firstBillingDate: string;
  selfSmsConfiguration?: boolean;
  currencyCode?: string;
  enableGeotagsAnyEvents?: boolean;
  deletionDatetime?: string;
  status: AV2ClientStatus;
}

export enum AV2ClientStatus {
  ACTIVE="ACTIVE",
  DISABLED_MANUALLY = "DISABLED_MANUALLY",
  DISABLED_AUTOMATICALLY = "DISABLED_AUTOMATICALLY",
  TO_DELETE = "TO_DELETE"
}

export interface AV2ClientLogo {
  id?: number;
  name: string;
  image: string;
}

export interface AV2DiscountDateRange {
  from: Date | string;
  to: Date | string;
}

export interface AV2ClientAddress {
  apartmentNumber: string;
  buildingName: string;
  buildingNumber: string;
  city: string;
  country: string;
  postalCode: string;
  street: string;
}


export interface AV2ClientResponse extends AV2DataListResponse {
  content: AV2Client[];
}

export interface AV2UserToImpersonateResponse extends AV2DataListResponse {
  content: AV2UserToImpersonate[];
}

export enum AV2ClientType {
  DIRECT_CLIENT = "DIRECT_CLIENT",
  DISTRIBUTOR = "DISTRIBUTOR",
  PARTNER = "PARTNER",
  ADMIN = "ADMIN"
}

export enum AV2ClientBilingType {
  MONTHLY = "MONTHLY",
  QUARTERLY = "QUARTERLY",
  YEARLY = "YEARLY",
  PREPAYMENT = "PREPAYMENT"
}

export enum AV2ClientTableColumn {
  NAME = "name",
  NIP = "nip",
  ID = "id",
  PARENT_NAME = "parent.name",
  CITY = "address.city",
  TYPE = "type",
  STATUS = "status"
}

export interface AV2ClientSearchDto {
  name: string;
  NIP?: string;
  idContains?: string,
  cities: string[];
  active?: boolean;
  myClients?: boolean,
  ids?: number[]
  statuses?: string[]
}


export interface AV2UserToImpersonate {
  clientId?: number;
  clientName: string;
  technicalUsername: string;
}

export interface AV2ClientBreadcrumbDto {
  name: string;
}

export interface AV2ClientLogoIsUsedDto {
  isUsedInReport: boolean;
}
