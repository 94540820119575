import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TRANSLATIONS_STORE_NAME } from '../consts/store-name.const';
import { AV2TranslationState } from '../model/state.model';

export const selectTranslationsState = createFeatureSelector<AV2TranslationState>(
  TRANSLATIONS_STORE_NAME
);

export const getTranslationList = createSelector(
  selectTranslationsState,
  (state: AV2TranslationState) => state?.translationList
)

export const getLokaliseList = createSelector(
  selectTranslationsState,
  (state: AV2TranslationState) => state?.lokaliseList
)

export const getTranslationsTableLoaderState = createSelector(
  selectTranslationsState,
  (state: AV2TranslationState) => state?.translationsTableLoaderState
)
