import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslationsRoutingModule} from './translations-routing.module';
import {TranslationsComponent} from './components/translations/translations.component';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {PrimeNgModule} from 'src/app/prime-ng.module';
import {TRANSLATIONS_STORE_NAME} from './store/consts/store-name.const';
import {translationReducer} from './store/reducers/translations.reducer';
import {TranslationsEffects} from './store/effects/translations.effects';
import {ListEmptyStateModule} from 'src/app/shared/modules/list-empty-state/list-empty-state.module';
import {TranslationCreateComponent} from './components/translation-create/translation-create.component';
import {TranslateModule} from '@ngx-translate/core';
import {ProgressBarModule} from 'src/app/shared/modules/progress-bar/progress-bar.module';
import {ReactiveFormsModule} from '@angular/forms';
import {ErrorValidationModule} from 'src/app/shared/components/error-validation/error-validation.module';
import {AV2RouterModule} from "../../../../shared/directives/router-link.module";
import {Av2PaginatorModule} from "../../../../shared/modules/paginator/av2-paginator.module";
import { CoreModule } from 'src/app/core/core.module';

@NgModule({
  declarations: [
    TranslationsComponent,
    TranslationCreateComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    TranslationsRoutingModule,
    PrimeNgModule,
    StoreModule.forFeature(TRANSLATIONS_STORE_NAME, translationReducer),
    EffectsModule.forFeature([TranslationsEffects]),
    TranslateModule,
    ListEmptyStateModule,
    ProgressBarModule,
    ReactiveFormsModule,
    ErrorValidationModule,
    AV2RouterModule,
    Av2PaginatorModule
  ]
})
export class TranslationsModule {
}
