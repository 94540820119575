import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-av2-progress-bar',
  templateUrl: './av2-progress-bar.component.html',
  styleUrls: ['./av2-progress-bar.component.scss']
})
export class AV2ProgressBarComponent {

  @Input() progress!: number;
  @Input() colorClass?: string;
  @Input() toPassPercentage!: number;
  @Input() isSmallProgressBar: boolean = false;

  isBig(): boolean {
    return this.progress === 100;
  }

  isMedium(): boolean {
    if (!!this.toPassPercentage) {
      return this.progress < 100 && this.progress >= this.toPassPercentage;
    }
    return this.progress < 100 && this.progress > 50;
  }

  isSmall(): boolean {
    if (!!this.toPassPercentage) {
      return this.progress < this.toPassPercentage && this.progress >= 0;
    }
    return this.progress <= 50 && this.progress >= 0;
  }
}
