import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { switchMap, mergeMap, catchError, tap } from 'rxjs/operators';
import {
  hideLoader,
  locationBack,
  showLoader,
  showToast
} from 'src/app/shared/modules/ui/store/actions/ui.actions';
import {
  loadAllTranslations,
  loadAllTranslationsSuccess,
  loadTranslationByLocale,
  loadTranslationByLocaleSuccess,
  deleteTranslation,
  loadAllTranslationsFailed,
  loadTranslationByLocaleFailed,
  deleteTranslationFailed,
  loadAllAvailableTranslations,
  loadAllAvailableTranslationsSuccess,
  createTranslation,
  createTranslationSuccess,
  createTranslationFailed,
  updateTranslation,
  updateTranslationSuccess,
  updateTranslationFailed,
  loadAllAvailableTranslationsFailed,
  showTranslationsTableLoader,
  hideTranslationsTableLoader,
} from '../actions/translations.actions';
import { AV2Translation } from '../model/translations.model';
import { TranslationsService } from '../services/translations.service';
import { TranslateService } from "@ngx-translate/core";
import { Store } from '@ngrx/store';
import { TRANSLATIONS_STORE_NAME } from "../consts/store-name.const";

@Injectable()
export class TranslationsEffects {

  constructor(
    private readonly actions$: Actions,
    private readonly translationsService: TranslationsService,
    private readonly translate: TranslateService,
    private readonly store$: Store
  ) {
  }

  loadAllTranslations$ = createEffect(() => this.actions$.pipe(
    ofType(loadAllTranslations),
    tap(() => this.store$.dispatch(showTranslationsTableLoader())),
    switchMap(action => this.translationsService.getAllTranslations().pipe(
      tap((translations: AV2Translation[]) => {
        if (translations?.length > 0) {
          const tags = translations.map(translation => translation.tag).filter(translation => !this.translate.langs.includes(translation));
          this.translate.addLangs(tags);
        }
      }),
      mergeMap((translationsList: AV2Translation[]) => [
        loadAllTranslationsSuccess({ translationsList }),
        hideTranslationsTableLoader()
      ]),
      catchError(() => of(loadAllTranslationsFailed(), hideTranslationsTableLoader()))
    ))
  ));

  loadTranslationByLocale$ = createEffect(() => this.actions$.pipe(
    ofType(loadTranslationByLocale),
    tap(() => this.store$.dispatch(showLoader())),
    switchMap(action => this.translationsService.getTranslationByLocale(action.locale).pipe(
      mergeMap((translation: AV2Translation) => [
        loadTranslationByLocaleSuccess({ translation }),
        hideLoader()
      ]),
      catchError(() => of(loadTranslationByLocaleFailed(), hideLoader()))
    ))
  ));

  deleteTranslation$ = createEffect(() => this.actions$.pipe(
    ofType(deleteTranslation),
    tap(() => this.store$.dispatch(showLoader())),
    switchMap(action => this.translationsService.deleteTranslation(action.locale).pipe(
      mergeMap(() => [loadAllTranslations(), hideLoader(), showToast({ severity: 'success', message: 'lokalise.delete.success' })]),
      catchError(() => of(hideLoader()))
    ))
  ));

  loadAllAvailableTranslations$ = createEffect(() => this.actions$.pipe(
    ofType(loadAllAvailableTranslations),
    tap(() => this.store$.dispatch(showLoader())),
    switchMap(action => this.translationsService.getAllAvailableTranslations().pipe(
      mergeMap((lokaliseList: AV2Translation[]) => [
        loadAllAvailableTranslationsSuccess({ lokaliseList }),
        hideLoader()
      ]),
      catchError(() => of(hideLoader(), loadAllAvailableTranslationsFailed()))
    ))
  ));

  createTranslation$ = createEffect(() => this.actions$.pipe(
    ofType(createTranslation),
    tap(() => this.store$.dispatch(showLoader())),
    switchMap(action => this.translationsService.createTranslation(action.locale).pipe(
      mergeMap(() => [
        createTranslationSuccess({ locale: action.locale }),
        showToast({ severity: 'success', message: 'lokalise.create.success' }),
        hideLoader(),
        locationBack()
      ]),
      catchError(() => of(createTranslationFailed(), hideLoader()))
    ))
  ));

  updateTranslation$ = createEffect(() => this.actions$.pipe(
    ofType(updateTranslation),
    tap(() => this.store$.dispatch(showLoader())),
    switchMap(action => this.translationsService.createTranslation(action.locale).pipe(
      tap(() => {
        sessionStorage.removeItem(TRANSLATIONS_STORE_NAME + '_' + action.locale);
        this.translate.reloadLang(action.locale);
      }),
      mergeMap(() => [
        updateTranslationSuccess({ locale: action.locale }),
        showToast({ severity: 'success', message: 'lokalise.update.success' }),
        hideLoader(),
      ]),

      catchError(() => of(updateTranslationFailed(), hideLoader()))
    ))
  ));
}
