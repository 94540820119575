import { Pipe, PipeTransform } from "@angular/core";
import { AV2UserSettingsTimeFormat } from "../../commons/models/user-settings";
import { CustomDatePipeService } from "./customDatePipe.service";
import dayjs, { ConfigType } from "dayjs";

//it's from backend
export const DATE_FORMATS: Array<{ name: string, value: string }> = [
  { name: "ISO", value: "yyyy-MM-dd" },
  { name: "DOTTED", value: "dd.MM.yyyy" },
  { name: "US", value: "MM/dd/yyyy" }
];

//it's from backend - 'value' is useless for us
export const TIME_FORMATS: Array<{ name: string, value: string }> = [
  { name: AV2UserSettingsTimeFormat.H24, value: "HH:mm:ss" },
  { name: AV2UserSettingsTimeFormat.H12, value: "hh:mm:ss a" }
];

// export const DATE_FORMATS: Array<{ name: string, value: string }> = [ //todo propozycja w CR
//   {name: 'DD.MM.RRRR', value: 'dd.MM.yyyy'},
//   {name: 'RRRR.MM.DD', value: 'yyyy.MM.dd'},
//   {name: 'MM.DD.RRRR', value: 'MM.dd.yyyy'},
//   {name: 'DD-MM-RRRR', value: 'dd-MM-yyyy'},
//   {name: 'RRRR-MM-DD', value: 'yyyy-MM-dd'},
//   {name: 'MM-DD-RRRR', value: 'MM-dd-yyyy'}
// ];

export enum DATE_FORMAT {
  TIME_WITH_SECONDS = "TIME_WITH_SECONDS",
  TIME = "TIME",
  JUST_TIME = "JUST_TIME",
  ONLY_DATE = "ONLY_DATE",
}

export type DATE_FORMAT_TYPE = keyof typeof DATE_FORMAT;
const ONLY_DATE_FORMAT = "YYYY-MM-DD";

@Pipe({
  name: "customDate",
  pure: false
})
export class CustomDatePipe implements PipeTransform {
  private customFormat?: string;

  get _dateFormat(): string {
    return this.customDateService._dateFormat;
  }

  public get h12(): boolean {
    return this.customDateService.h12;
  }

  public get timeFormat(): AV2UserSettingsTimeFormat {
    return this.customDateService.timeFormat;
  }

  constructor(
    private customDateService: CustomDatePipeService
  ) {
  }

  /**
   *  It`s only for pipe - value from backend / ISO UTC Format
   */
  transform(value: ConfigType, args?: DATE_FORMAT_TYPE, timezone?: string): string | null {
    if (value) {
      return dayjs(value, { utc: true }).tz(timezone || dayjs.zoneId).format(this.getFormat(args, true));
    }
    return null;
  }

  transformWithCustomTimezoneAndFormat(value: ConfigType, customTimezone?: string, customFormat?: string, args?: DATE_FORMAT_TYPE,): string | null {
    if (value) {
      this.customFormat = customFormat;
      return dayjs(value, { utc: true }).tz(customTimezone || dayjs.zoneId).format(this.getFormat(args, true));
    }
    return null;
  }

  private getTimeFormat(withSeconds = false) {
    if (withSeconds) {
      if (this.h12) return "hh:mm:ss a";
      return "HH:mm:ss";
    }
    if (this.h12) return "hh:mm a";
    return "HH:mm";
  }

  private getDateFormat() {
    return ""
  }

  public get dateFormatForPrimengCalendar(): string {
    return this.customFormat?.replace("yyyy", "yy").replace("MM", "mm") ||
      this._dateFormat.replace("yyyy", "yy").replace("MM", "mm");
  }

  public get dateFormatForDayJS(): string {
    return this.customFormat?.replace("dd", "DD").replace("yyyy", "YYYY").replace("yy", "YY") ||
      this._dateFormat.replace("dd", "DD").replace("yyyy", "YYYY").replace("yy", "YY");
  }

  public getFormat(args?: DATE_FORMAT_TYPE, forDayJs = true): string {
    if (args === DATE_FORMAT.TIME_WITH_SECONDS) {
      if (forDayJs) return this.dateFormatForDayJS + " " + this.getTimeFormat(true);
      return this.customFormat || this._dateFormat + " " + this.getTimeFormat(true);
    } else if (args === DATE_FORMAT.TIME) {
      if (forDayJs) return this.dateFormatForDayJS + " " + this.getTimeFormat();
      return this.customFormat || this._dateFormat + " " + this.getTimeFormat();
    } else if (args === DATE_FORMAT.JUST_TIME) {
      return this.getTimeFormat();
    } else if (args === DATE_FORMAT.ONLY_DATE) {
      return ONLY_DATE_FORMAT;
    } else {
      if (forDayJs) return this.dateFormatForDayJS;
      return this.customFormat || this._dateFormat;
    }
  }

  public parseToDateFormControl(formValue: string): Date {
    return dayjs(formValue, this.dateFormatForDayJS).toDate();
  }

}
