import { ActivatedRoute, ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from "@angular/router";
import { ComponentRef, Directive, QueryList, ViewChildren } from "@angular/core";
import { throttleMethod } from "../../shared/helpers/decorators";
import { sleep } from "../../shared/helpers/dateUtils";
import { DeepReuseControlService } from "./control-deep-reuse";

export enum CustomRoutingReuseAction {
  DETACH = "ngOnDetach",
  ATTACH = "ngOnAttach"
}

export interface OnAttach {
  ngOnAttach(): void;
}

export interface OnDetach {
  ngOnDetach(): void;
}

export interface DetachedRouteHandleWithComponent extends DetachedRouteHandle {
  componentRef: ComponentRef<any>;
}

export class CustomRoutingReuse implements RouteReuseStrategy {
  private static handlers = new Map<string, DetachedRouteHandleWithComponent>();

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    const path = CustomRoutingReuse.getPath(route);
    const storedComponent = CustomRoutingReuse.handlers.get(path);
    const componentDestroyed = (storedComponent && storedComponent.componentRef.hostView.destroyed);
    if (componentDestroyed) {
      CustomRoutingReuse.handlers.delete(path);
      return null;
    }
    if (CustomRoutingReuse.isIgnored(route) || !storedComponent) {
      return null;
    }
    CustomRoutingReuse.callHook(storedComponent, CustomRoutingReuseAction.ATTACH);
    return storedComponent || null;
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const path = CustomRoutingReuse.getPath(route);
    const storedComponent = CustomRoutingReuse.handlers.get(path);
    const componentDestroyed = (!!storedComponent && storedComponent.componentRef.hostView.destroyed);

    return !componentDestroyed && !!storedComponent && !CustomRoutingReuse.isIgnored(route);
  }

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return !CustomRoutingReuse.isIgnored(route);
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === curr.routeConfig;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandleWithComponent | null): void {
    const key = CustomRoutingReuse.getPath(route);
    if (!handle || CustomRoutingReuse.isIgnored(route)) {
      return;
    }
    if (!!handle) {
      CustomRoutingReuse.callHook(handle, CustomRoutingReuseAction.DETACH);
      CustomRoutingReuse.removeElementsAfterDetach();
      CustomRoutingReuse.handlers.set(key, handle);
    }
  }

  private static isIgnored(route: ActivatedRouteSnapshot) {
    return !!(!route.routeConfig
      || route.routeConfig.loadChildren
      || !route.routeConfig.data?.remember);
  }

  public static getPath(route: ActivatedRouteSnapshot): string {
    return "/" + route.pathFromRoot.map(route => route.routeConfig && route.routeConfig.path).filter(path => !!path).join("/");
  }

  @throttleMethod()
  private static callHook(detachedRoute: DetachedRouteHandleWithComponent, hookName: CustomRoutingReuseAction) {
    const componentRef = detachedRoute.componentRef;
    if (typeof componentRef?.instance[hookName] === "function") {
      sleep(10).then(() => {
        componentRef.instance[hookName]();
      });
    }
    if (componentRef.instance instanceof ReuseParentComponent) {
      componentRef.instance.deepAttachedComponents.forEach((component) => {
        if (typeof component[hookName] === "function") {
          sleep(10).then(() => {
            component[hookName]();
          });
        }
      });
    }
  }

  public static deleteRoute(path: string) {
    const storedComponent = CustomRoutingReuse.handlers.get(path);
    if (storedComponent) {
      storedComponent.componentRef.hostView.destroy();
      CustomRoutingReuse.handlers.delete(path);
    }
  }

  private static removeElementsAfterDetach() {
    const x = document.getElementsByClassName("del-after-detach");
    while (x.length > 0) {
      if (x[0].parentElement?.className.includes("p-overlaypanel-content")) {
        x[0].parentNode?.parentNode?.parentNode?.removeChild(x[0].parentElement!.parentElement!);
      } else x[0].parentNode?.removeChild(x[0]);
    }
  }
}

@Directive()
export abstract class ReuseParentComponent {
  @ViewChildren("deepAttach") deepAttachedComponents!: QueryList<any>;

  protected constructor(protected reuseControl: DeepReuseControlService, deepMode = false) {
    reuseControl.startControlDeepReuse(this, deepMode);
  }

}
