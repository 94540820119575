import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListEmptyStateComponent } from './list-empty-state.component';
import { TranslateModule } from '@ngx-translate/core';
import { ProgressSpinnerModule } from 'primeng/progressspinner';



@NgModule({
  declarations: [
    ListEmptyStateComponent
  ],
  imports: [
    CommonModule,
    ProgressSpinnerModule,
    TranslateModule.forChild()
  ],
  exports: [
    ListEmptyStateComponent
  ]
})
export class ListEmptyStateModule { }
