import {HttpClient} from "@angular/common/http";
import {TranslateLoader} from "@ngx-translate/core";
import {Observable} from "rxjs";
import {TRANSLATION_SERVICE_API_TRANSLATION} from "src/app/commons/consts/api.const";
import {TRANSLATIONS_STORE_NAME} from "../../modules/settings/modules/translations/store/consts/store-name.const";

export class TranslateHttpLoader implements TranslateLoader {

  constructor(private httpClient: HttpClient) {
  }

  getTranslation(lang: string): Observable<{ [key: string]: string }> {
    return new Observable(observer => {
      const trans = sessionStorage.getItem(TRANSLATIONS_STORE_NAME + '_' + lang);
      if (!!trans) {
        observer.next(JSON.parse(trans));
        observer.complete();
      } else {
        this.httpClient.get<{ [key: string]: string }>(`${TRANSLATION_SERVICE_API_TRANSLATION}/${lang}`).subscribe(
          data => {
            sessionStorage.setItem(TRANSLATIONS_STORE_NAME + '_' + lang, JSON.stringify(data));
            observer.next(data);
            observer.complete();
          }
        )
      }
    });
  }
}
