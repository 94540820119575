import { Pipe, PipeTransform } from '@angular/core';
import { EmployeeTimetableHelperService } from 'src/app/modules/administration/modules/employee/store/services/employee-timetable-helper.service';

@Pipe({
  name: 'workDuration'
})
export class WorkDurationPipe implements PipeTransform {

  transform(endTime: Date, startTime: Date): string {
    return EmployeeTimetableHelperService.getWorkDurationString(endTime, startTime);
  }

}
