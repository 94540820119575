let lastId = -1;
const prefix = "av2_component_id_";

export function ucId() {
  return {
    generateId: () => {
      if (lastId === -1) lastId = 1;
      return `${prefix}${lastId++}`;
    },
    lastId: () => lastId
  };
}
