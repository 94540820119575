import { Component, OnInit } from '@angular/core';
import { NAVI } from 'src/app/commons/consts';

@Component({
  selector: 'app-error-password',
  templateUrl: './error-password.component.html',
  styleUrls: ['./error-password.component.scss']
})
export class ErrorPasswordComponent {

  NAVI = NAVI;

}
