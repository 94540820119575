import {NgModule} from "@angular/core";
import {AV2RouterLink, AV2RouterLinkWithHref} from "./router-link.directive";
import {AV2RouterLinkActive} from "./router-link-active.directive";
import {CommonModule} from "@angular/common";

@NgModule({
  imports: [CommonModule],
  exports: [
    AV2RouterLink,
    AV2RouterLinkWithHref,
    AV2RouterLinkActive
  ],
  declarations: [AV2RouterLink, AV2RouterLinkWithHref, AV2RouterLinkActive]
})
export class AV2RouterModule {
}
