import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { UI_STORE_NAME } from "./store/consts/store-name.const";
import { uiReducer } from "./store/reducers/ui.reducer";
import { UiEffects } from "./store/effects/ui.effects";
import { MessageService } from "primeng/api";
import { ToastComponent } from './components/toast/toast.component';
import { ToastModule } from "primeng/toast";
import { DropdownSelectorComponent } from './components/dropdown-selector/dropdown-selector.component';
import { TranslateModule } from '@ngx-translate/core';
import { PrimeNgModule } from 'src/app/prime-ng.module';

@NgModule({

  imports: [
    CommonModule,
    ToastModule,
    TranslateModule,
    PrimeNgModule,
    StoreModule.forFeature(UI_STORE_NAME, uiReducer),
    EffectsModule.forFeature([UiEffects])
  ],
  providers: [MessageService],
  exports: [
    ToastComponent,
    DropdownSelectorComponent
  ],
  declarations: [
    ToastComponent,
    DropdownSelectorComponent
  ]
})
export class UiModule { }
