<div class="av2-container">
  <h1 class="av2-text-bold-2 av2-page-header" translate>settings.translations.header</h1>
  <div class="av2-header-action-buttons-container">
    <button pButton pRipple type="button" [av2routerLink]="[NAVI.SETTINGS_TRANSLATIONS_CREATE_PATH]" translate
            *hasAccessIf="AV2SettingsViewPermissionsEnum.TRANSLATION_CREATE_FORM_VIEW_ROLE">
      settings.translations.add-translation-button
    </button>
  </div>

  <ng-container *ngIf="translationsList.length > 0 || !!(loading$ | async); else emptyState">
    <p-table [columns]="headers" [value]="translationsList" responsiveLayout="scroll" class="company-table"
      [loading]="!!(loading$ | async)">
      <ng-template pTemplate="header" let-headers>
        <tr>
          <th *ngFor="let item of headers" [ngClass]="item.class!" class="av2-table-sort-header" translate>
            {{item.labelKey}}
          </th>
          <th>&nbsp;</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-translation>
        <ng-container *ngIf="translationsList.length > 0">
          <tr>
            <td class="language">
              <b>{{translation.name}}</b>
            </td>
            <td class="name">
              {{translation.nativeName}}
            </td>
            <td class="progress">
              <div class="translation-progress">
                <app-av2-progress-bar [progress]="translation.progress"></app-av2-progress-bar>
                <span>
                  {{translation.progress}}%
                </span>
              </div>
            </td>
            <td class="options-cell">
              <button class="p-button-outlined overlayButton" type="button" pButton pRipple (click)="op.toggle($event)">
                ⋮
              </button>
              <p-overlayPanel #op styleClass="av2-select-dropdown">
                <ng-template pTemplate>
                  <div class="av2-select-dropdown-panel del-after-detach">
                    <ng-container *ngFor="let option of options">
                      <div class="av2-select-dropdown-item" *hasAccessIf="option.roleRequired" (click)="op.hide(); option.action(translation.tag)">
                        <i class="av2-overlay-panel-item-icon" [ngClass]="option.icon"> </i>
                        {{option.name | translate}}
                      </div>
                    </ng-container>
                  </div>
                </ng-template>
              </p-overlayPanel>
            </td>
          </tr>
        </ng-container>
      </ng-template>
    </p-table>
    <app-paginator [totalRecords]="translationListSize" (pageChange)="onPageChange($event)"></app-paginator>
  </ng-container>
</div>

<ng-template #emptyState>
  <app-list-empty-state class="company-table" [title]="'No languages added'"
    [desc]="'No language has been added yet. Select the Add Language button or click here to create a new company.'"
    [imageName]="'translates'"></app-list-empty-state>
</ng-template>
