import { createFeatureSelector, createSelector } from "@ngrx/store";
import { UI_STORE_NAME } from "../consts/store-name.const";
import { UiState } from "../models/state.model";

export const selectCoreState = createFeatureSelector<UiState>(UI_STORE_NAME);

export const getIsMobileView = createSelector(
  selectCoreState,
  (state: UiState) => state.isMobile
);

export const getIsTabletView = createSelector(
  selectCoreState,
  (state: UiState) => state.isTablet
);

export const getLoaderVisibility = createSelector(
  selectCoreState,
  (state: UiState) => state.loaderVisibility
);

export const getLoaderTableVisibility = createSelector(
  selectCoreState,
  (state: UiState) => state.loaderTableVisibility || false
);

export const getBlockContent = createSelector(
  selectCoreState,
  (state: UiState) => state.blockContent
);
