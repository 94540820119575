import { AV2Environment } from "./av2.environment";

export const environment: AV2Environment = {
  production: true,
  mobileWidth: 500,
  tabletWidth: 1024,
  google: {
    apiKey: 'AIzaSyDDRSsdvwZl-IzD1sHq1oX1LYKQrxmD60s'
  },
  api: {
    host: 'https://api.app2.active-view.eu/api/v1',
    sseHost: 'https://origin-92d7220-api.app2.active-view.eu/api/v1',
    manualUrl: 'https://storage.googleapis.com/av20-prod-file-store/' // TODO check url
  },
  version: '1.2.489',
  paginatorRowPerPageOptions: [10 , 20, 30, 40, 50],
  patrolsIntervalTime: 10000,
  refreshSessionSettings: {
    showCountdownOnSideMenuBeforeTheEndTokenLife: 600,
    showRefreshModalBeforeTheEndTokenLife: 60,
    tryRefreshTokenBeforeTheEndTokenLife: 605,
    tryRefreshTokenBeforeTheEndTokenLifeForUsersWithAutoSessionRefresh: 200
  }
};
