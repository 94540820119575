import {Injectable} from '@angular/core';

@Injectable()
export class BrowserCssLoaderService {
  public readonly browser: Browser;

  constructor() {
    let agent = window.navigator.userAgent.toLowerCase();
    if (agent.includes('opr')) this.browser = Browser.OPERA;
    else if (agent.includes('edg')) this.browser = Browser.EDGE;
    else if (agent.includes('chrome')) this.browser = Browser.CHROME;
    else if (agent.includes('firefox')) this.browser = Browser.FIREFOX;
    else if (agent.includes('safari')) this.browser = Browser.SAFARI;
    else this.browser = Browser.OTHER;
  }

  public startService() {
    if (this.browser === Browser.SAFARI) {
      (document.getElementById('browser-style') as HTMLLinkElement).href = this.browser.toLowerCase() + '.css';
    }
    else if (this.browser === Browser.FIREFOX){
      (document.getElementById('browser-style') as HTMLLinkElement).href = this.browser.toLowerCase() + '.css';
    }
    return;
  }

}

export enum Browser {
  OPERA = "OPERA",
  FIREFOX = "FIREFOX",
  SAFARI = "SAFARI",
  EDGE = "EDGE",
  CHROME = "CHROME",
  OTHER = "OTHER"
}
