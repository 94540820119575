<div class="av2-container">
  <div class="form-box">
    <div class="logo"></div>
    <div class="sign-in-card">
      <router-outlet></router-outlet>
    </div>
    <div class="av2-sign-in-toast" *ngIf="isToastVisible">
      <div class="av2-sign-in-toast-text" translate>
        auth.privacy-consent.text
        <a class="av2-link-button" [href]="privacyPolicyHref" translate>
          auth.privacy-consent.link
        </a>
        auth.privacy-consent.text-connector
        <a class="av2-link-button" [href]="generalTermsHref" translate>
          auth.terms-of-use.link
        </a>
      </div>
      <button pButton pRipple type="button" class="av2-sign-in-toast-button" (click)="changeToastVisibility()">
        <i class="fal fa-times toast-icon"></i>
      </button>
    </div>
  </div>
  <div class="mobile-disable-picture-box">
    <div class="picture-box"></div>
  </div>
</div>
