import { createAction, props } from "@ngrx/store";
import { AV2User } from "../../../../../../commons/models/user";
import { AV2ChangePasswordModel, AV2ProfileDetails } from "../models/my-profile.model";
import { AV2UserSettingsDto } from "../../../../../../commons/models/user-settings";

const actionHeader = "[My profile]";


export const loadProfileDetails = createAction(
  `${actionHeader} load profile details`,
);
export const loadProfileDetailsSuccess = createAction(
  `${actionHeader} load profile details success`,
  props<{ details: AV2User }>()
);
export const loadProfileDetailsFailed = createAction(
  `${actionHeader} load profile details failed`,
);

export const updateProfileDetails = createAction(
  `${actionHeader} update profile details`,
  props<{ details: AV2ProfileDetails; emailChanged?: boolean; }>()
);
export const updateProfileDetailsSuccess = createAction(
  `${actionHeader} update profile details success`,
);
export const updateProfileDetailsSuccessWithLogout = createAction(
  `${actionHeader} update profile details success with logout`,
);
export const updateProfileDetailsFailed = createAction(
  `${actionHeader} load profile details failed`,
);

export const updateProfileImage = createAction(
  `${actionHeader} update profile image`,
  props<{ image: string }>()
);
export const updateProfileImageSuccess = createAction(
  `${actionHeader} update profile image success`,
  props<{ image: string; }>()
);
export const updateProfileImageFailed = createAction(
  `${actionHeader} update profile image failed`,
);

export const changePassword = createAction(
  `${actionHeader} change password`,
  props<{ changePasswordModel: AV2ChangePasswordModel }>()
);
export const changePasswordSuccess = createAction(
  `${actionHeader} change password success`,
);
export const changePasswordFailed = createAction(
  `${actionHeader} change password failed`,
);

export const loadProfileSettings = createAction(
  `${actionHeader} load profile settings`,
);
export const loadProfileSettingsSuccess = createAction(
  `${actionHeader} load profile settings success`,
  props<{ settings: AV2UserSettingsDto }>()
);
export const loadProfileSettingsFailed = createAction(
  `${actionHeader} load profile settings failed`,
);

export const updateProfileSettings = createAction(
  `${actionHeader} update profile settings`,
  props<{ settings: AV2UserSettingsDto }>()
);
export const updateProfileSettingsSuccess = createAction(
  `${actionHeader} update profile settings success`,
  props<{ settings: AV2UserSettingsDto }>()
);
export const updateProfileSettingsFailed = createAction(
  `${actionHeader} update profile settings failed`,
);
