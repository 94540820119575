import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, Subscription, timer } from 'rxjs';
import { NAVI } from 'src/app/commons/consts';

@Component({
  selector: 'app-blocked-sign-in',
  templateUrl: './blocked-sign-in.component.html',
  styleUrls: ['./blocked-sign-in.component.scss']
})
export class BlockedSignInComponent implements OnInit, OnDestroy {

  NAVI = NAVI;
  blockedState: any;
  unlockInfo!: Observable<string>;
  private timeObserver!: Subscription;

  constructor(private router: Router) {
    this.blockedState = this.router.getCurrentNavigation()?.extras?.state;
  }

  ngOnInit(): void {
    this.timeObserver = timer(0, 1000).subscribe(() => {
      if (Date.now() > this.blockedState.timestamp) {
        this.timeObserver?.unsubscribe();
        this.router.navigate([this.NAVI.AUTH_SIGN_IN_PATH]);
      }
      const unlockTime = new Date(this.blockedState.timestamp - Date.now());
      let info = `${unlockTime.getSeconds()}s`;
      const mins = unlockTime.getMinutes();
      if (mins > 0) info = `${mins}m ${info}`;
      this.unlockInfo = of(info);
    });
  }
  
  ngOnDestroy(): void {
    this.timeObserver?.unsubscribe();
  }

}
