import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AV2ProgressBarComponent } from './components/av2-progress-bar/av2-progress-bar.component';
import { PrimeNgModule } from 'src/app/prime-ng.module';

@NgModule({
  declarations: [
    AV2ProgressBarComponent
  ],
  imports: [
    CommonModule,
    PrimeNgModule
  ],
  exports: [
    AV2ProgressBarComponent
  ]
})
export class ProgressBarModule { }
