<div class="av2-container">
  <div class="content">
    <h1 class="av2-text-bold-2 av2-page-header" translate>settings.translation.download.page-header</h1>
    <form *ngIf="languageForm" [formGroup]="languageForm" (submit)="submit()">
      <div class="av2-container">
        <div class="av2-info-bar">
          <i class="fa fa-info-circle icon"></i>
          <p translate>settings.translation.create.info-bar</p>
        </div>
      </div>
      <div class="av2-container">
        <div class="av2-form-field language">
          <label for="language" translate>settings.translation.create.input.label.language</label>
          <p-dropdown [options]="languages" formControlName="language" optionValue="tag" optionLabel="name" [filter]="languages.length > 5" filterBy="combinedNames"
            [placeholder]="'settings.translation.create.input.placeholder.language' | translate">
            <ng-template let-option pTemplate="item">
              <div class="av2-menu-dropdown-item">
                <span>
                  {{option.nativeName}}
                </span>
                <span>
                  -
                </span>
                <span>
                  {{option.name}}
                </span>
              </div>
            </ng-template>
          </p-dropdown>
          <app-error-validation [form]="languageForm.get('languageForm')" [controlName]="'language'">
          </app-error-validation>
        </div>
      </div>
      <div class="av2-container">
        <button class="cancel-button p-button-secondary" type="button" pButton pRipple
          [label]="'ui.common.cancel' | translate" (click)="cancel()"></button>
        <button class="submit-button" type="submit" pButton pRipple [label]="'ui.common.download' | translate"></button>
      </div>
    </form>
  </div>
</div>
