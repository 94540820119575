import { Pipe, PipeTransform } from '@angular/core';
import { AV2ClientType } from 'src/app/modules/client/store/models/client.model';

@Pipe({
  name: 'hasSmsEditConfigurationPermission'
})
export class CheckSmsEditConfigurationPermissionPipe implements PipeTransform {

   transform(selfSmsConfiguration: boolean | undefined, userClientId: number, clientId: number, parentId: number): boolean {
    let hasPermission = false;

    if (!!selfSmsConfiguration) {
      hasPermission = userClientId === clientId;
    }

    if (!selfSmsConfiguration) {
      hasPermission = userClientId === parentId;
    }

    return hasPermission;
  }

}
