import { environment } from "../../../environments/environment";
import {clientId} from "./navigation.const";

export const API = 'api';
export const API_HOST = `${environment.api.host}`;
export const API_HOST_SSE = `${environment.api.sseHost}`;
export const API_USER_SERVICE = `${API_HOST}/user-service/${API}`;
export const API_COMPANY_SITE_SERVICE = `${API_HOST}/company-site-service/${API}`;
export const API_TRANSLATION_SERVICE = `${API_HOST}/translation-service/${API}`;
export const API_CLIENT_PRICELIST_SERVICE = `${API_HOST}/client-pricelist-service/${API}`;
export const API_CLIENT_EVENT_CONFIGURATION_SERVICE = `${API_HOST}/event-configuration-service/${API}`;
export const API_CLIENT_TASK_CONFIGURATION_SERVICE = `${API_HOST}/task-service/${API}`;
export const API_TIMETABLE_SERVICE = `${API_HOST}/timetable-service/${API}`;
export const API_LICENSE_SERVICE = `${API_HOST}/license-service/${API}`;
export const API_BILLING_SERVICE = `${API_HOST}/billing-service/${API}`;
export const API_ALARM_EVENT_SERVICE = `${API_HOST}/alarm-event-service/${API}`;
export const API_CLIENT_ALARM_EVENT_SERVICE = `${API_HOST}/alarm-event-service/${API}`;
export const API_NOTIFICATION_SERVICE = `${API_HOST}/notification-service/${API}`;
export const API_PROXY_SERVICE = `${API_HOST}/api-proxy-service/${API}`;
export const API_LOCATION_SERVICE = `${API_HOST}/location-service/${API}/v1`;

// USER SERVICE
// [USER]
export const USER_SERVICE_LOGIN_API = `${API_USER_SERVICE}/login`;
export const USER_SERVICE_LOGOUT_API = `${API_USER_SERVICE}/logout`;
export const USER_SERVICE_REFRESHTOKEN_API = `${API_USER_SERVICE}/refreshToken`;
export const USER_SERVICE_USER_API = `${API_USER_SERVICE}/user`;
export const USER_SERVICE_CHANGE_PASSWORD_API = `${API_USER_SERVICE}/changePassword`;
export const USER_SERVICE_RESET_PASSWORD_API = `${API_USER_SERVICE}/resetPassword`;
export const USER_SERVICE_SENT_EMAIL_RESET_PASSWORD_API = `${API_USER_SERVICE}/sendResetPasswordEmail`;
export const USER_SERVICE_CHECK_UUID_RESET_PASSWORD_API = `${API_USER_SERVICE}/resetPassword/checkUUID`;
export const USER_SERVICE_TIMEZONE_API = `${API_USER_SERVICE}/timezone`;
export const USER_SERVICE_ACCEPT_CONDITIONS = `${USER_SERVICE_USER_API}/accept-terms`;
export const USER_SERVICE_RESET_PIN_API = `${API_USER_SERVICE}/reset-pin`
// [CLIENT USER]
export const USER_SERVICE_CLIENT_USER_API = `${API_USER_SERVICE}/client`;
// [DISTRIBUTOR USER]
export const USER_SERVICE_DISTRIBUTOR_USER_API = `${API_USER_SERVICE}/user`;
// [ROLE]
export const USER_SERVICE_ROLE_BASE_API = `${API_USER_SERVICE}/role/base`;
export const USER_SERVICE_ROLE_COMPOSITE_API = `${API_USER_SERVICE}/role/composite`;
export const USER_SERVICE_ROLE_API = `${API_USER_SERVICE}/role`;


// LICENSE SERVICE
// [LICENSE]
export const LICENSE_SERVICE_LICENSE_TYPE_API = `${API_LICENSE_SERVICE}/license-type`;
export const LICENSE_LICENSE_API = `${API_LICENSE_SERVICE}/license`
//[PRODUCT]
export const LICENSE_SERVICE_LICENSE_BASE_PRODUCT_API = `${API_LICENSE_SERVICE}/product/base-license`;
// [DEVICE]
export const LICENSE_SERVICE_DEVICE_API = `${API_LICENSE_SERVICE}/device`
export const LICENSE_SERVICE_DEVICE_LIST_API = `${LICENSE_SERVICE_DEVICE_API}/list`
// [LICENSE-TYPE-CATALOG]
export const LICENSE_SERVICE_LICENSE_DEVICE_CATALOG = `${API_LICENSE_SERVICE}/client`;
// [DEVICE CONFIGURATION]
export const NOTIFICATION_SERVICE_DEVICE_CONFIGURATION_API = `${API_NOTIFICATION_SERVICE}/device-configuration`;

// CLIENT PRICELIST SERVICE
// [PRICELIST]
export const CLIENT_PRICELIST_SERVICE_PRICELIST_API = `${API_CLIENT_PRICELIST_SERVICE}/pricelist`;
export const CLIENT_PRICELIST_SERVICE_PRICELIST_LIST_API = `${API_CLIENT_PRICELIST_SERVICE}/pricelist/list`;
// [CURRENCY EXCHANGE RATE]
export const CLIENT_PRICELIST_SERVICE_CURRENCT_EXCHANGE_RATE_API = `${API_CLIENT_PRICELIST_SERVICE}/currency/exchangeRate`;
// [CLIENT]
export const CLIENT_PRICELIST_SERVICE_CLIENT_API = `${API_CLIENT_PRICELIST_SERVICE}/client`;
// [CURRENCY]
export const CLIENT_PRICELIST_SERVICE_CURRENCY_API = `${API_CLIENT_PRICELIST_SERVICE}/currency`;
export const CLIENT_PRICELIST_SERVICE_CURRENCY_LIST_API = `${API_CLIENT_PRICELIST_SERVICE}/currency/list`;
// [CURRENCY CODE]
export const CLIENT_PRICELIST_SERVICE_CURRENCY_CODE_API = `${API_CLIENT_PRICELIST_SERVICE}/currencyCode`;
// [FILTER]
export const CLIENT_PRICELIST_SERVICE_CLIENT_FILTER_CITY_LIST_API = `${API_CLIENT_PRICELIST_SERVICE}/filter/client/city`;
// [CONTACT]
export const CLIENT_PRICELIST_SERVICE_CONTACT_API = `${API_CLIENT_PRICELIST_SERVICE}/contact`;

// [ALARM_TYPE]
export const CLIENT_ALARM_TYPE_TEMPLATE_SERVICE_API = `${API_CLIENT_EVENT_CONFIGURATION_SERVICE}/alarm-type/template`;
export const CLIENT_ALARM_TYPE_SERVICE_BY_CLIENT_ID_API = `${API_CLIENT_EVENT_CONFIGURATION_SERVICE}/alarm-type/client`;
export const CLIENT_ACCEPT_UNKNOWN_EVENTS_SERVICE_BY_CLIENT_ID_API = `${API_CLIENT_EVENT_CONFIGURATION_SERVICE}/accept-unknown-events`;

// [ALARM_CATEGORY]
export const CLIENT_ALARM_CATEGORY_SERVICE_API = `${API_CLIENT_EVENT_CONFIGURATION_SERVICE}/client`;

// [EVENTS]
export const CLIENT_EVENTS_SERVICE_API = `${API_CLIENT_EVENT_CONFIGURATION_SERVICE}/configuration/template`;
export const CLIENT_EVENTS_SERVICE_BY_CLIENT_ID_API = `${API_CLIENT_EVENT_CONFIGURATION_SERVICE}/configuration/client`;

// [MAIN-EVENTS]
export const CLIENT_MAIN_EVENTS_SERVICE_API = `${API_CLIENT_ALARM_EVENT_SERVICE}/client`;
export const CLIENT_MAIN_EVENTS_DISPLAY_NAMES_TO_SOURCE_NAMES_API = (clientId: number) => `${CLIENT_EVENTS_SERVICE_BY_CLIENT_ID_API}/${clientId}/available-display-names-to-source-names`;

// [PATROLS]
export const PATROLS_SERVICE_API = `${API_TIMETABLE_SERVICE}/patrol`;

// [TASKS]
export const CLIENT_TASKS_SERVICE_API = `${API_CLIENT_TASK_CONFIGURATION_SERVICE}/client`;

// COMPANY SERVICE
// [FILTER]
export const COMPANY_SITE_SERVICE_COMPANY_API_SITE_CITY_LIST = `${API_COMPANY_SITE_SERVICE}/filter/site/city`;
export const COMPANY_SITE_SERVICE_COMPANY_API_SITE_COMPANY_LIST = `${API_COMPANY_SITE_SERVICE}/filter/site/company`;
export const COMPANY_SITE_SERVICE_COMPANY_API_SITE_SITE_LIST = `${API_COMPANY_SITE_SERVICE}/filter/site`;
export const COMPANY_SITE_SERVICE_COMPANY_API_CITY_LIST = `${API_COMPANY_SITE_SERVICE}/filter/company/city`;
// [COMPANY]
export const COMPANY_SITE_SERVICE_COMPANY_API = `${API_COMPANY_SITE_SERVICE}/company`;
export const COMPANY_SITE_SERVICE_COMPANY_LIST_API = `${COMPANY_SITE_SERVICE_COMPANY_API}/list`;
// [SITE]
export const COMPANY_SITE_SERVICE_SITE_API = `${API_COMPANY_SITE_SERVICE}/site`;
export const COMPANY_SITE_SERVICE_SITE_LIST_API = `${COMPANY_SITE_SERVICE_SITE_API}/list`;
export const COMPANY_SITE_SERVICE_SITE_DELETE_LIST_API = `${COMPANY_SITE_SERVICE_SITE_API}/delete/list`;
// [CONTACT]
export const COMPANY_SITE_SERVICE_CONTACT_API = `${API_COMPANY_SITE_SERVICE}/contact`;
export const COMPANY_SITE_SERVICE_CONTACT_LIST_API = `${COMPANY_SITE_SERVICE_CONTACT_API}/list`;
// [SITE USER]
export const COMPANY_SITE_SERVICE_SITE_USER_API = `${API_COMPANY_SITE_SERVICE}/siteUser`;
export const COMPANY_SITE_SERVICE_SITE_USER_SITE_API = `${COMPANY_SITE_SERVICE_SITE_USER_API}/site`;


// TIMETABLE SERVICE
// [ROUTE]
export const TIME_TABLE_SERVICE_ROUTE_API = `${API_TIMETABLE_SERVICE}/route`;
export const TIME_TABLE_SERVICE_ROUTE_FOR_SITE_API = `${API_TIMETABLE_SERVICE}/site`;
// [GOOGLE MAPS]
export const TIME_TABLE_SERVICE_GOOGLE_MAPS_API = `${API_TIMETABLE_SERVICE}/googleMaps`;
export const TIME_TABLE_SERVICE_GOOGLE_MAPS_ADDRESS_API = `${TIME_TABLE_SERVICE_GOOGLE_MAPS_API}/address`;
export const TIME_TABLE_SERVICE_GOOGLE_MAPS_PLACES_API = `${TIME_TABLE_SERVICE_GOOGLE_MAPS_API}/places`;

// TRANSLATION
export const TRANSLATION_SERVICE_API_TRANSLATION = `${API_TRANSLATION_SERVICE}/translation`;
export const TRANSLATION_SERVICE_API_LOKALISE = `${API_TRANSLATION_SERVICE}/lokalise`;
export const TRANSLATION_SERVICE_API_LOKALISE_AVAILABLE = `${TRANSLATION_SERVICE_API_LOKALISE}/available-languages`

// BILLING
export const BILLING_SERVICE_API_BILLING = `${API_BILLING_SERVICE}/billing`;
export const BILLING_SERVICE_API_BILLING_CUMULATIVE = `${BILLING_SERVICE_API_BILLING}/cumulative`;
export const BILLING_SERVICE_API_BILLING_EXCEL = `${BILLING_SERVICE_API_BILLING}/excel`;
export const BILLING_SERVICE_API_BILLING_STATUS = `${BILLING_SERVICE_API_BILLING}/status`;

export const NOTIFICATION_SERVICE_API_NOTIFICATION = `${API_NOTIFICATION_SERVICE}/notification`;


//MESSAGES
export const NOTIFICATION_SERVICE_API_MESSAGES = `${API_NOTIFICATION_SERVICE}/message`;
export const NOTIFICATION_SERVICE_API_MESSAGES_INBOX = `${NOTIFICATION_SERVICE_API_MESSAGES}/inbox`;
export const NOTIFICATION_SERVICE_API_MESSAGES_SENT = `${NOTIFICATION_SERVICE_API_MESSAGES}/sent`;
export const NOTIFICATION_SERVICE_API_MESSAGES_SEND = `${NOTIFICATION_SERVICE_API_MESSAGES}/send`;
export const NOTIFICATION_SERVICE_API_MESSAGE_UNREAD_COUNTER = `${NOTIFICATION_SERVICE_API_MESSAGES_INBOX}/unread-counter`;
export const NOTIFICATION_SERVICE_API_MESSAGE_MARK_AS_READ = `${NOTIFICATION_SERVICE_API_MESSAGES_INBOX}`;

// PATROLS
export const PATROLS_WEBSOCKET = (userId: number) => `/topic/${userId}/patrol`;

//MAIN-EVENTS
export const MAIN_EVENTS_WEBSOCKET = (userId: number) => `/topic/${userId}/event`;

// TAG SERVICE
// [TAG]
export const TAG_SERVICE_API = `${API_HOST}/tag-service/${API}`;
export const TAG_API = `${TAG_SERVICE_API}/tag`;
export const TAG_COMPANY_API = `${TAG_SERVICE_API}/tag/company`;

// LICENSE SERVICE
export const LICENSE_SERVICE_API_LICENSE = `${API_LICENSE_SERVICE}/license`;
export const LICENSE_SERVICE_API_PRODUCT = `${API_LICENSE_SERVICE}/product`;
export const LICENSE_SERVICE_API_ADDITIONAL_SERVICE = `${API_LICENSE_SERVICE}/additionalService`;

// ALARM SERVICE
// [alarm]
export const ALARM_EVENT_SERVICE_ALARM = `${API_ALARM_EVENT_SERVICE}/client`;

//REPORTS
export const API_REPORT_SERVICE = `${API_HOST}/report-service/${API}`;
export const API_READY_GRAPHICAL_REPORT_JSON_DOWNLOAD = `${API_REPORT_SERVICE}/ready-graphical-report-json/download`;
export const API_REPORT_SERVICE_REPORT = `${API_REPORT_SERVICE}/report`;
export const API_REPORT_SERVICE_RECURRING_REPORT = `${API_REPORT_SERVICE}/recurring-report`;
export const API_REPORT_SERVICE_REPORT_TEMPLATE = `${API_REPORT_SERVICE_REPORT}/template`;
export const API_REPORT_SERVICE_REPORT_TEMPLATE_SCOPES = `${API_REPORT_SERVICE_REPORT_TEMPLATE}/scopes`;
export const API_REPORT_SERVICE_REPORT_GENERATE = `${API_REPORT_SERVICE_REPORT}/graphical`;
export const API_REPORT_SERVICE_REPORT_GENERATED = `${API_REPORT_SERVICE_REPORT}/generated`;
export const API_REPORT_SERVICE_REPORT_FILTER = `${API_REPORT_SERVICE_REPORT}/filters/list`; // TODO: confirm
export const API_REPORT_SERVICE_REPORT_EXCEL_ALARM = `${API_REPORT_SERVICE_REPORT}/excel/alarm`;
export const API_REPORT_SERVICE_REPORT_EXCEL_TASK = `${API_REPORT_SERVICE_REPORT}/excel/task`;
export const API_REPORT_SERVICE_REPORT_EXCEL_PATROL = `${API_REPORT_SERVICE_REPORT}/excel/patrol`;
export const API_REPORT_SERVICE_REPORT_EXCEL_EMPLOYEE = `${API_REPORT_SERVICE_REPORT}/excel/worker`;
export const API_REPORT_SERVICE_REPORT_EXCEL_EVENT = `${API_REPORT_SERVICE_REPORT}/excel/alarmEvent`;
export const API_REPORT_SERVICE_REPORT_EXCEL_LICENSE_HISTORY = `${API_REPORT_SERVICE_REPORT}/excel/licenseHistory`;
export const API_REPORT_SERVICE_REPORT_TABLE_ALARM = `${API_REPORT_SERVICE_REPORT}/table/alarm`;
export const API_REPORT_SERVICE_REPORT_TABLE_TASK = `${API_REPORT_SERVICE_REPORT}/table/task`;
export const API_REPORT_SERVICE_REPORT_TABLE_PATROL = `${API_REPORT_SERVICE_REPORT}/table/patrol`;
export const API_REPORT_SERVICE_REPORT_TABLE_EMPLOYEE = `${API_REPORT_SERVICE_REPORT}/table/worker`;
export const API_REPORT_SERVICE_REPORT_TABLE_EVENT = `${API_REPORT_SERVICE_REPORT}/table/eventAlarm`;
export const API_REPORT_SERVICE_REPORT_FILTERS = `${API_REPORT_SERVICE_REPORT}/filters`
// DASHBOARD
export const API_REPORT_SERVICE_DASHBOARD = `${API_REPORT_SERVICE}/dashboard`
export const API_REPORT_SERVICE_CLIENTS_DASHBOARD = `${API_REPORT_SERVICE_DASHBOARD}/clients`
export const API_REPORT_SERVICE_LICENSES_DASHBOARD = `${API_REPORT_SERVICE_DASHBOARD}/licenses`
export const API_REPORT_SERVICE_DEVICES_DASHBOARD = `${API_REPORT_SERVICE_DASHBOARD}/devices`
export const API_REPORT_SERVICE_SITES_DASHBOARD = `${API_REPORT_SERVICE_DASHBOARD}/sites`
export const API_REPORT_SERVICE_ALARMS_DASHBOARD = `${API_REPORT_SERVICE_DASHBOARD}/alarms`
export const API_REPORT_SERVICE_TASKS_DASHBOARD = `${API_REPORT_SERVICE_DASHBOARD}/tasks`
export const API_REPORT_SERVICE_PATROLS_DASHBOARD = `${API_REPORT_SERVICE_DASHBOARD}/patrols`
export const API_REPORT_SERVICE_WORKERS_DASHBOARD = `${API_REPORT_SERVICE_DASHBOARD}/workers`

//API PROXY
export const API_PROXY_API_KEY = `${API_PROXY_SERVICE}/api-key`
export const API_PROXY_RETRANSMISSION_CONFIG = `${API_PROXY_SERVICE}/retransmission-config`
export const API_PROXY_RETRANSMISSION_KEYS = `${API_PROXY_SERVICE}/retransmission/api-key`

// API ALARM_NOTIFICATION

export const API_NOTIFICATION_EMAIL_SERVER_CONFIGURATION = `${API_NOTIFICATION_SERVICE}/email-configuration`
export const API_NOTIFICATION_EMAIL_SENDING_CONFIGURATION = `${API_NOTIFICATION_SERVICE}/email-sending-config`
export const API_NOTIFICATION_EMAIL_PATTERN = `${API_NOTIFICATION_SERVICE}/email-pattern`
export const API_NOTIFICATION_SMS_CONFIGURATION = `${API_NOTIFICATION_SERVICE}/sms-configuration`
export const API_NOTIFICATION_SMS_PATTERN = `${API_NOTIFICATION_SERVICE}/sms-pattern`

//LOCATION SERVICE
export const LOCATION_SERVICE_DEVICE_LOCATION = (clientId: number) => `${API_LOCATION_SERVICE}/client/${clientId}/location/device`;
