import {Injectable, OnDestroy} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable, Subscription} from "rxjs";
import {select, Store} from "@ngrx/store";
import {AV2AuthState} from "../../modules/auth/store/models/state.model";
import {getImpersonatedUserData} from "../../modules/auth/store/selectors/auth.selectors";
import {API_USER_SERVICE} from "../../commons/consts/api.const";

@Injectable()
export class ImpersonateInterceptor implements HttpInterceptor, OnDestroy {

  impersonateMode: boolean = false;
  impersonateClientId?: number
  subscription: Subscription;

  constructor(private store$: Store<AV2AuthState>) {
    this.subscription = this.store$.pipe(select(getImpersonatedUserData))
      .subscribe(data => {
        if (data.impersonatedUserId) {
          this.impersonateMode = true
          this.impersonateClientId = data.impersonatedUserId
        } else {
          this.impersonateMode = false
          this.impersonateClientId = undefined
        }
      })
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(this.impersonateMode && this.impersonateClientId && !request.url.includes(`${API_USER_SERVICE}/impersonate`)) {
      const impersonateClientIdHeader = 'X-CLIENT-ID';
      const modifiedReq = request.clone({
        headers: request.headers.set(impersonateClientIdHeader, this.impersonateClientId.toString()),
      });
      return next.handle(modifiedReq);
    }
    return next.handle(request);
  }

  ngOnDestroy() {
    this.subscription && this.subscription.unsubscribe();
  }
}
