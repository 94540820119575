export class Interpolator {
  static templateMatcher: RegExp = /{{\s?([^{}\s]*)\s?}}/g;

  /**
   * Interpolates a string to replace parameters
   * "This is a {{ key }}" ==> "This is a value", with params = { key: "value" }
   * @param expr
   * @param params
   */
  public static interpolate(expr: string | Function, params?: any): string {
    let result: string;

    if (typeof expr === "string") {
      result = this.interpolateString(expr, params);
    } else if (typeof expr === "function") {
      result = this.interpolateFunction(expr, params);
    } else {
      // this should not happen, but to be sure
      result = expr as string;
    }
    return result;
  }

  /**
   * Gets a value from an object by composed key
   * parser.getValue({ key1: { keyA: 'valueI' }}, 'key1.keyA') ==> 'valueI'
   * @param target
   * @param key
   */
  public static getValue(target: any, key: string): any {
    if (target instanceof Map) {
      target = Array.from(target.entries()).reduce((main, [key, value]) => ({ ...main, [key]: value }), {});
    }
    let keys = typeof key === "string" ? key.split(".") : [key];
    key = "";
    do {
      key += keys.shift();
      if (isDefined(target) && isDefined(target[key]) && (typeof target[key] === "object" || !keys.length)) {
        target = target[key];
        key = "";
      } else if (!keys.length) {
        target = undefined;
      } else {
        key += ".";
      }
    } while (keys.length);

    return target;
  }

  private static interpolateFunction(fn: Function, params?: any) {
    return fn(params);
  }

  private static interpolateString(expr: string, params?: any) {
    if (!params) {
      return expr;
    }

    return expr.replace(this.templateMatcher, (substring: string, b: string) => {
      let r = Interpolator.getValue(params, b);
      return isDefined(r) ? r : substring;
    });
  }
}

export function isDefined(value: any): boolean {
  return typeof value !== "undefined" && value !== null;
}
