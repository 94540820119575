import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { switchMap, tap, map } from "rxjs/operators";
import {
  changeLanguage, delayAction,
  hideLoader,
  locationBack,
  locationUp,
  navigate,
  setTheme,
  showToast
} from '../actions/ui.actions';
import { Location } from '@angular/common';
import { EMPTY, from } from 'rxjs';
import { Router } from '@angular/router';
import { fillPathByIDs } from '../../../../directives/router-link.directive';
import { ThemeService } from '../../../../../theme.service';
import { Store } from '@ngrx/store';
import { LocationBackGuardService } from '../../../../../core/services/locationBackGuard.service';
import { loadAllNotifications } from 'src/app/modules/notifications/store/actions/notifications.actions';
import ignoreDuplicateInTime from "../../../../helpers/ignoreDuplicateInTime";
import { sleep } from "../../../../helpers/dateUtils";
import { AuthService } from 'src/app/modules/auth/store/services/auth.service';

@Injectable()
export class UiEffects {
  constructor(
    private actions$: Actions,
    private messageService: MessageService,
    private translateService: TranslateService,
    private readonly location: Location,
    private router: Router,
    private theme: ThemeService,
    private primeNGConfig: PrimeNGConfig,
    private store$: Store,
    private authService: AuthService,
    private locationBackGuard: LocationBackGuardService
  ) {}

  showToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(showToast),
        ignoreDuplicateInTime(1000),
        switchMap((action) => {
          if (action.message?.length > 0) {
            return this.translateService.get(action.message, action.interpolateParams).pipe(
              tap((trans) =>
                this.messageService.add({
                  severity: action.severity,
                  summary: !!action.interpolateParams ? trans + ' ' + action.interpolateParams : trans,
                  sticky: action.severity === 'error' || action.severity === 'custom',
                  icon: action.severity === 'custom' ? 'fas fa-exclamation-triangle' : undefined,
                  key: action.severity === 'custom' ? 'br' : action.message.includes('token') ? 'token' : 'default',
                })
              )
            );
          } else return EMPTY;
        })
      ),
    { dispatch: false }
  );

  locationBack$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(locationBack),
        tap(() => this.location.back())
      ),
    { dispatch: false }
  );

  locationUp$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(locationUp),
        tap(() => this.locationBackGuard.locationUP())
      ),
    { dispatch: false }
  );

  navigate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigate),
        switchMap((action) =>
          from(
            this.router
              .navigate(!action.idsToReplace ? [action.path] : [fillPathByIDs(action.path, action.idsToReplace)])
              .then((res) => !!res && !!action.hideLoaderAfterNavigate && this.store$.dispatch(hideLoader()))
          )
        )
      ),
    { dispatch: false }
  );

  setTheme$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setTheme),
        tap((action) => this.theme.setTheme(action.theme))
      ),
    { dispatch: false }
  );

  changeLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeLanguage),
      tap(action => this.authService.getAuthDocsLanguage(true, action.locale)),
      switchMap((action) => this.translateService.use(action.locale)),
      map(() => loadAllNotifications()),
      tap(() => this.translateService.get("primeng").subscribe((trans) => this.primeNGConfig.setTranslation(trans)))
    )
  );

  delayAction$ = createEffect(() => this.actions$.pipe(
    ofType(delayAction),
    tap((action) => {
        sleep(action.delay ?? 0).then(() => this.store$.dispatch(action.action));
      }
    )), { dispatch: false });
}
