import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

if (environment.production) {
  enableProdMode();
}
window.onbeforeunload = () => {
  sessionStorage.clear()
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

//防止“返回”离开应用程序
for (let i = 0; i < 25; i++) {
  history.pushState({navigationId: 1}, '');
}
