import { Component, OnInit } from '@angular/core';
import { NAVI } from 'src/app/commons/consts';

@Component({
  selector: 'app-success-send-password-email',
  templateUrl: './success-send-password-email.component.html',
  styleUrls: ['./success-send-password-email.component.scss']
})
export class SuccessSendPasswordEmailComponent {

  NAVI = NAVI;


}
