//region PREFIX && SUFFIX
export const CREATE = "create";
export const EDIT = "edit";
export const COPY = "copy";
export const LIST = "list";
export const DETAILS = "details";
export const OVERVIEW = "overview";
export const CONNECT = "connect";

//endregion

//region CORE
export const COMPANY = "company";
export const OBJECT = "object";
export const ROUTE = "route";
export const TAGS = "tags";
export const CONTACT = "contact";
export const PRICELIST = "pricelist";
export const ADDITIONAL_SERVICES = "additional-services";
export const LICENSE_TYPE = "license-type";
export const ALARM_TYPE = "alarm-type";
export const ALARM_TYPE_TEMPLATE = "alarm-type-template";
export const MAIN_EVENTS = "main-events";
export const PATROLS = "patrols";
export const EVENTS = "events";
export const CHURN_HISTORY = "churn-history";
export const LICENSE_HISTORY = "license-history";
export const EVENT_TPL = "event-template"
export const TASK_TPL = "task-template";
export const USER = "user";
export const USERS = "users";
export const ROLE = "role";
export const TIMETABLE = "timetable";
export const REPORT_BASIC = "basic";
export const REPORT_ADVANCED = "advanced";
export const REPORT_RECURRING = "report-recurring";
export const REPORT_TEMPLATE = "report-template";
export const REPORT_GENERATED = "report-generated";
export const BILLINGS = "billings";
export const REPORT_GRAPHICAL = "report-graphical";
export const FILTER = "filter";
export const API_ACCESS = "api-access";
export const RETRANSMISSION = "retransmission";
export const ALARM_NOTIFICATIONS = "alarm-notifications";
export const GENERATE = "generate";
//endregion

//region MODULES
export const ADMINISTRATION = "administration";
export const DISTRIBUTOR = "distributor";
export const CLIENT = "client";
export const SETTINGS = "settings";
export const REPORT = "report";
export const LICENSE = "licenses";
export const DEVICE = "device";
export const PRODUCT = "product";
export const EMPLOYEE = "employee";
export const WORKERS = "workers";
export const TAG = "tag";
export const MY_PROFILE = "my-profile";
export const TASK = "task";
export const TASKS = "tasks";
export const ALARM = "alarm";
export const ALARMS = "alarms";
export const ALARM_CATEGORIES = "alarm-categories";
export const EMAIL_SMS_CONFIG = "email-sms-configuration";
export const INSTRUCTIONS = "instructions";
export const INSTRUCTION = "instruction";
export const MESSAGES = "messages";

export const TRANSLATIONS = "translations";
export const CURRENCY_AND_RATE = "currency-and-rate";

export const CONFIRMATION = "confirmation";
export const STYLE_GUIDE = "style-guide";
//endregion

//region ADDITIONALS
export const DASHBOARD = "dashboard";
export const READING_DATA = "reading-data";
export const READING_DATA_PROGRESS = "in-progress";
export const READING_DATA_PROGRESS_ERROR = "in-progress-error";
export const MANAGE_LICENSE = "manage-license";
export const ASSIGN = "assign";
export const TRANSFER = "transfer";
export const PASS = "pass";
export const WORK_TIME_REPORT = "work-time-report";
export const IMAGE = "image";
export const LOGO = "logo";
export const EMAIL_SERVER_CONFIGURATION = "email-server-configuration";
export const EMAIL_SENDING_CONFIGURATION = "email-sending-configuration";
export const ALARM_EMAIL_MESSAGES_CONFIGURATION = "alarm-email-messages-configuration";
export const REPORT_EMAIL_MESSAGES_CONFIGURATION = "report-email-messages-configuration";
export const SMS_CONFIGURATION = "sms-configuration";
export const ALARM_SMS_MESSAGES_CONFIGURATION = "alarm-sms-messages-configuration";
export const SECURITY = "security";
export const PERSONALIZATION = "personalization";
export const ADD_DAY_OFF = "add-day-off";
export const CHANGE_MODE = "change-mode";
export const TPL = "tpl";
export const excel = "excel";
export const PIN_ATM = "pin-atm";
export const GENERATE_REPORT = "generate-report";
export const RETURN = "return";
export const RECEIVED = "received";
export const SENT = "sent";


//endregion

//region IDs
export const roleName = ":roleName";
export const companyId = ":companyId";
export const contactId = ":contactId";
export const siteId = ":siteId";
export const routeId = ":routeId";
export const tagId = ":tagId";
export const currencyId = ":currencyId";
export const clientId = ":clientId";
export const pricelistId = ":pricelistId";
export const eventId = ":eventId";
export const eventTemplateId = ":eventTemplateId";
export const eventKey = ":eventKey";
export const patrolId = ":patrolId";
export const taskTemplateId = ":taskTemplateId";
export const admin_client_id = ":admin_client_id";
export const licenseTypeId = ":licenseTypeId";
export const alarmTypeId = ":alarmTypeId";
export const alarmTypeTemplateId= ":alarmTypeTemplateId"
export const clientPriceListId = ":clientPriceListId";
export const additionalServiceId = ":additionalServiceId";
export const userId = ":userId";
export const licenseId = ":licenseId";
export const deviceId = ":deviceId";
export const uuid = ":uuid";
export const productId = ":productId";
export const employeeId = ":employeeId";
export const taskId = ":taskId";
export const alarmId = ":alarmId";
export const alarmCategoryId = ":alarmCategoryId";
export const timetableId = ":timetableId";
export const dateFrom = ":dateFrom";
export const dateTo = ":dateTo";
export const reportTemplateId = ":reportTemplateId";
export const reportRecurringId = ":reportRecurringId";
export const reportFilterId = ":reportFilterId";
export const instructionId = ":instructionId";
export const messageId = ":messageId";

export const IDS = {
  roleName,
  companyId,
  contactId,
  siteId,
  routeId,
  tagId,
  currencyId,
  clientId,
  pricelistId,
  eventId,
  eventTemplateId,
  eventKey,
  clientPriceListId,
  userId,
  licenseId,
  licenseTypeId,
  deviceId,
  timetableId,
  admin_client_id,
  additionalServiceId,
  uuid,
  productId,
  employeeId,
  taskTemplateId,
  taskId,
  alarmId,
  patrolId,
  alarmCategoryId,
  dateFrom,
  dateTo,
  reportTemplateId,
  reportRecurringId,
  reportFilterId,
  alarmTypeId,
  alarmTypeTemplateId,
  instructionId,
  messageId
};
type idsType = keyof typeof IDS;
export type IDSType = Partial<Record<idsType, number | string>>;
export type paramIDSType = Record<idsType, string>;
//endregion

//region AUTH
export const AUTH = "auth";
export const SIGN_IN = "sign-in";
export const RESET_PASSWORD = "reset-password";
export const MAIL_PASSWORD = "mail-password";
export const SUCCESS_PASSWORD = "success-password";
export const SUCCESS_SEND_PASSWORD = "success-send-password";
export const ERROR_PASSWORD = "error-password";
export const BLOCKED_SIGN_IN = "blocked-sign-in";
export const CONDITIONS_SIGN_IN = "conditions-sign-in";
export const AUTH_PATH = `/${AUTH}`;
export const AUTH_SIGN_IN_PATH = `${AUTH_PATH}/${SIGN_IN}`;
export const AUTH_MAIL_PASSWORD_PATH = `${AUTH_PATH}/${MAIL_PASSWORD}`;
export const AUTH_SUCCESS_PASSWORD_PATH = `${AUTH_PATH}/${SUCCESS_PASSWORD}`;
export const AUTH_SUCCESS_SEND_PASSWORD_PATH = `${AUTH_PATH}/${SUCCESS_SEND_PASSWORD}`;
export const AUTH_ERROR_PASSWORD_PATH = `${AUTH_PATH}/${ERROR_PASSWORD}`;
export const AUTH_BLOCKED_SIGN_IN_PATH = `${AUTH_PATH}/${BLOCKED_SIGN_IN}`;
export const AUTH_CONDITIONS_SIGN_IN_PATH = `${AUTH_PATH}/${CONDITIONS_SIGN_IN}`;

//endregion

export const DASHBOARD_PATH = `/${DASHBOARD}`;

//region MAIN SECTION: Administration
export const ADMINISTRATION_PATH = `/${ADMINISTRATION}`;
export const ADMINISTRATION_OVERVIEW_PATH = `${ADMINISTRATION_PATH}/${OVERVIEW}`;
// SUB SECTION: Company
export const ADMINISTRATION_COMPANY_PATH = `${ADMINISTRATION_OVERVIEW_PATH}/${COMPANY}`;
export const ADMINISTRATION_COMPANY_LIST_PATH = `${ADMINISTRATION_COMPANY_PATH}/${LIST}`;
export const ADMINISTRATION_COMPANY_CREATE_PATH = `${ADMINISTRATION_COMPANY_LIST_PATH}/${CREATE}`;
export const ADMINISTRATION_COMPANY_OVERVIEW_PATH = `${ADMINISTRATION_COMPANY_LIST_PATH}/${OVERVIEW}/${companyId}`;
export const ADMINISTRATION_COMPANY_CONTACT_CREATE_PATH = `${ADMINISTRATION_COMPANY_OVERVIEW_PATH}/${CONTACT}/${CREATE}`;
export const ADMINISTRATION_COMPANY_CONFIRMATION_PATH = `${ADMINISTRATION_COMPANY_LIST_PATH}/${CONFIRMATION}`;

// SUB SECTION: Object/Site
export const ADMINISTRATION_OBJECT_PATH = `${ADMINISTRATION_OVERVIEW_PATH}/${OBJECT}`;
export const ADMINISTRATION_OBJECT_LIST_PATH = `${ADMINISTRATION_OBJECT_PATH}/${LIST}`;
export const ADMINISTRATION_OBJECT_OVERVIEW_PATH = `${ADMINISTRATION_OBJECT_LIST_PATH}/${OVERVIEW}/${companyId}/${siteId}`;
export const ADMINISTRATION_OBJECT_CONTACT_CREATE_PATH = `${ADMINISTRATION_OBJECT_OVERVIEW_PATH}/${CONTACT}/${CREATE}`;
export const ADMINISTRATION_OBJECT_CREATE_PATH = `${ADMINISTRATION_OBJECT_LIST_PATH}/${CREATE}`;
export const ADMINISTRATION_OBJECT_CREATE_FOR_COMPANY_PATH = `${ADMINISTRATION_OBJECT_CREATE_PATH}/${companyId}`;
export const ADMINISTRATION_OBJECT_INSTRUCTION_OVERVIEW_PATH = `${ADMINISTRATION_OBJECT_OVERVIEW_PATH}/${INSTRUCTION}/${instructionId}/${OVERVIEW}`;
export const ADMINISTRATION_OBJECT_INSTRUCTION_EDIT_PATH = `${ADMINISTRATION_OBJECT_OVERVIEW_PATH}/${INSTRUCTION}/${instructionId}/${EDIT}`;
export const ADMINISTRATION_OBJECT_INSTRUCTION_CREATE_PATH = `${ADMINISTRATION_OBJECT_OVERVIEW_PATH}/${INSTRUCTION}/${CREATE}`;

// SUB SECTION: Route
export const ADMINISTRATION_ROUTE_PATH = `${ADMINISTRATION_OVERVIEW_PATH}/${ROUTE}`;
export const ADMINISTRATION_ROUTE_LIST_PATH = `${ADMINISTRATION_ROUTE_PATH}/${LIST}`;
export const ADMINISTRATION_ROUTE_CREATE_PATH = `${ADMINISTRATION_ROUTE_LIST_PATH}/${CREATE}`;
export const ADMINISTRATION_ROUTE_CREATE_FOR_COMPANY_PATH = `${ADMINISTRATION_ROUTE_CREATE_PATH}/${companyId}`;
export const ADMINISTRATION_ROUTE_CREATE_FOR_SITE_PATH = `${ADMINISTRATION_ROUTE_CREATE_PATH}/${companyId}/${siteId}`;
// SUB SECTION: TAG
export const ADMINISTRATION_TAG_PATH = `${ADMINISTRATION_OVERVIEW_PATH}/${TAGS}`;
export const ADMINISTRATION_TAG_LIST_PATH = `${ADMINISTRATION_TAG_PATH}/${LIST}`;
export const ADMINISTRATION_TAG_DETAILS_PATH = `${ADMINISTRATION_TAG_LIST_PATH}/${DETAILS}/${tagId}`;
export const ADMINISTRATION_TAG_CREATE_PATH = `${ADMINISTRATION_TAG_LIST_PATH}/${CREATE}`;
export const ADMINISTRATION_TAG_CREATE_READING_DATA_PATH = `${ADMINISTRATION_TAG_CREATE_PATH}/${READING_DATA}`;
export const ADMINISTRATION_TAG_CREATE_READING_DATA_PROGRESS_PATH = `${ADMINISTRATION_TAG_CREATE_READING_DATA_PATH}/${READING_DATA_PROGRESS}/${deviceId}/${clientId}`;
export const ADMINISTRATION_TAG_CREATE_READING_DATA_PROGRESS_ERROR_PATH = `${ADMINISTRATION_TAG_CREATE_READING_DATA_PATH}/${READING_DATA_PROGRESS_ERROR}/${clientId}`;

// SUB SECTION: LICENSE
export const ADMINISTRATION_LICENSE_PATH = `${ADMINISTRATION_OVERVIEW_PATH}/${LICENSE}`;
export const ADMINISTRATION_LICENSE_LIST_PATH = `${ADMINISTRATION_LICENSE_PATH}/${LIST}`;
export const ADMINISTRATION_LICENSE_OVERVIEW_PATH = `${ADMINISTRATION_LICENSE_LIST_PATH}/${OVERVIEW}/${licenseId}`;

// SUB SECTION: DEVICE
export const ADMINISTRATION_DEVICE_PATH = `${ADMINISTRATION_OVERVIEW_PATH}/${DEVICE}`;
export const ADMINISTRATION_DEVICE_LIST_PATH = `${ADMINISTRATION_DEVICE_PATH}/${LIST}`;
export const ADMINISTRATION_DEVICE_DETAILS_PATH = `${ADMINISTRATION_DEVICE_LIST_PATH}/${DETAILS}/${deviceId}/${admin_client_id}`;
// ${clientId}
export const ADMINISTRATION_DEVICE_PASS_PATH = `${ADMINISTRATION_DEVICE_DETAILS_PATH}/${PASS}`;
export const ADMINISTRATION_DEVICE_CONNECT_TO_ATM_PATH = `${ADMINISTRATION_DEVICE_DETAILS_PATH}/${CONNECT}`;
export const ADMINISTRATION_DEVICE_CONNECT_TO_ATM_EDIT_PATH = `${ADMINISTRATION_DEVICE_DETAILS_PATH}/${CONNECT}/${EDIT}`;
export const ADMINISTRATION_DEVICE_DETAILS_MANAGE_LICENSE_PATH = `${ADMINISTRATION_DEVICE_DETAILS_PATH}/${MANAGE_LICENSE}`;
export const ADMINISTRATION_DEVICE_ASSIGN_PATH = `${ADMINISTRATION_DEVICE_DETAILS_PATH}/${ASSIGN}`;

// SUB SECTION: ROLE
export const ADMINISTRATION_ROLE_PATH = `${ADMINISTRATION_OVERVIEW_PATH}/${ROLE}`;
export const ADMINISTRATION_ROLE_LIST_PATH = `${ADMINISTRATION_ROLE_PATH}/${LIST}`;
export const ADMINISTRATION_ROLE_EDIT_PATH = `${ADMINISTRATION_ROLE_LIST_PATH}/${EDIT}/${clientId}/${roleName}`;
export const ADMINISTRATION_ROLE_CREATE_PATH = `${ADMINISTRATION_ROLE_LIST_PATH}/${CREATE}/${clientId}`;


// SUB SECTION: EMPLOYEE
export const ADMINISTRATION_EMPLOYEE_PATH = `${ADMINISTRATION_OVERVIEW_PATH}/${EMPLOYEE}`;
export const ADMINISTRATION_EMPLOYEE_LIST_PATH = `${ADMINISTRATION_EMPLOYEE_PATH}/${LIST}`;
export const ADMINISTRATION_EMPLOYEE_OVERVIEW_PATH = `${ADMINISTRATION_EMPLOYEE_LIST_PATH}/${OVERVIEW}/${employeeId}`;
export const ADMINISTRATION_EMPLOYEE_CREATE_PATH = `${ADMINISTRATION_EMPLOYEE_LIST_PATH}/${CREATE}`;
export const ADMINISTRATION_EMPLOYEE_TIMETABLE_EDIT_WITH_DATE_PATH = `${ADMINISTRATION_EMPLOYEE_OVERVIEW_PATH}/${TIMETABLE}/${EDIT}/${dateFrom}/${dateTo}`;
export const ADMINISTRATION_EMPLOYEE_TIMETABLE_EDIT_GENERATOR = `${ADMINISTRATION_EMPLOYEE_TIMETABLE_EDIT_WITH_DATE_PATH}/${GENERATE}`;

//endregion

//region MAIN SECTION: Events
export const MAIN_EVENTS_PATH = `/${MAIN_EVENTS}`;
export const MAIN_EVENTS_LIST_PATH = `${MAIN_EVENTS_PATH}/${LIST}`;
export const MAIN_EVENTS_DETAILS_PATH = `${MAIN_EVENTS_LIST_PATH}/${OVERVIEW}/${eventKey}/${eventId}`;


//endregion

//region MAIN SECTION: Patrols
export const PATROLS_PATH = `/${PATROLS}`;
export const PATROLS_LIST_PATH = `${PATROLS_PATH}/${LIST}`;
export const PATROLS_DETAILS_PATH = `${PATROLS_LIST_PATH}/${OVERVIEW}/${patrolId}`;


//endregion

//region MAIN SECTION: Tasks
export const TASKS_PATH = `/${TASK}`;
export const TASKS_LIST_PATH = `${TASKS_PATH}/${LIST}`;
export const TASK_OVERVIEW_PATH = `${TASKS_LIST_PATH}/${OVERVIEW}/${taskId}`;
export const TASK_LIST_CREATE_PATH = `${TASKS_LIST_PATH}/${CREATE}`;
//endregion

export const DISTRIBUTOR_PATH = `/${DISTRIBUTOR}`;
export const DISTRIBUTOR_DEVICES_PATH = `${DISTRIBUTOR_PATH}/${DEVICE}/${LIST}`;
export const DISTRIBUTOR_DEVICE_OVERVIEW_PATH = `${DISTRIBUTOR_DEVICES_PATH}/${OVERVIEW}/${deviceId}`;
export const DISTRIBUTOR_LICENSES_PATH = `${DISTRIBUTOR_PATH}/${LICENSE}/${LIST}`;
export const DISTRIBUTOR_LICENSE_OVERVIEW_PATH = `${DISTRIBUTOR_LICENSES_PATH}/${OVERVIEW}/${licenseId}`;
export const DISTRIBUTOR_USERS_PATH = `${DISTRIBUTOR_PATH}/${USERS}/${LIST}`;
export const DISTRIBUTOR_USER_OVERVIEW_PATH = `${DISTRIBUTOR_USERS_PATH}/${CLIENT}/${clientId}/${OVERVIEW}/${userId}`;
export const DISTRIBUTOR_USER_EDIT_PATH = `${DISTRIBUTOR_USERS_PATH}/${CLIENT}/${clientId}/${EDIT}/${userId}`;


//region MAIN SECTION: Client
export const CLIENT_PATH = `/${CLIENT}`;
export const CLIENT_LIST_PATH = `${CLIENT_PATH}/${LIST}`;
export const CLIENT_OVERVIEW_PATH = `${CLIENT_LIST_PATH}/${OVERVIEW}/${clientId}`;
export const CLIENT_EDIT_PATH = `${CLIENT_OVERVIEW_PATH}/${EDIT}`;
export const CLIENT_LOGO_EDIT_PATH = `${CLIENT_OVERVIEW_PATH}/${LOGO}/${EDIT}`;
export const CLIENT_CREATE_PATH = `${CLIENT_LIST_PATH}/${CREATE}`;

const CLIENT_USER_PATH = `${CLIENT_OVERVIEW_PATH}/${USER}`;
export const CLIENT_USER_DETAILS_PATH = `${CLIENT_USER_PATH}/${DETAILS}/${userId}`;

const CLIENT_PRICELIST_PATH = `${CLIENT_OVERVIEW_PATH}/${PRICELIST}`;
export const CLIENT_PRICELIST_DETAILS_PATH = `${CLIENT_PRICELIST_PATH}/${DETAILS}/${clientPriceListId}`;

const CLIENT_ROLE_PATH = `${CLIENT_OVERVIEW_PATH}/${ROLE}`;
export const CLIENT_ROLE_EDIT_PATH = `${CLIENT_ROLE_PATH}/${EDIT}/${roleName}`;


const CLIENT_LICENSE_PATH = `${CLIENT_OVERVIEW_PATH}/${LICENSE}`;
export const CLIENT_LICENSE_DETAILS_PATH = `${CLIENT_LICENSE_PATH}/${OVERVIEW}/${licenseId}`;

const CLIENT_DEVICE_PATH = `${CLIENT_OVERVIEW_PATH}/${DEVICE}`;
export const CLIENT_DEVICE_DETAILS_PATH = `${CLIENT_DEVICE_PATH}/${deviceId}`;
export const CLIENT_DEVICE_DETAILS_MANAGE_LICENSE_PATH = `${CLIENT_DEVICE_DETAILS_PATH}/${MANAGE_LICENSE}`;
export const CLIENT_DEVICE_ASSIGN_PATH = `${CLIENT_DEVICE_DETAILS_PATH}/${ASSIGN}`;
export const CLIENT_DEVICE_PASS_PATH = `${CLIENT_DEVICE_DETAILS_PATH}/${PASS}`;
export const CLIENT_DEVICE_RETURN_PATH = `${CLIENT_DEVICE_DETAILS_PATH}/${RETURN}`;

const CLIENT_ADDITIONAL_SERVICE_PATH = `${CLIENT_OVERVIEW_PATH}/${ADDITIONAL_SERVICES}`;
export const CLIENT_ADDITIONAL_SERVICE_DETAILS_PATH = `${CLIENT_ADDITIONAL_SERVICE_PATH}/${DETAILS}/${additionalServiceId}`;

//endregion

//region SETTINGS
export const SETTINGS_PATH = `/${SETTINGS}`;

export const SETTINGS_PRICELIST_PATH = `${SETTINGS_PATH}/${PRICELIST}`;
export const SETTINGS_PRICELIST_LIST_PATH = `${SETTINGS_PRICELIST_PATH}/${LIST}`;
export const SETTINGS_PRICELIST_CREATE_PATH = `${SETTINGS_PRICELIST_LIST_PATH}/${CREATE}`;
export const SETTINGS_PRICELIST_OVERVIEW_PATH = `${SETTINGS_PRICELIST_LIST_PATH}/${OVERVIEW}/${pricelistId}`;

export const SETTINGS_LICENSE_TYPE_PATH = `${SETTINGS_PATH}/${LICENSE_TYPE}`;
export const SETTINGS_LICENSE_TYPE_LIST_PATH = `${SETTINGS_LICENSE_TYPE_PATH}/${LIST}`;
export const SETTINGS_LICENSE_TYPE_EDIT_PATH = `${SETTINGS_LICENSE_TYPE_LIST_PATH}/${EDIT}/${licenseTypeId}`;

export const SETTINGS_ALARM_PATH = `${SETTINGS_PATH}/${ALARM_TYPE}/${CLIENT}`;
export const SETTINGS_ALARM_LIST_PATH = `${SETTINGS_ALARM_PATH}/${LIST}`;
export const SETTINGS_ALARM_TPL_PATH = `${SETTINGS_PATH}/${ALARM_TYPE_TEMPLATE}`;
export const SETTINGS_ALARM_TPL_LIST_PATH = `${SETTINGS_ALARM_TPL_PATH}/${LIST}`;

export const SETTINGS_EVENTS_CLIENT_PATH = `${SETTINGS_PATH}/${EVENTS}/${CLIENT}`;
export const SETTINGS_EVENTS_CLIENT_LIST_PATH = `${SETTINGS_EVENTS_CLIENT_PATH}/${LIST}`;
export const SETTINGS_EVENT_EDIT_PATH = `${SETTINGS_EVENTS_CLIENT_LIST_PATH}/${EDIT}/${eventId}`;
export const SETTINGS_EVENT_OVERVIEW_PATH = `${SETTINGS_EVENTS_CLIENT_LIST_PATH}/${OVERVIEW}/${eventId}`;
export const SETTINGS_EVENT_CREATE_PATH = `${SETTINGS_EVENTS_CLIENT_LIST_PATH}/${CREATE}`;
export const SETTINGS_EVENT_TPL_PATH = `${SETTINGS_PATH}/${EVENT_TPL}`;
export const SETTINGS_EVENT_TPL_LIST_PATH = `${SETTINGS_EVENT_TPL_PATH}/${LIST}`;
export const SETTINGS_EVENT_TPL_EDIT_PATH = `${SETTINGS_EVENT_TPL_LIST_PATH}/${EDIT}/${eventTemplateId}`;
export const SETTINGS_EVENT_TPL_CREATE_PATH = `${SETTINGS_EVENT_TPL_LIST_PATH}/${CREATE}`;

export const SETTINGS_TASK_TEMPLATE_PATH = `${SETTINGS_PATH}/${TASK_TPL}`;
export const SETTINGS_TASK_TEMPLATE_LIST_PATH = `${SETTINGS_TASK_TEMPLATE_PATH}/${LIST}`;
export const SETTINGS_TASK_DETAILS_PATH = `${SETTINGS_TASK_TEMPLATE_LIST_PATH}/${DETAILS}/${taskTemplateId}`;
export const SETTINGS_TASK_CREATE_PATH = `${SETTINGS_TASK_TEMPLATE_LIST_PATH}/${CREATE}`;

export const SETTINGS_CURRENCY_AND_RATE_PATH = `${SETTINGS_PATH}/${CURRENCY_AND_RATE}`;
export const SETTINGS_CURRENCY_AND_RATE_LIST_PATH = `${SETTINGS_CURRENCY_AND_RATE_PATH}/${LIST}`;
export const SETTINGS_CURRENCY_AND_RATE_CREATE_PATH = `${SETTINGS_CURRENCY_AND_RATE_LIST_PATH}/${CREATE}`;
export const SETTINGS_CURRENCY_AND_RATE_OVERVIEW_PATH = `${SETTINGS_CURRENCY_AND_RATE_LIST_PATH}/${OVERVIEW}/${currencyId}`;

export const SETTINGS_TRANSLATIONS_PATH = `${SETTINGS_PATH}/${TRANSLATIONS}`;
export const SETTINGS_TRANSLATIONS_LIST_PATH = `${SETTINGS_TRANSLATIONS_PATH}/${LIST}`;
export const SETTINGS_TRANSLATIONS_CREATE_PATH = `${SETTINGS_TRANSLATIONS_LIST_PATH}/${CREATE}`;

export const SETTINGS_PRODUCT_PATH = `${SETTINGS_PATH}/${PRODUCT}`;
export const SETTINGS_PRODUCT_LIST_PATH = `${SETTINGS_PRODUCT_PATH}/${LIST}`;
export const SETTINGS_PRODUCT_CREATE_PATH = `${SETTINGS_PRODUCT_LIST_PATH}/${CREATE}`;
export const SETTINGS_PRODUCT_OVERVIEW_PATH = `${SETTINGS_PRODUCT_LIST_PATH}/${OVERVIEW}/${productId}`;

export const SETTINGS_MY_PROFILE_PATH = `${SETTINGS_PATH}/${MY_PROFILE}`;
export const SETTINGS_MY_PROFILE_DETAILS_PATH = `${SETTINGS_MY_PROFILE_PATH}/${DETAILS}`;
export const SETTINGS_MY_PROFILE_IMAGE_PATH = `${SETTINGS_MY_PROFILE_PATH}/${IMAGE}`;
export const SETTINGS_MY_PROFILE_SECURITY_PATH = `${SETTINGS_MY_PROFILE_PATH}/${SECURITY}`;
export const SETTINGS_MY_PROFILE_PERSONALIZATION_PATH = `${SETTINGS_MY_PROFILE_PATH}/${PERSONALIZATION}`;
export const SETTINGS_MY_PROFILE_MESSAGES_PANEL_PATH = `${SETTINGS_MY_PROFILE_PATH}/${MESSAGES}`;

export const SETTINGS_ALARM_CATEGORIES_PATH = `${SETTINGS_PATH}/${ALARM_CATEGORIES}`;
export const SETTINGS_ALARM_CATEGORIES_LIST_PATH = `${SETTINGS_ALARM_CATEGORIES_PATH}/${LIST}`;
export const SETTINGS_ALARM_CATEGORIES_CREATE_PATH = `${SETTINGS_ALARM_CATEGORIES_LIST_PATH}/${CREATE}`;
export const SETTINGS_ALARM_CATEGORIES_EDIT_PATH = `${SETTINGS_ALARM_CATEGORIES_LIST_PATH}/${EDIT}/${alarmCategoryId}`;
export const SETTINGS_ALARM_CATEGORY_OVERVIEW_PATH = `${SETTINGS_ALARM_CATEGORIES_LIST_PATH}/${OVERVIEW}/${alarmCategoryId}`;


export const SETTINGS_API_ACCESS_PATH = `${SETTINGS_PATH}/${API_ACCESS}`;
export const SETTINGS_RETRANSMISSION_PATH = `${SETTINGS_PATH}/${RETRANSMISSION}`;

//TODO
export const SETTINGS_ALARM_NOTIFICATIONS_PATH = `${SETTINGS_PATH}/${ALARM_NOTIFICATIONS}`;
export const SETTINGS_NOTIFICATIONS_EMAIL_ALARM_MESSAGES_CONFIGURATION_PATH = `${SETTINGS_ALARM_NOTIFICATIONS_PATH}/${ALARM_EMAIL_MESSAGES_CONFIGURATION}`;
export const SETTINGS_NOTIFICATIONS_EMAIL_REPORT_MESSAGES_CONFIGURATION_PATH = `${SETTINGS_ALARM_NOTIFICATIONS_PATH}/${REPORT_EMAIL_MESSAGES_CONFIGURATION}`;
export const SETTINGS_ALARM_NOTIFICATIONS_SMS_MESSAGES_CONFIGURATION_PATH = `${SETTINGS_ALARM_NOTIFICATIONS_PATH}/${ALARM_SMS_MESSAGES_CONFIGURATION}`;

export const SETTINGS_EMAIL_SMS_CONFIG_PATH = `${SETTINGS_PATH}/${EMAIL_SMS_CONFIG}`;
export const SETTINGS_EMAIL_SMS_CONFIG_EMAIL_SERVER_CONFIGURATION_PATH = `${SETTINGS_EMAIL_SMS_CONFIG_PATH}/${EMAIL_SERVER_CONFIGURATION}`;
export const SETTINGS_EMAIL_SMS_CONFIG_EMAIL_SENDING_CONFIGURATION_PATH = `${SETTINGS_EMAIL_SMS_CONFIG_PATH}/${EMAIL_SENDING_CONFIGURATION}`;
export const SETTINGS_EMAIL_SMS_CONFIG_SMS_CONFIGURATION_PATH = `${SETTINGS_EMAIL_SMS_CONFIG_PATH}/${SMS_CONFIGURATION}`;
//endregion


// region REPORTS
export const REPORTS_PATH = `/${REPORT}`;
export const REPORT_OVERVIEW = `${REPORTS_PATH}/${OVERVIEW}`;
export const REPORT_RECURRING_PATH = `${REPORT_OVERVIEW}/${REPORT_RECURRING}`;
export const REPORT_RECURRING_LIST_PATH = `${REPORT_RECURRING_PATH}/${LIST}`;
export const REPORT_RECURRING_LIST_EDIT_PATH = `${REPORT_RECURRING_LIST_PATH}/${EDIT}/${reportRecurringId}`;
export const REPORT_RECURRING_LIST_OVERVIEW_PATH = `${REPORT_RECURRING_LIST_PATH}/${OVERVIEW}/${reportRecurringId}`;
export const REPORT_TEMPLATE_PATH = `${REPORT_OVERVIEW}/${REPORT_TEMPLATE}`;
export const REPORT_TEMPLATE_LIST_PATH = `${REPORT_TEMPLATE_PATH}/${LIST}`;
export const REPORT_TEMPLATE_LIST_EDIT_PATH = `${REPORT_TEMPLATE_LIST_PATH}/${EDIT}/${reportTemplateId}`;
export const REPORT_GENERATED_PATH = `${REPORT_OVERVIEW}/${REPORT_GENERATED}`;
export const REPORT_GENERATED_LIST_PATH = `${REPORT_GENERATED_PATH}/${LIST}`;
export const ALARM_REPORT_PATH = `${REPORT_OVERVIEW}/${ALARMS}`;
export const ALARM_REPORT_LIST_PATH = `${ALARM_REPORT_PATH}/${LIST}`;
export const ALARM_REPORTS_GENERATE = `${ALARM_REPORT_LIST_PATH}/${GENERATE_REPORT}`;
export const PATROL_REPORTS_PATH = `${REPORT_OVERVIEW}/${PATROLS}`;
export const PATROL_REPORTS_LIST_PATH = `${PATROL_REPORTS_PATH}/${LIST}`;
export const PATROL_REPORTS_GENERATE = `${PATROL_REPORTS_LIST_PATH}/${GENERATE_REPORT}`;
export const TASK_REPORT_PATH = `${REPORT_OVERVIEW}/${TASKS}`;
export const TASK_REPORT_LIST_PATH = `${TASK_REPORT_PATH}/${LIST}`;
export const TASK_REPORTS_GENERATE = `${TASK_REPORT_LIST_PATH}/${GENERATE_REPORT}`;
export const EMPLOYEE_REPORT_PATH = `${REPORT_OVERVIEW}/${WORKERS}`;
export const EMPLOYEE_REPORT_LIST_PATH = `${EMPLOYEE_REPORT_PATH}/${LIST}`;
export const EMPLOYEE_REPORTS_GENERATE = `${EMPLOYEE_REPORT_LIST_PATH}/${GENERATE_REPORT}`;
export const BILLINGS_PATH = `${REPORTS_PATH}/${BILLINGS}`;
export const BILLINGS_OVERVIEW_PATH = `${BILLINGS_PATH}/${OVERVIEW}`;
export const EVENT_REPORT_PATH = `${REPORT_OVERVIEW}/${EVENTS}`;
export const EVENT_REPORT_LIST_PATH = `${EVENT_REPORT_PATH}/${LIST}`;
export const EVENT_REPORTS_GENERATE = `${EVENT_REPORT_LIST_PATH}/${GENERATE_REPORT}`;
export const LICENSE_HISTORY_REPORT_PATH = `${REPORT_OVERVIEW}/${LICENSE_HISTORY}`;
export const LICENSE_HISTORY_REPORTS_GENERATE = `${LICENSE_HISTORY_REPORT_PATH}/${GENERATE_REPORT}`;
export const CHURN_HISTORY_REPORT_PATH = `${REPORT_OVERVIEW}/${CHURN_HISTORY}`;
export const CHURN_HISTORY_REPORTS_GENERATE = `${CHURN_HISTORY_REPORT_PATH}/${GENERATE_REPORT}`;
export const REPORT_FILTER_PATH = `${REPORT_OVERVIEW}/${FILTER}/${LIST}`;
export const REPORT_FILTER_EDIT_PATH = `${REPORT_FILTER_PATH}/${EDIT}/${reportFilterId}`;
//endregion

//region MAIN SECTION: ALARMS
export const ALARMS_PATH = `/${ALARM}`;
export const ALARMS_LIST_PATH = `${ALARMS_PATH}/${LIST}`;
export const ALARMS_LIST_OVERVIEW_PATH = `${ALARMS_LIST_PATH}/${OVERVIEW}/${alarmId}`;
export const ALARMS_LIST_OVERVIEW_SEND_PATROL_CREATE_TASK_PATH = `${ALARMS_LIST_PATH}/${OVERVIEW}/${alarmId}/${TASK}/${CREATE}`;
//endregion


//region RELATIVES
export const TIMETABLE_EDIT_RELATIVE_PATH = `${TIMETABLE}/${EDIT}/${companyId}/${routeId}`;
export const DEVICE_RELATIVE_PATH = `${DEVICE}/${deviceId}`;
export const OBJECT_RELATIVE_PATH = (base: string = OBJECT) => `${base}/${companyId}/${siteId}`;
export const ROUTE_RELATIVE_PATH = (base: string = ROUTE) => `${base}/${siteId}/${routeId}`;
export const EMPLOYEE_RELATIVE_PATH = (base: string = EMPLOYEE) => `${base}/${employeeId}`;
export const CONTACT_EDIT_RELATIVE_PATH = `${CONTACT}/${EDIT}/${contactId}`;
export const CONTACT_OVERVIEW_RELATIVE_PATH = `${CONTACT}/${OVERVIEW}/${contactId}`;
export const TAG_RELATIVE_PATH = `${TAG}/${tagId}`;
export const TIMETABLE_CREATE_RELATIVE_PATH = `${TIMETABLE}/${CREATE}`;
export const TIMETABLE_CREATE_WITH_COMPANY_ID_PATH = `${companyId}/${TIMETABLE}/${CREATE}`;
export const CONTACT_CREATE_RELATIVE_PATH = `${CONTACT}/${CREATE}`;
export const CLIENT_ROLE_CREATE_RELATIVE_PATH = `${ROLE}/${CREATE}`;
export const CLIENT_ADDITIONAL_SERVICE_CREATE_RELATIVE_PATH = `${ADDITIONAL_SERVICES}/${CREATE}`;
export const CLIENT_ADDITIONAL_SERVICE_EDIT_RELATIVE_PATH = `${ADDITIONAL_SERVICES}/${EDIT}/${additionalServiceId}`;
export const PRICELIST_CREATE_RELATIVE_PATH = `${PRICELIST}/${CREATE}`;
export const USER_CREATE_RELATIVE_PATH = `${USER}/${CREATE}`;
export const LICENSE_CREATE_RELATIVE_PATH = `${LICENSE}/${CREATE}`;
export const ALARM_TYPE_EDIT_RELATIVE_PATH = `${EDIT}/${alarmTypeId}`
export const ALARM_TYPE_TEMPLATE_EDIT_RELATIVE_PATH = `${EDIT}/${alarmTypeTemplateId}`
//endregion

