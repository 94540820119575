import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, concatMap, map, mergeMap, switchMap, tap } from "rxjs/operators";
import { iif, of } from "rxjs";
import { AuthService } from "../services/auth.service";
import { Router } from "@angular/router";
import { AV2CurrentUser } from "src/app/commons/models/user";
import { Store } from "@ngrx/store";
import {
  blockContent,
  changeLanguage,
  delayAction,
  hideLoader,
  locationBack,
  navigate,
  setTheme,
  showLoader,
  showToast
} from "src/app/shared/modules/ui/store/actions/ui.actions";
import {
  acceptConditions,
  acceptConditionsFailed,
  acceptConditionsSuccess,
  changePassword,
  changePasswordFailed,
  changePasswordSuccess,
  checkResetPasswordUUID,
  checkResetPasswordUUIDFailed,
  checkResetPasswordUUIDSuccess,
  loadAllTimezones,
  loadAllTimezonesFailed,
  loadAllTimezonesSuccess, loadImpersonatedUser, loadImpersonatedUserFailed, loadImpersonatedUserSuccess,
  logout,
  logoutFailed,
  logoutSuccess,
  logoutSuccessAfterAuthDataChanged,
  navigateToAuthConditionsIfNecessary,
  refreshToken,
  refreshTokenSuccess,
  resetPassword,
  resetPasswordFailed,
  resetPasswordSuccess,
  resetPin,
  resetPinFailed,
  resetPinSuccess,
  sendResetPasswordEmail,
  sendResetPasswordEmailFailed,
  sendResetPasswordEmailSuccess,
  showAuthInfobar,
  signIn,
  signInFailed,
  signInSuccess
} from "../actions/auth.actions";
import { InfoDialogService } from "src/app/shared/info-dialog/info-dialog.service";
import { InfoDialogType } from "src/app/shared/info-dialog/models/info-dialog";
import { TranslateService } from "@ngx-translate/core";
import {
  AUTH_CONDITIONS_SIGN_IN_PATH,
  AUTH_ERROR_PASSWORD_PATH,
  AUTH_SIGN_IN_PATH,
  AUTH_SUCCESS_PASSWORD_PATH,
  AUTH_SUCCESS_SEND_PASSWORD_PATH,
  CLIENT_LIST_PATH,
  DASHBOARD,
  DASHBOARD_PATH
} from "../../../../commons/consts/navigation.const";
import { MessageService } from "primeng/api";

@Injectable()
export class AuthEffects {

  constructor(
    private actions$: Actions,
    private store$: Store,
    private authService: AuthService,
    private router: Router,
    private infoDialogService: InfoDialogService,
    private translateService: TranslateService,
    private messageService: MessageService
  ) {
  }

  signIn$ = createEffect(() => this.actions$.pipe(
    ofType(signIn),
    tap(() => {
      this.store$.dispatch(showLoader());
      this.store$.dispatch(blockContent({ block: true }));
    }),
    switchMap(action => this.authService.signIn(action.username, action.password).pipe(
      concatMap((response: AV2CurrentUser) => [
        signInSuccess({ user: response.appUser, accessToken: response.accessToken, role: response.role }),
        delayAction({
          delay: 10, action: navigateToAuthConditionsIfNecessary({
            acceptedTerms: !!response.appUser.acceptedTerms,
            returnUrl: action.returnUrl
          })
        })
      ]
      ),
      catchError(() => of(signInFailed()))
    ))
  ));

  signInSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(signInSuccess),
    concatMap(action => [
      setTheme({ theme: action.user.userSettingsDto.theme }),
      changeLanguage({ locale: action.user.userSettingsDto.language }),
      delayAction({ delay: 1750, action: blockContent({ block: false }) })
    ])
  ));

  navigateToAuthConditionsIfNecessary$ = createEffect(() => this.actions$.pipe(
    ofType(navigateToAuthConditionsIfNecessary),
    switchMap(action =>
      iif(() => action.acceptedTerms,
        of(navigate({ path: action.returnUrl, hideLoaderAfterNavigate: true })),
        of(navigate({ path: AUTH_CONDITIONS_SIGN_IN_PATH, hideLoaderAfterNavigate: true })))
    )
  ));

  signInFailed$ = createEffect(() => this.actions$.pipe(
    ofType(signInFailed),
    mergeMap(() => [
      hideLoader(),
      showAuthInfobar(),
      blockContent({ block: false })
    ])
  ));

  logout$ = createEffect(() => this.actions$.pipe(
    ofType(logout),
    switchMap((action) => this.authService.logout().pipe(
      map(() => action.authDataChanged ? logoutSuccessAfterAuthDataChanged() : logoutSuccess()),
      catchError(() => of(logoutFailed()))
    ))
  ));

  logoutSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(logoutSuccess),
    tap(() => {
      this.router.navigate([AUTH_SIGN_IN_PATH]);
      this.messageService.clear("br");
      this.messageService.clear("default");
    })
  ), { dispatch: false });

  logoutSuccessAfterAuthDataChanged$ = createEffect(() => this.actions$.pipe(
    ofType(logoutSuccessAfterAuthDataChanged),
    tap(() => {
      this.router.navigate([AUTH_SIGN_IN_PATH]);
      this.store$.dispatch(showToast({
        severity: "success",
        message: "my-profile.security.user-auth-data-change.logout-info"
      }));
    })
  ), { dispatch: false });

  logoutFailed$ = createEffect(() => this.actions$.pipe(
    ofType(logoutFailed),
    tap(() => {
      this.authService.logoutOnFailed();
      this.messageService.clear("br");
      this.messageService.clear("default");
    })
  ), { dispatch: false });

  refreshToken$ = createEffect(() => this.actions$.pipe(
    ofType(refreshToken),
    switchMap(action => this.authService.refreshToken(action.refreshToken).pipe(
      map(response => refreshTokenSuccess({ accessToken: response })),
      catchError(() => of(logoutFailed()))
    ))
  ));

  changePassword$ = createEffect(() => this.actions$.pipe(
    ofType(changePassword),
    tap(() => this.store$.dispatch(showLoader())),
    switchMap(action => this.authService.changePassword(action.oldPassword, action.newPassword, action.newPasswordReply).pipe(
      mergeMap(() => [
        changePasswordSuccess()]
      ),
      catchError(() => of(changePasswordFailed()))
    ))
  ));

  changePasswordSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(changePasswordSuccess),
    switchMap(() => this.translateService.get("ui.common.sign-in.password-change.success").pipe(
      map(trans => {
        this.infoDialogService.showInfoDialog(trans, InfoDialogType.SUCCESS, "padlock.svg");
      })
    )),
    map(() => hideLoader())
  ));

  changePasswordFailed$ = createEffect(() => this.actions$.pipe(
    ofType(changePasswordFailed),
    switchMap(() => this.translateService.get("ui.common.sign-in.password-change.failed").pipe(
      map(trans => {
        this.infoDialogService.showInfoDialog(trans, InfoDialogType.ERROR, "padlock.svg");
      })
    )),
    map(() => hideLoader())
  ));

  resetPassword$ = createEffect(() => this.actions$.pipe(
    ofType(resetPassword),
    tap(() => this.store$.dispatch(showLoader())),
    switchMap(action => this.authService.resetPassword(action.newPassword, action.newPasswordReply, action.uuid).pipe(
      map(() => resetPasswordSuccess()),
      catchError(() => of(resetPasswordFailed()))
    ))
  ));

  resetPasswordSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(resetPasswordSuccess),
    tap(() => this.router.navigate([AUTH_SUCCESS_PASSWORD_PATH])),
    map(() => hideLoader())
  ));

  resetPasswordFailed$ = createEffect(() => this.actions$.pipe(
    ofType(resetPasswordFailed),
    tap(() => this.router.navigate([AUTH_ERROR_PASSWORD_PATH])),
    map(() => hideLoader())
  ));

  sendResetPasswordEmail$ = createEffect(() => this.actions$.pipe(
    ofType(sendResetPasswordEmail),
    tap(() => this.store$.dispatch(showLoader())),
    switchMap(action => this.authService.sendResetPasswordEmail(action.email).pipe(
      map(() => sendResetPasswordEmailSuccess()),
      catchError(() => of(sendResetPasswordEmailFailed()))
    ))
  ));

  sendResetPasswordEmailSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(sendResetPasswordEmailSuccess),
    tap(() => this.router.navigate([AUTH_SUCCESS_SEND_PASSWORD_PATH])),
    map(() => hideLoader())
  ));

  sendResetPasswordEmailFailed$ = createEffect(() => this.actions$.pipe(
    ofType(sendResetPasswordEmailFailed),
    tap(() => this.router.navigate([AUTH_ERROR_PASSWORD_PATH])),
    map(() => hideLoader())
  ));

  checkResetPasswordUUID$ = createEffect(() => this.actions$.pipe(
    ofType(checkResetPasswordUUID),
    tap(() => this.store$.dispatch(showLoader())),
    switchMap(action => this.authService.checkResetPasswordUUID(action.uuid).pipe(
      mergeMap((response: boolean) => [
        checkResetPasswordUUIDSuccess({ existUuid: response }),
        hideLoader()
      ]),
      catchError(() => of(checkResetPasswordUUIDFailed()))
    ))
  ));

  checkResetPasswordUUIDFailed$ = createEffect(() => this.actions$.pipe(
    ofType(checkResetPasswordUUIDFailed),
    tap(() => this.router.navigate([AUTH_ERROR_PASSWORD_PATH])),
    map(() => hideLoader())
  ));

  acceptConditions$ = createEffect(() => this.actions$.pipe(
    ofType(acceptConditions),
    tap(() => this.store$.dispatch(showLoader())),
    switchMap(action => this.authService.acceptConditions().pipe(
      map(() => acceptConditionsSuccess()),
      catchError(() => of(acceptConditionsFailed()))
    ))
  ));

  acceptConditionsSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(acceptConditionsSuccess),
    tap(() => this.router.navigate([DASHBOARD])),
    map(() => hideLoader())
  ));

  acceptConditionsFailed$ = createEffect(() => this.actions$.pipe(
    ofType(acceptConditionsFailed),
    mergeMap(() => [
      logout({}), hideLoader()
    ])
  ));

  loadAllTimezones$ = createEffect(() => this.actions$.pipe(
    ofType(loadAllTimezones),
    switchMap((action) => this.authService.getAllTimezones().pipe(
      mergeMap((timezones) => [
        loadAllTimezonesSuccess({ timezones })
      ]),
      catchError(() => of(loadAllTimezonesFailed()))
    ))
  ));

  loadImpersonatedUser$ = createEffect(() => this.actions$.pipe(
    ofType(loadImpersonatedUser),
    tap(impersonatedUser => {
      if (this.router.url !== DASHBOARD_PATH && this.router.url !== CLIENT_LIST_PATH) {
        this.router.navigate([DASHBOARD_PATH]);
      }
      this.store$.dispatch(showLoader());
      this.authService.setNewImpersonatedUserDataToLocalStorage(
        impersonatedUser.impersonatedUserTechnicalName,
        impersonatedUser.impersonatedUserName,
        impersonatedUser.impersonatedUserClientId
      )
    }),
      mergeMap(action => [
        loadImpersonatedUserSuccess({
          impersonatedUserTechnicalName: action.impersonatedUserTechnicalName,
          impersonatedUserName: action.impersonatedUserName,
          impersonatedUserClientId: action.impersonatedUserClientId
        }),
        hideLoader()
      ]),
  ));

  resetPin$ = createEffect(() => this.actions$.pipe(
    ofType(resetPin),
    switchMap(action => this.authService.resetPin(action.newPin).pipe(
      map(() => resetPinSuccess()),
      catchError(() => of(resetPinFailed()))
    ))
  ));

  resetPinSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(resetPinSuccess),
    mergeMap(() => [
      showToast({ severity: "success", message: "auth.employee.reset-pin.success" }),
      locationBack()
    ])
  ));

}
