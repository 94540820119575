<form class="form-container" *ngIf="acceptanceFormGroup" [formGroup]="acceptanceFormGroup">
    <h2 class="form-title" translate>auth.conditions.acceptance-of-conditions</h2>
    <div class="conditions-body">
        <p-checkbox formControlName="personalData" [binary]="true" class="personal-data-checkbox"></p-checkbox>
        <div class="personal-data-head av2-text-bold-1" translate>auth.conditions.data-processing</div>
        <div class="personal-data-body av2-text-small-1" *ngIf="isVisiblePersonalBody" translate>auth.conditions.data-processing-acceptance</div>
        <div class="personal-data-more-container">
          <a class="av2-link-button personal-data-more" (click)="changeVisibilityPersonal()" translate>auth.conditions.personal-data-more</a>
        </div>
        <p-checkbox formControlName="rodo" [binary]="true" class="rodo-checkbox"></p-checkbox>
        <div class="rodo-head av2-text-bold-1" translate>auth.conditions.data_protection_regulation</div>
        <div class="rodo-more-container">
          <a class="av2-link-button rodo-more" [href]="dataProtectionHref" translate>auth.conditions.data_protection_regulation.link</a>
        </div>
    </div>
    <button pButton pRipple type="submit" label="Accept" class="accept-button" (click)="accept()"
        [disabled]="acceptanceFormGroup.controls['personalData'].value === false || acceptanceFormGroup.controls['rodo'].value === false">
    </button>
    <div class="additional-field">
      <a class="av2-link-button forgot-password" (click)="logout()" translate>auth.conditions.log-out</a>
    </div>
</form>
