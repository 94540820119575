import {createAction, props} from '@ngrx/store';
import {AV2Translation} from '../model/translations.model';

const actionHeader = "[Translations]"

export const showTranslationsTableLoader = createAction(
  `${actionHeader} show table loader`,
);

export const hideTranslationsTableLoader = createAction(
  `${actionHeader} hide table loader`,
);

export const loadAllTranslations = createAction(
  `${actionHeader} load all translations`,
);

export const loadAllTranslationsSuccess = createAction(
  `${actionHeader} load all translations success`,
  props<{ translationsList: AV2Translation[]; }>()
);

export const loadAllTranslationsFailed = createAction(
  `${actionHeader} load all translations failed`,
);

export const loadTranslationByLocale = createAction(
  `${actionHeader} load translation by locale`,
  props<{ locale: string; }>()
);

export const loadTranslationByLocaleSuccess = createAction(
  `${actionHeader} load translation by locale success`,
  props<{ translation: AV2Translation; }>()
);
export const loadTranslationByLocaleFailed = createAction(
  `${actionHeader} load translation by locale failed`,
);

export const deleteTranslation = createAction(
  `${actionHeader} delete translation`,
  props<{ locale: string; }>()
);

export const deleteTranslationSuccess = createAction(
  `${actionHeader} delete translation success`,
  props<{ locale: string; }>()
);

export const deleteTranslationFailed = createAction(
  `${actionHeader} delete translation failed`,
);

export const loadAllAvailableTranslations = createAction(
  `${actionHeader} load available languages`,
);

export const loadAllAvailableTranslationsSuccess = createAction(
  `${actionHeader} load available languages success`,
  props<{ lokaliseList: AV2Translation[]; }>()
);

export const loadAllAvailableTranslationsFailed = createAction(
  `${actionHeader} load available languages failed`,
);

export const updateTranslation = createAction(
  `${actionHeader} update translation`,
  props<{ locale: string; }>()
);

export const updateTranslationSuccess = createAction(
  `${actionHeader} update translation success`,
  props<{ locale: string; }>()
);

export const updateTranslationFailed = createAction(
  `${actionHeader} update translation failed`
);

export const createTranslation = createAction(
  `${actionHeader} create translation`,
  props<{ locale: string; }>()
);

export const createTranslationSuccess = createAction(
  `${actionHeader} create translation success`,
  props<{ locale: string; }>()
);

export const createTranslationFailed = createAction(
  `${actionHeader} create translation failed`,
);

