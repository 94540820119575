import { Action, createReducer, on } from "@ngrx/store";
import { INITIAL_UI_STATE } from "../consts/initial-store.const";
import { UiState } from "../models/state.model";
import * as CoreActions from "../actions/ui.actions";

export function uiReducer(state: UiState, action: Action): UiState {
  return reducer(state, action);
}

export const reducer = createReducer(
  INITIAL_UI_STATE,
  on(CoreActions.setIsMobile, (state, action) => ({
    ...state,
    isMobile: action.isMobile
  })),
  on(CoreActions.setIsTablet, (state, action) => ({
    ...state,
    isTablet: action.isTablet
  })),
  on(CoreActions.showLoader, (state, action) => ({
    ...state,
    loaderVisibility: true
  })),
  on(CoreActions.hideLoader, (state, action) => ({
    ...state,
    loaderVisibility: false
  })),
  on(CoreActions.blockContent, (state, action) => ({
    ...state,
    blockContent: action.block
  })),
);
