import { Injectable } from '@angular/core';
import {Observable, Subject, Subscription} from 'rxjs';
import {
  ConfirmDialogSubject,
  ConfirmRefreshSessionDialogSubject,
  ConfirmWithInputDialogSubject,
  InfoDialogSubject,
  InfoDialogType
} from './models/info-dialog';
import {CountdownData} from "../helpers/dateUtils";

@Injectable({
  providedIn: 'root'
})
export class InfoDialogService {

  private infoDialog$: Subject<InfoDialogSubject> = new Subject<InfoDialogSubject>();
  infoDialog: Observable<InfoDialogSubject>;
  private confirmation$: Subject<ConfirmDialogSubject> = new Subject<ConfirmDialogSubject>();
  confirmation: Observable<ConfirmDialogSubject>;
  private confirmationRefreshSession$: Subject<ConfirmRefreshSessionDialogSubject> = new Subject<ConfirmRefreshSessionDialogSubject>();
  confirmationRefreshSession: Observable<ConfirmRefreshSessionDialogSubject>;
  private confirmationWithInput$: Subject<ConfirmWithInputDialogSubject> = new Subject<ConfirmWithInputDialogSubject>();
  confirmationWithInput: Observable<ConfirmWithInputDialogSubject>;

  constructor() {
    this.infoDialog = this.infoDialog$.asObservable();
    this.confirmation = this.confirmation$.asObservable();
    this.confirmationRefreshSession = this.confirmationRefreshSession$.asObservable();
    this.confirmationWithInput = this.confirmationWithInput$.asObservable();
  }

  showInfoDialog(message: string, type: InfoDialogType, icon?: string) {
    this.infoDialog$.next({ message, icon, type });
  }

  showConfirmation(message: string, icon: string, accept: () => void, confirmLabel?: string, rejectLabel?: string,) {
    this.confirmation$.next({ message, iconName: icon, confirmLabel, rejectLabel: !!rejectLabel ? rejectLabel : undefined, accept });
  }

  showRefreshSessionConfirmation(title: string, message: string, counter: Observable<CountdownData>, icon: string, accept: () => void, confirmLabel?: string, rejectLabel?: string,) {
    this.confirmationRefreshSession$.next({ title, counter, message, iconName: icon, confirmLabel, rejectLabel: !!rejectLabel ? rejectLabel : undefined, accept });
  }

  showConfirmationWithInput(message: string, icon: string, accept: (inputValue: string) => void, inputLabel: string, inputePlaceholder: string, inputMaxLength: number, confirmLabel?: string, rejectLabel?: string, requiredInput?: boolean) {
    this.confirmationWithInput$.next({ message, iconName: icon, confirmLabel, rejectLabel, inputLabel, inputMaxLength, inputePlaceholder,  accept, requiredInput });
  }
}
