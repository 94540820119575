import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { sendResetPasswordEmail } from '../../store/actions/auth.actions';
import { AV2AuthState } from '../../store/models/state.model';
import { NAVI } from 'src/app/commons/consts';
import { showToast } from 'src/app/shared/modules/ui/store/actions/ui.actions';

@Component({
  selector: 'app-email-password',
  templateUrl: './email-password.component.html',
  styleUrls: ['./email-password.component.scss']
})
export class EmailPasswordComponent implements OnInit {

  NAVI = NAVI;
  mailPasswordFormGroup!: UntypedFormGroup;

  constructor(private store$: Store<AV2AuthState>) { }

  ngOnInit(): void {
    this.initForm();
  }

  private initForm() {
    this.mailPasswordFormGroup = new UntypedFormGroup({
      email: new UntypedFormControl('', Validators.required)
    })
  }

  sendEmail() {
    if (this.mailPasswordFormGroup && this.mailPasswordFormGroup.valid) {
      const email = this.mailPasswordFormGroup.get('email')?.value;
      this.store$.dispatch(
        sendResetPasswordEmail({ email: email })
      );
    } else {
      this.store$.dispatch(showToast({ severity: 'error', message: 'ui.form.validation.failed' }));
    }
  }
}
