import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorValidationComponent } from './error-validation.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [ErrorValidationComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule
  ],
  exports: [ErrorValidationComponent]
})
export class ErrorValidationModule { }
