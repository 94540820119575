import { AV2AuthState } from "../models/state.model";
import {
  AV2_USER_DEFAULT_STATE,
} from "../../../../commons/models/user-default-state";


export const INITIAL_AUTH_STATE: AV2AuthState = {
  appUser: { ...AV2_USER_DEFAULT_STATE },
  accessToken: {
    token: "",
    refreshToken: "",
    expiresIn: 0,
    refreshExpiresIn: 0
  },
  uuid: true,
  isInfobarVisible: false,
  role: {
    name: '',
    isAdmin: false,
    permissions: []
  },
  timezones: [],
};
