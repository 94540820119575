import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { AV2ListItem } from "src/app/shared/models/av2-list-item.model";

@Component({
    selector: 'app-dropdown-selector',
    templateUrl: './dropdown-selector.component.html',
    styleUrls: ['./dropdown-selector.component.scss']
})
export class DropdownSelectorComponent implements OnInit, OnChanges {
    
    @Input() options?: AV2ListItem[] = [];
    @Input() placeholder: string = "ui.dropdown-selector.search";
    @Input() noOptionsLabel: string = "ui.dropdown-selector.no-options";
    @Input() disabled: boolean = false;
    @Output() onSelect = new EventEmitter<AV2ListItem>();

    filteredOptions: AV2ListItem[] = [];
    isShow: boolean = false;

    constructor() {}

    ngOnInit(): void {
        this.filteredOptions = this.options ?? [];
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes?.options?.currentValue) {
            this.filteredOptions = this.options ?? [];
        }
    }

    search(event: any) {
        if(!this.options?.length) return;

        this.filteredOptions = event?.target?.value ? 
            [...this.options.filter(o => o.label.toLowerCase().includes(event.target.value.toLowerCase()))] : [...this.options];
    }

    select(item: AV2ListItem) {
        this.isShow = false;
        this.onSelect.emit(item);
    }
}