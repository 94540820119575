<p-dialog [visible]="dialogVisibility" (visibleChange)="closeDialog()" [modal]="true" [draggable]="false"
  [resizable]="false" class="av2-info-dialog">
  <div class="av2-info-dialog-container">
    <img [src]="'../../assets/icons/' + icon" class="av2-info-dialog-icon">
    <ng-container *ngIf="type === InfoDialogType.CONFIRM_REFRESH_SESSION && !!refreshSessionCountdown && !!countdownTitle">
      <span class="av2-info-dialog-message message">
        {{countdownTitle | translate}}
      </span>
        <span class="av2-info-dialog-refresh-session-counter message">
        {{refreshSessionCountdown.minutes}}m:{{refreshSessionCountdown.seconds}}s
      </span>
    </ng-container>
    <span class="av2-info-dialog-message message">
      {{message | translate}}
    </span>
  </div>
  <div class="av2-info-dialog-footer" *ngIf="type === InfoDialogType.CONFIRM_REFRESH_SESSION">
    <button *ngIf="confirmLabel" pButton pRipple type="button" [label]="confirmLabel | translate" (click)="confirm()"
            class="p-button-primary">
    </button>
    <button *ngIf="rejectLabel" pButton pRipple type="button" [label]="rejectLabel | translate" (click)="closeDialog()"
            class="p-button-secondary">
    </button>
  </div>
  <div class="av2-info-dialog-footer" *ngIf="type === InfoDialogType.CONFIRM">
    <button *ngIf="confirmLabel" pButton pRipple type="button" [label]="confirmLabel | translate" (click)="confirm()"
      class="p-button-primary">
    </button>
    <button *ngIf="rejectLabel" pButton pRipple type="button" [label]="rejectLabel | translate" (click)="closeDialog()"
      class="p-button-secondary">
    </button>
  </div>
  <div *ngIf="type === InfoDialogType.CONFIRM_WITH_INPUT">
    <div class="input-container">
      <div class="av2-form-field input">
        <label for="input">
          {{inputLabel! | translate}}
          <span class="av2-text-small-1" *ngIf="!requiredInput" translate>ui.common.optional</span>
        </label>
        <textarea pInputTextarea id="input" type="text" [rows]="3" [autoResize]="true"
          [placeholder]="inputPlaceholder! | translate" [(ngModel)]="inputValue"
          [required]="requiredInput ?? false" [maxlength]="inputMaxLength!">
        </textarea>
        <p *ngIf="requiredInput && !inputValue" class="input-error-info p-invalid" translate>ui.common.validation.error.required</p>
      </div>
      <div class="av2-info-dialog-footer">
        <button *ngIf="confirmLabel" pButton pRipple type="button" [label]="confirmLabel | translate"
          (click)="confirmWithInput()" class="p-button-primary">
        </button>
        <ng-container *ngIf="rejectLabel !== undefined"><button pButton pRipple type="button" [label]="rejectLabel | translate"
          (click)="closeDialog()" class="p-button-secondary">
        </button></ng-container>
      </div>
    </div>
  </div>
</p-dialog>
