import { Pipe, PipeTransform } from '@angular/core';
import dayjs from "dayjs";
import {CustomDatePipe} from "../../shared/pipes/customDate.pipe";

@Pipe({
  name: 'dayNameFromDate'
})
export class DayNameFromDatePipe implements PipeTransform {

  constructor(private datePipe: CustomDatePipe) {
  }

  transform(date: string, ...args: unknown[]): string {
    return `ui.common.day.${dayjs(date).format('ddd').toLowerCase()}`;
  }

}
