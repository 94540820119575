import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { map, tap } from "rxjs/operators";
import { LocalStorageItem } from "../../../../commons/enums/local-storage";
import { ChangePasswordRequestData, LoginRequestData, ResetPasswordRequestData } from "../models/auth-service";
import { AV2AccessToken, AV2CurrentUser, AV2UserRole, AV2User, AV2ResetPin } from "../../../../commons/models/user";
import {
  API_USER_SERVICE,
  USER_SERVICE_ACCEPT_CONDITIONS,
  USER_SERVICE_CHANGE_PASSWORD_API,
  USER_SERVICE_CHECK_UUID_RESET_PASSWORD_API,
  USER_SERVICE_LOGIN_API,
  USER_SERVICE_LOGOUT_API,
  USER_SERVICE_REFRESHTOKEN_API,
  USER_SERVICE_RESET_PASSWORD_API, USER_SERVICE_RESET_PIN_API,
  USER_SERVICE_SENT_EMAIL_RESET_PASSWORD_API, USER_SERVICE_TIMEZONE_API
} from "../../../../commons/consts/api.const";
import { AV2AuthState } from "../models/state.model";
import { Store } from "@ngrx/store";
import { clearAuthData, clearImpersonatedUser, loadImpersonatedUserSuccess, signInSuccess } from "../actions/auth.actions";
import { AUTH_SIGN_IN_PATH } from "../../../../commons/consts/navigation.const";
import { AV2UserSettingsDto } from "../../../../commons/models/user-settings";
import { Av2TimezonesModel } from "../models/av2-timezones.model";
import { loadClientTypeByClientIdSuccess } from "src/app/modules/client/store/actions/client.actions";
import { AV2ClientType } from "src/app/modules/client/store/models/client.model";


@Injectable({
  providedIn: "root"
})
export class AuthService {

  authDocsLanguages = ["en", "pl"];

  constructor(
    private http: HttpClient,
    private router: Router,
    private store$: Store<AV2AuthState>
  ) {
    const appUser = this.getCurrentUserFromLocalStorage();
    const accessToken = this.getAccessTokenFromLocalStorage();
    const impersonatedUserTechnicalName = this.getImpersonatedUserTechnicalNameFromLocalStorage();
    const impersonatedUserName = this.getImpersonatedUserNameFromLocalStorage();
    const impersonatedUserClientId = this.getImpersonatedUserClientIdFromLocalStorage();
    const role = this.getRoleFromLocalStorage();
    if (appUser && accessToken) {
      queueMicrotask(() => {
        this.store$.dispatch(signInSuccess({ user: appUser, accessToken, role }));
      });
      if (impersonatedUserTechnicalName && impersonatedUserName && impersonatedUserClientId) {
        queueMicrotask(() => {
          this.store$.dispatch(loadClientTypeByClientIdSuccess({ clientType: AV2ClientType.ADMIN }))
          this.store$.dispatch(loadImpersonatedUserSuccess(
            { impersonatedUserTechnicalName, impersonatedUserName, impersonatedUserClientId }
          ));
        });
      }
    }
  }

  signIn(username: string, password: string): Observable<AV2CurrentUser> {
    const loginRequestData: LoginRequestData = { username, password };
    return this.http.post<AV2CurrentUser>(USER_SERVICE_LOGIN_API, loginRequestData)
      .pipe(map(currentUser => {
        this.clearImpersonatedUserDataFromLocalStorage();
        this.setUserToLocalStorage(currentUser.appUser);
        this.setNewTokenToLocalStorage(currentUser.accessToken);
        this.setRoleToLocalStorage(currentUser.role);
        return currentUser;
      }));
  }

  changePassword(oldPassword: string, newPassword: string, newPasswordReply: string) {
    const changePasswordRequestData: ChangePasswordRequestData = { oldPassword, newPassword, newPasswordReply };
    return this.http.post(USER_SERVICE_CHANGE_PASSWORD_API, changePasswordRequestData);
  }

  resetPassword(newPassword: string, newPasswordReply: string, uuid: string) {
    const resetPasswordRequestData: ResetPasswordRequestData = { newPassword, newPasswordReply };
    return this.http.put(`${USER_SERVICE_RESET_PASSWORD_API}/${uuid}`, resetPasswordRequestData, { responseType: "text" });
  }

  sendResetPasswordEmail(email: string) {
    return this.http.put(`${USER_SERVICE_SENT_EMAIL_RESET_PASSWORD_API}/${email}`, "", { responseType: "text" });
  }

  checkResetPasswordUUID(uuid: string): Observable<boolean> {
    return this.http.get<boolean>(`${USER_SERVICE_CHECK_UUID_RESET_PASSWORD_API}/${uuid}`);
  }

  logout() {
    return this.http.post(USER_SERVICE_LOGOUT_API, "")
      .pipe(map(() => {
        this.clearUserDataFromLocalStorage();
      }));
  }

  refreshToken(refreshToken: string): Observable<AV2AccessToken> {
    return this.http.post<AV2AccessToken>(USER_SERVICE_REFRESHTOKEN_API, refreshToken)
      .pipe(tap(at => {
        if (at) {
          this.setNewTokenToLocalStorage(at);
        }
      }));
  }

  logoutOn401() {
    this.clearUserDataFromLocalStorage();
  }

  logoutOnFailed() {
    const returnUrl = `${window.location.pathname}`;
    this.router.navigate([AUTH_SIGN_IN_PATH], { queryParams: { returnUrl } });
    this.clearUserDataFromLocalStorage();
  }

  setNewImpersonatedUserDataToLocalStorage(
    impersonatedUserTechnicalName: string,
    impersonatedUserName: string,
    impersonatedUserClientId: number
  ) {
    localStorage.removeItem(LocalStorageItem.IMPERSONATED_USER_TECHNICAL_NAME);
    localStorage.removeItem(LocalStorageItem.IMPERSONATED_USER_NAME);
    localStorage.removeItem(LocalStorageItem.IMPERSONATED_USER_CLIENT_ID);
    localStorage.setItem(LocalStorageItem.IMPERSONATED_USER_TECHNICAL_NAME, JSON.stringify(impersonatedUserTechnicalName));
    localStorage.setItem(LocalStorageItem.IMPERSONATED_USER_NAME, JSON.stringify(impersonatedUserName));
    localStorage.setItem(LocalStorageItem.IMPERSONATED_USER_CLIENT_ID, JSON.stringify(impersonatedUserClientId));
  }

  private clearUserDataFromLocalStorage() {
    this.store$.dispatch(clearAuthData());
    this.store$.dispatch(clearImpersonatedUser());
    this.clearImpersonatedUserDataFromLocalStorage();
    localStorage.removeItem(LocalStorageItem.APP_USER);
    localStorage.removeItem(LocalStorageItem.ACCESS_TOKEN);
    localStorage.removeItem(LocalStorageItem.ROLE);
  }

  private setUserToLocalStorage(appUser: AV2User) {
    localStorage.setItem(LocalStorageItem.APP_USER, JSON.stringify(appUser));
  }

  private setNewTokenToLocalStorage(accessToken: AV2AccessToken) {
    localStorage.removeItem(LocalStorageItem.ACCESS_TOKEN);
    localStorage.setItem(LocalStorageItem.ACCESS_TOKEN, JSON.stringify(accessToken));
  }

  public clearImpersonatedUserDataFromLocalStorage() {
    localStorage.removeItem(LocalStorageItem.IMPERSONATED_USER_TECHNICAL_NAME);
    localStorage.removeItem(LocalStorageItem.IMPERSONATED_USER_NAME);
    localStorage.removeItem(LocalStorageItem.IMPERSONATED_USER_CLIENT_ID);
  }

  private setRoleToLocalStorage(role: AV2UserRole) {
    localStorage.setItem(LocalStorageItem.ROLE, JSON.stringify(role));
  }

  private getImpersonatedUserTechnicalNameFromLocalStorage(): string | null {
    const name = localStorage.getItem(LocalStorageItem.IMPERSONATED_USER_TECHNICAL_NAME);
    return name ? JSON.parse(name) : null;
  }

  private getImpersonatedUserNameFromLocalStorage(): string | null {
    const name = localStorage.getItem(LocalStorageItem.IMPERSONATED_USER_NAME);
    return name ? JSON.parse(name) : null;
  }

  private getImpersonatedUserClientIdFromLocalStorage(): number | null {
    const id = localStorage.getItem(LocalStorageItem.IMPERSONATED_USER_CLIENT_ID);
    return id ? +JSON.parse(id) : null;
  }

  private getAccessTokenFromLocalStorage(): AV2AccessToken | null {
    const accessToken = localStorage.getItem(LocalStorageItem.ACCESS_TOKEN);
    return accessToken ? JSON.parse(accessToken) : null;
  }

  public getCurrentUserFromLocalStorage(): AV2User | null {
    const currentUser = localStorage.getItem(LocalStorageItem.APP_USER);
    return currentUser ? JSON.parse(currentUser) : null;
  }

  private getRoleFromLocalStorage(): AV2UserRole {
    const role = localStorage.getItem(LocalStorageItem.ROLE);
    return role ? JSON.parse(role) : null;
  }

  public setUserSettingsToLocalStorage(settings: AV2UserSettingsDto) {
    let currentUser: AV2User | null = this.getCurrentUserFromLocalStorage();
    if (!!currentUser) {
      currentUser = {
        ...currentUser,
        userSettingsDto: {
          ...currentUser?.userSettingsDto,
          ...settings
        }
      };
      this.setUserToLocalStorage(currentUser);
    }
  }

  public setUserImageToLocalStorage(image: string) {
    let currentUser: AV2User | null = this.getCurrentUserFromLocalStorage();
    if (!!currentUser) {
      currentUser = {
        ...currentUser,
        photo: image
      };
      this.setUserToLocalStorage(currentUser);
    }
  }

  acceptConditions() {
    return this.http.put(`${USER_SERVICE_ACCEPT_CONDITIONS}`, { responseType: "text" });
  }

  getAllTimezones(): Observable<Av2TimezonesModel[]> {
    return this.http.get<Av2TimezonesModel[]>(USER_SERVICE_TIMEZONE_API);
  }

  resetPin(newPin: AV2ResetPin) {
    return this.http.put(`${USER_SERVICE_RESET_PIN_API}`, newPin, { responseType: "text" });
  }

  public getAuthDocsLanguage(updateStorage = false, selectedLanguage?: string): string {
    const defaultLang = "en";
    let authLang = localStorage.getItem("authLang") || defaultLang;
    if (selectedLanguage && this.authDocsLanguages.includes(selectedLanguage)) {
      authLang = selectedLanguage;
    }
    if (updateStorage) {
      localStorage.setItem("authLang", authLang);
    }
    return authLang;
  }

}
