import { Pipe, PipeTransform } from "@angular/core";
import { ILocalTime, LocalTime } from "../classes/LocalTime.class";
import { CustomDatePipe } from "./customDate.pipe";

@Pipe({
  name: "localTime"
})
export class LocalTimePipe implements PipeTransform {

  constructor(public customDatePipe: CustomDatePipe) {
  }

  transform(value: string | ILocalTime, withSeconds = false): string | null {
    if (!value) return null;
    return new LocalTime(value).toVisualString(this.customDatePipe.h12, withSeconds);
  }


}
