<form *ngIf="signInFormGroup" [formGroup]="signInFormGroup" (submit)="signIn()" class="form-container">
  <h2 class="form-title" translate>auth.signin.signin</h2>
  <div class="av2-info-bar invalid-sign-in-info-bar" *ngIf="infobarVisibility$ | async">
    <i class="far fa-exclamation-triangle"></i>
    <p translate>auth.signin.wrong.password</p>
  </div>
  <div class="sing-in-input">
    <label for="username" translate>auth.signin.username</label>
    <input id="username" type="text" label="username" pInputText formControlName="username" placeholder="Email"
      class="username-input" required>
    <app-error-validation [form]="signInFormGroup" [controlName]="'username'"></app-error-validation>
    <div class="password-container">
      <label for="password" class="password-label" translate>auth.signin.password</label>
      <p-password inputId="password" formControlName="password" [placeholder]="'auth.signin.password' | translate" [feedback]="false" [toggleMask]="true"
        class="password-input " styleClass="p-password p-component p-inputwrapper p-input-icon-right">
      </p-password>
      <app-error-validation [form]="signInFormGroup" [controlName]="'password'"></app-error-validation>
    </div>
  </div>
  <button pButton pRipple type="submit" [label]="'auth.signin.signin' | translate" class="sign-in-button"
    [disabled]="signInFormGroup.invalid"></button>
  <div class="additional-field">
    <a class="av2-link-button forgot-password" [av2routerLink]="NAVI.AUTH_MAIL_PASSWORD_PATH" translate>
      auth.signin.forgot.password
    </a>
  </div>
</form>
