import { Injectable } from "@angular/core";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import timezone from "src/app/shared/customDayjs/timezone";
import forBackend from "src/app/shared/customDayjs/forBackend";
import fromBackend from "src/app/shared/customDayjs/fromBackend";
import toCalendarInput from "src/app/shared/customDayjs/toCalendarInput";
import appTime from "src/app/shared/customDayjs/appTime";
import { select, Store } from "@ngrx/store";
import {
  getAuthUserDateFormat,
  getAuthUserTimeFormat,
  getAuthUserTimeZone
} from "../../modules/auth/store/selectors/auth.selectors";
import { AV2UserSettingsTimeFormat } from "../../commons/models/user-settings";
import { DATE_FORMATS } from "./customDate.pipe";
import dayjs from "dayjs";

@Injectable({ providedIn: "root" })
export class CustomDatePipeService {
  public _dateFormat: string = DATE_FORMATS[0].value;
  public h12: boolean = false;
  public timeFormat: AV2UserSettingsTimeFormat = AV2UserSettingsTimeFormat.H24;

  #timeZoneId!: string;

  public set timeZoneId(value: string) {
    this.#timeZoneId = value;
    dayjs.tz.setDefault(value);
  }

  public get timeZoneId() {
    return this.#timeZoneId;
  }

  constructor(private store$: Store) {
    dayjs.extend(utc);
    dayjs.extend(customParseFormat);
    dayjs.extend(timezone);
    dayjs.extend(isBetween);
    dayjs.extend(isSameOrAfter);
    dayjs.extend(fromBackend);
    dayjs.extend(forBackend);
    dayjs.extend(toCalendarInput);
    dayjs.extend(isSameOrBefore);
    dayjs.extend(appTime);
    this.store$.pipe(select(getAuthUserDateFormat)).subscribe(dateFormat => {
      this._dateFormat = DATE_FORMATS.find(format => format.name === dateFormat)?.value ?? DATE_FORMATS[0].value;
    });
    this.store$.pipe(select(getAuthUserTimeFormat)).subscribe(timeFromat => {
      this.h12 = timeFromat === AV2UserSettingsTimeFormat.H12;
      this.timeFormat = timeFromat;
    });
    this.store$.pipe(select(getAuthUserTimeZone)).subscribe((timezone) => {
      this.timeZoneId = timezone?.zoneId ? timezone.zoneId !== "zone_auto" ? timezone.zoneId : dayjs.tz.guess() : dayjs.tz.guess();
    });
  }


}

