import { Pipe, PipeTransform } from '@angular/core';
import { InjectedClass } from '../helpers/InjectedClass';

@Pipe({
  name: 'caluculateTimeFromMinutes'
})
export class CaluculateTimeFromMinutesPipe implements PipeTransform {

  transform(value: number): string {
    const hours = Math.floor(value / 60);          
    const minutes = value % 60;
    return `${hours} ${InjectedClass.translateService.instant('ui.unit.short-hours')} 
    ${minutes} ${InjectedClass.translateService.instant('ui.unit.short-minutes')}`;
  }

}
