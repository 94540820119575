import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AV2AuthState } from 'src/app/modules/auth/store/models/state.model';
import { getAccessToken } from 'src/app/modules/auth/store/selectors/auth.selectors';
import {AUTH_CONDITIONS_SIGN_IN_PATH, AUTH_SIGN_IN_PATH} from "../../commons/consts/navigation.const";


@Injectable({
  providedIn: 'root'
})
export class ChildAuthGuard  {

  constructor(private router: Router, private store$: Store<AV2AuthState>) {
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.store$.pipe(select(getAccessToken), map(accessToken => {
      if (accessToken?.token.token && accessToken.acceptedTerms) {
        return true;
      } else if (accessToken?.token.token && !accessToken.acceptedTerms){
        this.router.navigate([AUTH_CONDITIONS_SIGN_IN_PATH], {queryParams: {returnUrl: state.url}});
        return false;
      }
      this.router.navigate([AUTH_SIGN_IN_PATH], { queryParams: { returnUrl: state.url } });
      return false;
    }));
  }

}
