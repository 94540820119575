import { Component, OnDestroy, OnInit } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { fromEvent, merge, Observable, Observer, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { UiState } from "src/app/shared/modules/ui/store/models/state.model";
import { getBlockContent, getLoaderVisibility } from "src/app/shared/modules/ui/store/selectors/ui.selectors";
import { throttleMethod } from "../../helpers/decorators";
@Component({
  selector: "app-loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.scss"]
})
export class LoaderComponent implements OnInit, OnDestroy {

  loaderSub?: Subscription;
  timer?: any;
  showSpinner: boolean = false;
  blockContent: Observable<boolean>;

  connectionStateSub?: Subscription;
  isOnline = true;
  offlineTitle = 'ui.offline.title';
  offlineDesc = 'ui.offline.description';

  private controlFlag = true;

  constructor(private uiStore: Store<UiState>) {
    this.blockContent = this.uiStore.pipe(select(getBlockContent));
  }

  ngOnInit(): void {
    this.addLoaderListener();
    this.connectionStateSub = this.connectionState$().subscribe(isOnline => {
      isOnline && !this.isOnline && location.reload();
      this.isOnline = isOnline;
    });
  }

  private addLoaderListener() {
    this.loaderSub = this.uiStore.pipe(select(getLoaderVisibility)).subscribe(value => {
      if (value) {
        this.showSpinner = true;
        this.controlFlag = false;
      } else {
        this.controlFlag = true;
        this.hideLoader();
      }
    });
  }

  @throttleMethod(333, 0, false)
  private hideLoader() {
    this.controlFlag && (this.showSpinner = false);
  }
 
  private connectionState$() {
    return merge<boolean>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      }));
  }

  ngOnDestroy() {
    this.loaderSub && this.loaderSub.unsubscribe();
    this.connectionStateSub?.unsubscribe();
  }
}
