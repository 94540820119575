import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { map } from "rxjs/operators";
import { AV2AuthState } from "src/app/modules/auth/store/models/state.model";
import { getUserRoles } from "../../modules/auth/store/selectors/auth.selectors";
import { AV2Permission } from "../../commons/models/user";
import { showToast } from "../../shared/modules/ui/store/actions/ui.actions";
import { AuthService } from "../../modules/auth/store/services/auth.service";

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard  {

  constructor(private store$: Store<AV2AuthState>, private authService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let neededRoles = Array.isArray(route.data.roles) ? route.data.roles : [route.data.roles];
    return this.store$.pipe(select(getUserRoles), map(permissions => {
      let hasAccess: boolean[] = [];
      neededRoles?.forEach(role => hasAccess.push(this.ifHasAccess(permissions, role)));
      if (hasAccess.some(i => !i)) {
        this.authService.logoutOnFailed();
        this.store$.dispatch(showToast({ severity: 'error', message: 'administration.no.access' }));
        return false;
      } else {
        return true;
      }
    }));
  }

  userHasPerm(neededPermissions: string[]) {
    return this.store$.pipe(select(getUserRoles),
      map(userPerm => {
      let hasAccess: boolean[] = [];
      neededPermissions?.forEach(neededPerm => hasAccess.push(this.ifHasAccess(userPerm, neededPerm)));
      return !hasAccess.some(i => !i);
    }));
  }

  private ifHasAccess(permissions: AV2Permission[], neededRole: string) {
    return permissions?.some(item => item.name === neededRole);
  }

}
