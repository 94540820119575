import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { combineLatest, Subscription } from 'rxjs';
import { FormHelper } from 'src/app/core/helpers/form-validation-helper';
import { AV2TranslationState } from '../../store/model/state.model';
import { locationBack, showToast } from "../../../../../../shared/modules/ui/store/actions/ui.actions";
import { AV2Translation } from "../../store/model/translations.model";
import { createTranslation, loadAllAvailableTranslations } from "../../store/actions/translations.actions";
import { getLokaliseList, getTranslationList } from "../../store/selectors/translations.selectors";
import _ from "lodash";

@Component({
  selector: 'app-translation-create',
  templateUrl: './translation-create.component.html',
  styleUrls: ['./translation-create.component.scss']
})
export class TranslationCreateComponent implements OnInit, OnDestroy {

  languages: AV2Translation[] = [];
  translationsSub?: Subscription;
  languageForm!: UntypedFormGroup;

  constructor(
    private readonly store$: Store<AV2TranslationState>,
    private readonly fb: UntypedFormBuilder,
  ) { }

  ngOnInit(): void {
    this.prepareSortConfig();
  }

  public submit(): void {
    this.languageForm.markAllAsTouched();
    if (this.languageForm.invalid) {
      console.log('Invalid controls', FormHelper.findInvalidControls(this.languageForm!));
      this.store$.dispatch(showToast({ severity: 'error', message: 'ui.form.validation.failed' }));
      return;
    }
    this.store$.dispatch(createTranslation({ locale: this.languageForm.get('language')?.value }));
  }

  public cancel(): void {
    this.store$.dispatch(locationBack());
  }

  private initForm(): void {
    this.languageForm = this.fb.group({
      language: ['', Validators.required]
    });
  }

  private prepareSortConfig(): void {
    this.store$.dispatch(loadAllAvailableTranslations());
    this.translationsSub =
      combineLatest([
        this.store$.pipe(select(getLokaliseList)),
        this.store$.pipe(select(getTranslationList))
      ]).subscribe(([lokaliseList, translationList]) => {
        if (lokaliseList.length && translationList.length) {
          this.languages = _(lokaliseList).differenceBy(translationList, 'tag')
            .map((t) => ({
              ...t,
              combinedNames: `${t.name} ${t.nativeName}`,
            })).value();
          this.initForm();
        }
      })
  }

  ngOnDestroy(): void {
    this.translationsSub?.unsubscribe();
  }

}
