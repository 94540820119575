import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './auth.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ErrorPasswordComponent } from './components/error-password/error-password.component';
import { EmailPasswordComponent } from './components/email-password/email-password.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SuccessPasswordComponent } from './components/success-password/success-password.component';
import { SuccessSendPasswordEmailComponent } from './components/success-send-password-email/success-send-password-email.component';
import { AUTH, BLOCKED_SIGN_IN, CONDITIONS_SIGN_IN, ERROR_PASSWORD, MAIL_PASSWORD, RESET_PASSWORD, SIGN_IN, SUCCESS_PASSWORD, SUCCESS_SEND_PASSWORD, uuid } from 'src/app/commons/consts/navigation.const';
import { BlockedSignInComponent } from './components/blocked-sign-in/blocked-sign-in.component';
import { ConditionsSignInComponent } from './components/conditions-sign-in/conditions-sign-in.component';

const routes: Routes = [
  {
    path: AUTH,
    component: AuthComponent,
    children: [
      { path: SIGN_IN, component: SignInComponent },
      { path: `${RESET_PASSWORD}/${uuid}`, component: ResetPasswordComponent },
      { path: MAIL_PASSWORD, component: EmailPasswordComponent },
      { path: SUCCESS_PASSWORD, component: SuccessPasswordComponent },
      { path: SUCCESS_SEND_PASSWORD, component: SuccessSendPasswordEmailComponent },
      { path: ERROR_PASSWORD, component: ErrorPasswordComponent },
      { path: BLOCKED_SIGN_IN, component: BlockedSignInComponent},
      { path: CONDITIONS_SIGN_IN, component: ConditionsSignInComponent},
      { path: '**', redirectTo: SIGN_IN, pathMatch: "full" },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class AuthRoutingModule { }
