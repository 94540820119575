import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { NAVI } from 'src/app/commons/consts';
import { acceptConditions, logout } from '../../store/actions/auth.actions';
import { GENERAL_DATA_PROTECTION_REGULATION_HREF } from '../../store/consts/auth-docs-path.const';
import { AV2AuthState } from '../../store/models/state.model';
import { AuthService } from '../../store/services/auth.service';
import {getAuthData} from "../../store/selectors/auth.selectors";
import {first} from "rxjs/operators";
import {AV2User} from "../../../../commons/models/user";

@Component({
  selector: 'app-conditions-sign-in',
  templateUrl: './conditions-sign-in.component.html',
  styleUrls: ['./conditions-sign-in.component.scss']
})
export class ConditionsSignInComponent implements OnInit {

  NAVI = NAVI;
  acceptanceFormGroup!: UntypedFormGroup;
  isVisiblePersonalBody = false;
  dataProtectionHref?: string;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private store$: Store<AV2AuthState>,
    private authService: AuthService) {
      this.store$.select(getAuthData)
        .pipe(first())
        .subscribe((user: AV2User) => {
          const userLang = this.authService.getAuthDocsLanguage(false, user.userSettingsDto.language);
          this.dataProtectionHref = GENERAL_DATA_PROTECTION_REGULATION_HREF.replace("{LANGUAGE_CODE}", userLang);
        }
      )
   }

  ngOnInit(): void {
    this.initForm();
  }

  private initForm() {
    this.acceptanceFormGroup = this.fb.group({
      personalData: [false],
      rodo: [false]
    })
  }

  accept(){
    this.store$.dispatch(
      acceptConditions()
    );
  }

  changeVisibilityPersonal(){
    this.isVisiblePersonalBody = !this.isVisiblePersonalBody;
  }

  logout() {
    this.store$.dispatch(logout({}));
  }

}
