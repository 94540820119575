import {
  AfterContentInit,
  ChangeDetectorRef,
  ContentChildren,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  QueryList,
  Renderer2,
  SimpleChanges
} from '@angular/core';
import {from, of, Subscription} from 'rxjs';
import {filter, mergeAll} from 'rxjs/operators';
import {IsActiveMatchOptions, NavigationEnd, Router} from "@angular/router";
import {AV2RouterLink, AV2RouterLinkWithHref} from "./router-link.directive";

@Directive({
  selector: '[av2routerLinkActive]',
  exportAs: 'av2routerLinkActive',
})
export class AV2RouterLinkActive implements OnChanges, OnDestroy, AfterContentInit {
  @ContentChildren(AV2RouterLink, {descendants: true}) links!: QueryList<AV2RouterLink>;
  @ContentChildren(AV2RouterLinkWithHref, {descendants: true})
  linksWithHrefs!: QueryList<AV2RouterLinkWithHref>;

  private classes: string[] = [];
  private routerEventsSubscription: Subscription;
  private linkInputChangesSubscription?: Subscription;
  public readonly isActive: boolean = false;

  @Input('av2routerLinkActiveOptions') routerLinkActiveOptions: { exact: boolean } | IsActiveMatchOptions = {exact: false};


  constructor(
    private router: Router, private element: ElementRef, private renderer: Renderer2,
    private readonly cdr: ChangeDetectorRef, @Optional() private link?: AV2RouterLink,
    @Optional() private linkWithHref?: AV2RouterLinkWithHref) {
    this.routerEventsSubscription = router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.update();

    });
  }

  ngAfterContentInit(): void {
    of(this.links.changes, this.linksWithHrefs.changes, of(null)).pipe(mergeAll()).subscribe(_ => {
      this.update();
      this.subscribeToEachLinkOnChanges();
    });
  }

  private subscribeToEachLinkOnChanges() {
    this.linkInputChangesSubscription?.unsubscribe();
    const allLinkChanges =
      [...this.links.toArray(), ...this.linksWithHrefs.toArray(), this.link, this.linkWithHref]
        .filter((link): link is AV2RouterLink | AV2RouterLinkWithHref => !!link)
        .map(link => link.onChanges);
    this.linkInputChangesSubscription = from(allLinkChanges).pipe(mergeAll()).subscribe(link => {
      if (this.isActive !== this.isLinkActive(this.router)(link)) {
        this.update();
      }
    });
  }

  @Input('av2routerLinkActive')
  set routerLinkActive(data: string[] | string) {
    const classes = Array.isArray(data) ? data : data.split(' ');
    this.classes = classes.filter(c => !!c);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.update();
  }

  ngOnDestroy(): void {
    this.routerEventsSubscription.unsubscribe();
    this.linkInputChangesSubscription?.unsubscribe();
  }

  private update(): void {
    if (!this.links || !this.linksWithHrefs || !this.router.navigated) return;
    Promise.resolve().then(() => {
      const hasActiveLinks = this.hasActiveLinks();
      if (this.isActive !== hasActiveLinks) {
        (this as any).isActive = hasActiveLinks;
        this.cdr.markForCheck();
        this.classes.forEach((c) => {
          if (hasActiveLinks) {
            this.renderer.addClass(this.element.nativeElement, c);
          } else {
            this.renderer.removeClass(this.element.nativeElement, c);
          }
        });
      }
    });
  }

  private isLinkActive(router: Router): (link: (AV2RouterLink | AV2RouterLinkWithHref)) => boolean {
    const options: boolean | IsActiveMatchOptions =
      isActiveMatchOptions(this.routerLinkActiveOptions) ?
        this.routerLinkActiveOptions :
        (this.routerLinkActiveOptions.exact || false);
    return (link: AV2RouterLink | AV2RouterLinkWithHref) => router.isActive(link.urlTree, <boolean>options);
  }

  private hasActiveLinks(): boolean {
    const isActiveCheckFn = this.isLinkActive(this.router);
    return this.link && isActiveCheckFn(this.link) ||
      this.linkWithHref && isActiveCheckFn(this.linkWithHref) ||
      this.links.some(isActiveCheckFn) || this.linksWithHrefs.some(isActiveCheckFn);
  }
}

function isActiveMatchOptions(options: { exact: boolean } |
  IsActiveMatchOptions): options is IsActiveMatchOptions {
  return !!(options as IsActiveMatchOptions).paths;
}
