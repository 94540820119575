import {AbstractControl} from "@angular/forms";

/**
 * If the new password and the repeat password don't match, set the error on both fields. If they do match, check if the
 * new password is strong enough. If it is, set the error to null. If it isn't, set the error to strong
 * @param {AbstractControl} control - AbstractControl - This is the form control that we are validating.
 * @returns An object with a key of invalid and a value of true.
 */
export function passwordMatch(control: AbstractControl): { invalid: boolean } | null {
  if (control) {
    const newPass = control.get("newPassword");
    const repeatPass = control.get("newPasswordReply");

    let hasNumber = /\d/.test(newPass?.value);
    let hasUpper = /[A-Z]/.test(newPass?.value);
    let hasLower = /[a-z]/.test(newPass?.value);
    let hasSpecialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(newPass?.value);
    const valid = hasNumber && hasUpper && hasLower && hasSpecialChar;

    if (valid && newPass?.hasError('minlength') === false && repeatPass?.hasError('minlength') === false) {

      newPass?.setErrors(null);
      repeatPass?.setErrors(null);

      if (repeatPass?.value !== newPass?.value) {
        repeatPass?.value && newPass?.setErrors({match: true});
        repeatPass?.setErrors({match: true});
        return {invalid: true};
      }

      return null;
    } else {

      if (!valid || newPass?.hasError('minlength') === true) {
        newPass?.setErrors({strong: true});
        return {invalid: true};
      }

      if (!valid || repeatPass?.hasError('minlength') === true) {
        repeatPass?.setErrors({strong: true});
        return {invalid: true};
      }
    }
  }
  return {invalid: true};
}
