import {Component, Input, SkipSelf} from '@angular/core';
import {AbstractControl, ControlContainer} from '@angular/forms';
import { email, ISOLetters, onlyLettersAndNumbers, onlyNumbers } from 'src/app/core/validators/validation-patterns';

@Component({
  selector: 'app-error-validation',
  templateUrl: './error-validation.component.html',
  styleUrls: ['./error-validation.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: (container: ControlContainer) => container,
      deps: [[new SkipSelf(), ControlContainer]]
    }
  ],
})

export class ErrorValidationComponent {
  onlyLettersAndNumbers = onlyLettersAndNumbers;
  onlyNumbers = onlyNumbers;
  ISOLetters = ISOLetters;
  email = email;

  @Input() form?: AbstractControl | undefined | null;
  @Input() controlName?: string;
  @Input() englishOnly: boolean = false;

  constructor(private cc: ControlContainer) {
  }

  get control(): AbstractControl | undefined | null | "" {
    const form = this.form ?? this.cc.control;
    if(form) {
      if(!!this.controlName) {
        return form.get(this.controlName);
      } else {
        return form
      }
    }
    return
  }
}
