import { Action, createReducer, on } from '@ngrx/store';
import _ from 'lodash';
import { loadImpersonatedUserSuccess, signInSuccess } from 'src/app/modules/auth/store/actions/auth.actions';
import * as TranslationsActions from '../actions/translations.actions';
import { INITIAL_TRANSLATION_STATE } from '../consts/initial-store.const';
import { AV2TranslationState } from '../model/state.model';

export function translationReducer(state: AV2TranslationState, action: Action) {
  return reducer(state, action);
}

export const reducer = createReducer(
  INITIAL_TRANSLATION_STATE,
  on(loadImpersonatedUserSuccess, (state, action) =>
    INITIAL_TRANSLATION_STATE,
  ),
  on(signInSuccess, (state, action) =>
    INITIAL_TRANSLATION_STATE,
  ),
  on(TranslationsActions.loadAllTranslationsSuccess, (state, action) => ({
    ...state,
    translationList: _.orderBy(action.translationsList, 'name')
  })),
  on(TranslationsActions.loadTranslationByLocaleSuccess, (state, action) => ({
    ...state,
    translation: action.translation
  })),
  on(TranslationsActions.deleteTranslationSuccess, (state, action) => ({
    ...state,
    translationList: state.translationList.filter(c => c.tag === action.locale)
  })),
  on(TranslationsActions.loadAllAvailableTranslationsSuccess, (state, action) => ({
    ...state,
    lokaliseList: action.lokaliseList
  })),
  on(TranslationsActions.showTranslationsTableLoader, (state, action) => ({
    ...state,
    translationsTableLoaderState: true
  })),
  on(TranslationsActions.hideTranslationsTableLoader, (state, action) => ({
    ...state,
    translationsTableLoaderState: false
  })),
);

