import { Injectable, OnDestroy } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AV2AuthState } from 'src/app/modules/auth/store/models/state.model';
import { getAccessToken } from 'src/app/modules/auth/store/selectors/auth.selectors';
import { filter, switchMap, take } from 'rxjs/operators';
import { JwtHelperService } from "@auth0/angular-jwt";
import { refreshToken } from 'src/app/modules/auth/store/actions/auth.actions';
import { TRANSLATION_SERVICE_API_TRANSLATION, USER_SERVICE_CHECK_UUID_RESET_PASSWORD_API, USER_SERVICE_LOGIN_API, USER_SERVICE_REFRESHTOKEN_API, USER_SERVICE_RESET_PASSWORD_API, USER_SERVICE_SENT_EMAIL_RESET_PASSWORD_API } from 'src/app/commons/consts/api.const';
import {environment} from "../../../environments/environment";


@Injectable()
export class JwtInterceptor implements HttpInterceptor, OnDestroy {

  token: string = "";
  refreshToken: string = "";
  impersonateMode: boolean = false;

  private refreshTokenInProgress = false;
  private refreshToken$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private subscription: Subscription;

  constructor(private store$: Store<AV2AuthState>, private jwtHelper: JwtHelperService) {
    this.subscription = this.store$.pipe(select(getAccessToken))
      .subscribe(data => {
        if (data?.token) {
          this.impersonateMode = data.impersonateMode;
          if (data?.token?.token !== "" || this.token !== data?.token?.token) {
            this.refreshToken = data?.token?.refreshToken;
            this.token = data?.token?.token;
            this.refreshToken$.next(this.refreshToken);
          }
        }
      })
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes(USER_SERVICE_REFRESHTOKEN_API)
      || request.url.includes(USER_SERVICE_LOGIN_API)
      || request.url.includes(USER_SERVICE_CHECK_UUID_RESET_PASSWORD_API)
      || request.url.includes(USER_SERVICE_SENT_EMAIL_RESET_PASSWORD_API)
      || request.url.includes(USER_SERVICE_RESET_PASSWORD_API)
      || (request.url.includes(TRANSLATION_SERVICE_API_TRANSLATION) && request.method === 'GET')) {
      return next.handle(request);
    }
    this.refreshTokenInProgress = this.isRefreshTokenInProgress(this.token);
    if (this.refreshTokenInProgress) {
      return this.refreshToken$.pipe(
        filter(result => result !== null),
        take(1),
        switchMap(() => next.handle(this.addAuthHeader(request)))
      );
    } else {
      this.refreshToken$.next(null);
      return !!this.token ? next.handle(this.addAuthHeader(request)) : next.handle(request);
    }
  }

  private addAuthHeader(req: HttpRequest<any>): HttpRequest<any> {
    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${this.token}`
      }
    });
  }

  private isRefreshTokenInProgress(accessToken: string) {
    const refreshTokenTime = environment.refreshSessionSettings.tryRefreshTokenBeforeTheEndTokenLife;
    if (!!accessToken && this.jwtHelper.isTokenExpired(accessToken, refreshTokenTime)) {
      this.refreshToken$.next(null);
      this.store$.dispatch(refreshToken({ refreshToken: this.refreshToken }));
      return true;
    }
    return false;
  }

  ngOnDestroy() {
    this.subscription && this.subscription.unsubscribe();
  }

}




