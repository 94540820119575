<form [formGroup]="mailPasswordFormGroup" class="form-container">
  <h2 class="form-title" translate>auth.email-password.title</h2>
  <div class="form-body" translate>auth.email-password.information</div>
  <div class="mail-password-input">
    <label for="email">Email</label>
    <input id="email" type="email" label="email" pInputText formControlName="email" placeholder="Email"
      class="email-input" email>
  </div>
  <button pButton pRipple type="submit" [label]="'auth.email-password.send-link' | translate" class="mail-password-button"
    [disabled]="mailPasswordFormGroup.invalid" (click)="sendEmail()"></button>
  <div class="additional-field">
    <a class="av2-link-button" [av2routerLink]="NAVI.AUTH_SIGN_IN_PATH" translate>
      auth.email-password.back-to-sign-in
    </a>
  </div>
</form>