import {interval, Observable} from "rxjs";
import {InjectedClass} from "../../core/helpers/InjectedClass";
import dayjs from "dayjs";
import {LocalTime} from "../classes/LocalTime.class";
import {logout} from "../../modules/auth/store/actions/auth.actions";


export class DateUtils {
  public static returnOffsetInMinutes(isoOffset: string, revertSign = false): number {
    const ar = isoOffset.split("");
    const m = ar[0] === "-";
    let o = Number((ar[1] + ar[2])) * 60 + Number(ar[3] + ar[4]);
    revertSign && (o = -o);
    return m ? o : -o;
  }

  public static getAutoOffset(): string {
    let offsetInMin = -new Date().getTimezoneOffset();
    let hourString = String(offsetInMin / 60).padStart(2, "0");
    let minutesString = String(offsetInMin % 60).padStart(2, "0");
    let mark = offsetInMin > 0 ? "+" : "-";
    return mark + hourString + minutesString;
  }

  public static getFullTimeOffset(isoOffset: string, revertSign = false): Array<number> {
    return [DateUtils.getHoursOffset(isoOffset, revertSign), DateUtils.getMinutesOffset(isoOffset, revertSign)];
  }

  public static getHoursOffset(isoOffset: string, revertSign = false): number {
    const ar = isoOffset.split("");
    const m = ar[0] === "-";
    let o = Number((ar[1] + ar[2]));
    revertSign && (o = -o);
    return m ? o : -o;
  }

  public static getMinutesOffset(isoOffset: string, revertSign = false): number {
    const ar = isoOffset.split("");
    const m = ar[0] === "-";
    let o = Number(ar[3] + ar[4]);
    revertSign && (o = -o);
    return m ? o : -o;
  }

  public static convertToHours(minutes: number): string {
    let hoursAmount = Math.floor(minutes / 60);
    let minutesAmount = Math.floor(minutes % 60);
    return `${hoursAmount}h ${minutesAmount}min`;
  }

  public static convertToHoursFromSeconds(seconds: number, withSeconds = false): string {
    let hoursAmount = Math.floor(seconds / 60 / 60);
    let minutesAmount = Math.floor((seconds - (hoursAmount * 60 * 60)) / 60);
    const sec = Math.floor(seconds % 60);
    if (sec > 0 && !withSeconds) {
      minutesAmount++;
      if (minutesAmount === 60) {
        minutesAmount = 0;
        hoursAmount++;
      }
    }
    return `${hoursAmount}h ${minutesAmount}min${withSeconds ? (" " + sec + "s") : ""}`;
  }

  public static synchronizedObservable(precisionInMs = 100) {
    return new Observable((observer) => {
      this.synchronizedInterval(() => observer.next(), precisionInMs);
    });
  }

  public static async synchronizedInterval(handler: TimerHandler, precisionInMs = 100): Promise<number> {
    await DateUtils.w8_4_full_second(precisionInMs);
    return setInterval(handler, 1000);
  }

  public static w8_4_full_second(precisionInMs = 100): Promise<void> {
    return new Promise(resolve => {
      if (new Date().getMilliseconds() < precisionInMs) resolve();
      else sleep(precisionInMs).then(() => resolve(DateUtils.w8_4_full_second(precisionInMs)));
    });
  }

  public static range(): IDateRange {
    const day_index = +InjectedClass.translateService.instant("primeng.firstDayOfWeek");
    const now = dayjs().setAppTime(12, 0, 0);
    return {
      CURRENT_MONTH: [now.startOf("month").toDate(), now.endOf("month").toDate()],
      CURRENT_WEEK: [now.startOf("w").add(day_index, "d").toDate(), now.endOf("w").add(day_index, "d").toDate()],
      LAST_DAYS(n: number): [Date, Date] {
        return [now.subtract(n, "d").toDate(), now.toDate()];
      },
      LAST_MONTH: [now.startOf("months").subtract(1, "months").toDate(), now.endOf("months").subtract(1, "months").toDate()],
      LAST_WEEK: [now.startOf("w").add(day_index, "d").subtract(1, "w").toDate(), now.endOf("w").add(day_index, "d").subtract(1, "w").toDate()],
      TODAY: [now.setAppTime(0, 0, 0).toDate(), now.setAppTime(23, 59, 59).toDate()],
      TOMORROW: [now.add(1, "d").setAppTime(0, 0, 0).toDate(), now.add(1, "d").setAppTime(23, 59, 59).toDate()],
      YESTERDAY: [now.subtract(1, "d").setAppTime(0, 0, 0).toDate(), now.subtract(1, "d").setAppTime(23, 59, 59).toDate()]

    };
  }

  public static localTimeStringToUTCTimeString(localTime: string): string {
    const lt = new LocalTime(localTime);
    const result = dayjs().setAppTime(lt.get("h"), lt.get("m"), lt.get("s"));
    const withSeconds = localTime.split(":").length > 2;
    return result.utc().format(`HH:mm${withSeconds ? ":ss" : ""}`);
  }
}

export function sleep(duration: number): Promise<any> {
  return new Promise(resolve => {
    setTimeout(resolve, duration);
  });
}

export interface IDateRange extends I {
  TOMORROW: [Date, Date],
  TODAY: [Date, Date],
  YESTERDAY: [Date, Date],
  CURRENT_WEEK: [Date, Date],
  LAST_WEEK: [Date, Date],
  CURRENT_MONTH: [Date, Date],
  LAST_MONTH: [Date, Date],
  LAST_DAYS: (n: number) => [Date, Date],
}

export interface CountdownData {
  minutes: number;
  seconds: number;
}

type I = { [P in keyof typeof DateRangeEnum]: [Date, Date] | N }
type N = (n: number) => [Date, Date]

export enum DateRangeEnum {
  TOMORROW = "TOMORROW",
  TODAY = "TODAY",
  YESTERDAY = "YESTERDAY",
  CURRENT_WEEK = "CURRENT_WEEK",
  LAST_WEEK = "LAST_WEEK",
  CURRENT_MONTH = "CURRENT_MONTH",
  LAST_MONTH = "LAST_MONTH",
  LAST_DAYS = "LAST_DAYS",
}
