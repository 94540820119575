import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {
  TRANSLATION_SERVICE_API_LOKALISE,
  TRANSLATION_SERVICE_API_LOKALISE_AVAILABLE,
  TRANSLATION_SERVICE_API_TRANSLATION
} from "src/app/commons/consts/api.const";
import {AV2Translation} from "../model/translations.model";

@Injectable({
  providedIn: 'root'
})
export class TranslationsService {

  constructor(private http: HttpClient) {
  }

  getAllTranslations(): Observable<AV2Translation[]> {
    return this.http.get<AV2Translation[]>(TRANSLATION_SERVICE_API_TRANSLATION);
  }

  getTranslationByLocale(locale: string) {
    return this.http.get<AV2Translation>(`${TRANSLATION_SERVICE_API_TRANSLATION}/${locale}`);
  }

  deleteTranslation(locale: string) {
    return this.http.delete(`${TRANSLATION_SERVICE_API_TRANSLATION}/${locale}`);
  }

  getAllAvailableTranslations(): Observable<AV2Translation[]> {
    return this.http.get<AV2Translation[]>(TRANSLATION_SERVICE_API_LOKALISE_AVAILABLE);
  }

  createTranslation(locale: string) {
    return this.http.post(`${TRANSLATION_SERVICE_API_LOKALISE}/${locale}`, null);
  }

}
