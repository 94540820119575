<div class="av2-paginator-container">

  <div class="paginator-sites">
    <span *ngIf="title !== null" class="av2-text-normal-2">{{ title | translate }}: </span>
    <p-paginator #paginator [totalRecords]="totalRecords" [rows]="rowsPerPageOptions[0]" [showJumpToPageInput]="false"
      [showFirstLastIcon]="true" [showPageLinks]="true" (onPageChange)="onPageChange($event)">
    </p-paginator>
  </div>
  <div class="paginator-go-to">
    <span class="av2-text-normal-2">{{ "paginator.gotToThePage" | translate }}: </span>
    <p-inputNumber [(ngModel)]="currentPage" [min]="firstPage" [max]="lastPage"></p-inputNumber>
    <span class="av2-text-normal-2">{{ "paginator.display-of" | translate }}:
      <span class="av2-text-normal-1">
        {{lastPage}}
      </span>
    </span>
  </div>
  <div class="dropdown-container">
    <span class="av2-text-normal-2">{{ "paginator.display" | translate }}: </span>
    <p-dropdown class="av2-paginator-dropdown" [options]="rowsPerPageOptions" [(ngModel)]="currentRowOption">
    </p-dropdown>
  </div>
</div>
