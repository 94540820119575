import {AV2Theme} from "../enums/theme";
import {AV2User, AV2UserType, AV2WorkStartConfig} from "./user";
import {AV2UserSettingsDto, AV2UserSettingsTimeFormat} from "./user-settings";


export const AV2_USER_SETTINGS_DTO_DEFAULT_STATE: AV2UserSettingsDto = {
  dateFormat: "ISO",
  isSound: true,
  timeFormat: AV2UserSettingsTimeFormat.H24,
  csvSeparator: 'SEMICOLON',
  id: 0,
  language: 'en',
  notification: false,
  theme: AV2Theme.LIGHT,
  timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone
}

export const AV2_USER_WORK_START_CONFIG: AV2WorkStartConfig = {
  tag: false,
  selfie: false,
  tagSelfie: false,
  manual: false,
  auto: false
}

export const AV2_USER_DEFAULT_STATE: AV2User = {
  id: 0,
  active: false,
  clientId: 0,
  email: '',
  firstName: '',
  lastName: '',
  username: '',
  phoneNumber: '',
  score: 0,
  type: AV2UserType.USER,
  role: '',
  userSettingsDto: {...AV2_USER_SETTINGS_DTO_DEFAULT_STATE},
  userAssignments: [],
  photo: null,
  workStartConfig: {...AV2_USER_WORK_START_CONFIG},
  isSessionAutoRefresh: false
}
