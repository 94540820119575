import { AV2Theme } from "../enums/theme";

export interface AV2UserSettingsDto {
  id: number;
  timeZoneId: string;
  csvSeparator: string;
  theme: AV2Theme;
  language: string;
  notification: boolean;
  isSound: boolean,
  dateFormat: string,
  timeFormat: AV2UserSettingsTimeFormat
}

export enum AV2UserSettingsTimeFormat {
  H24 = "H24",
  H12 = "H12"
}