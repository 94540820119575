import {HttpClient, HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {APP_INITIALIZER, Inject, InjectionToken, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ErrorInterceptor} from './core/interceptors/error.interceptor';
import {HttpRequestInterceptor} from './core/interceptors/http-request.interceptor';
import {JwtInterceptor} from './core/interceptors/jwt.interceptor';
import {PrimeNgModule} from './prime-ng.module';
import {AuthModule} from './modules/auth/auth.module';
import {StoreModule} from "@ngrx/store";
import {EffectsModule} from "@ngrx/effects";
import {StoreDevtoolsModule} from "@ngrx/store-devtools";
import {InfoDialogModule} from './shared/info-dialog/info-dialog.module';
import {LoaderModule} from './shared/components/loader/loader.module';
import {UI_STORE_NAME} from './shared/modules/ui/store/consts/store-name.const';
import {uiReducer} from './shared/modules/ui/store/reducers/ui.reducer';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {JwtModule} from '@auth0/angular-jwt';
import {LocalStorageItem} from './commons/enums/local-storage';
import {CoreModule} from "./core/core.module";
import {StyleGuideComponent} from './dev/style-guide/style-guide.component';
import {TranslateHttpLoader} from './core/translate-http-loader/translate-http-loader';
import {DropdownModule} from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {ToastModule} from 'primeng/toast';
import {MessageService} from 'primeng/api';
import {UiModule} from "./shared/modules/ui/ui.module";
import {TranslationsModule} from "./modules/settings/modules/translations/translations.module";
import {RouteReuseStrategy} from "@angular/router";
import {CustomRoutingReuse} from "./core/custom-routing-reuse/custom-routing-reuse";
import {TranslationsEffects} from "./modules/settings/modules/translations/store/effects/translations.effects";
import {PasswordModule} from 'primeng/password';
import {CustomDatePipe} from "./shared/pipes/customDate.pipe";
import {InjectedClass} from "./core/helpers/InjectedClass";
import {LocationBackGuardService} from "./core/services/locationBackGuard.service";
import {BrowserCssLoaderService} from "./core/services/browserCssLoaderService.service";
import { CustomDatePipeService } from "./shared/pipes/customDatePipe.service";
import { AV2RangePickerModule } from "./shared/components/range-picker/range-picker.component";
import {ImpersonateInterceptor} from "./core/interceptors/impersonate.interceptor";
import {QRCodeModule} from "angularx-qrcode";
import Quill from "quill";

export const INJECT_TS = new InjectionToken<TranslateService>('injected.translateService');

export function injectTS_Factor(translateService: TranslateService) {
  InjectedClass.translateService = translateService;
  return translateService;
}

export const injectTS_SuperProvider =
  {
    provide: INJECT_TS,
    useFactory: injectTS_Factor,
    deps: [TranslateService]
  };

function tokenGetter() {
  return localStorage.getItem(`${LocalStorageItem.ACCESS_TOKEN}.token`);
}

function init_LocationBackGuardService(provider: LocationBackGuardService) {
  return () => provider.startService();
}

function init_BrowserCssLoaderService(provider: BrowserCssLoaderService) {
  return () => provider.startService();
}

@NgModule({
  declarations: [
    AppComponent,
    StyleGuideComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AuthModule,
    HttpClientModule,
    AppRoutingModule,
    PrimeNgModule,
    CoreModule,
    StoreModule.forRoot({}, {}),
    EffectsModule.forRoot([]),
    StoreModule.forFeature(UI_STORE_NAME, uiReducer),
    InfoDialogModule,
    LoaderModule,
    DropdownModule,
    FormsModule,
    ToastModule,
    TranslationsModule,
    UiModule,
    PasswordModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: "en"
    }),
    // TODO Disable Store Devtool on production env
    StoreDevtoolsModule.instrument(),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter
      }
    }),
    EffectsModule.forRoot([
      TranslationsEffects
    ]),
    AV2RangePickerModule,
    ReactiveFormsModule,
    QRCodeModule
  ],

  providers: [
    MessageService,
    CustomDatePipe,
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ImpersonateInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: RouteReuseStrategy, useClass: CustomRoutingReuse},
    injectTS_SuperProvider,
    LocationBackGuardService,
    {
      provide: APP_INITIALIZER, useFactory: init_LocationBackGuardService, deps: [LocationBackGuardService], multi: true
    },
    BrowserCssLoaderService,
    {
      provide: APP_INITIALIZER, useFactory: init_BrowserCssLoaderService, deps: [BrowserCssLoaderService], multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(@Inject(INJECT_TS) translateService: TranslateService, private customDatePipeService: CustomDatePipeService) {
  }
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
