<p class="p-invalid" *ngIf="control && control.invalid && control.touched">
  <ng-container *ngIf="control.errors?.required">
    <span *ngIf="!englishOnly; else withoutTranslation">
      {{'ui.common.validation.error.required' | translate }}
    </span>
    <ng-template #withoutTranslation>
      This field is required.
    </ng-template>
  </ng-container>
  <ng-container *ngIf="control.errors?.minlength">
    <span *ngIf="!englishOnly; else withoutTranslation">
      {{'ui.common.validation.error.minlength' | translate }} {{control.errors?.minlength.requiredLength}}.
    </span>
    <ng-template #withoutTranslation>
      Minimum length required: {{control.errors?.minlength.requiredLength}}.
    </ng-template>
  </ng-container>
  <ng-container *ngIf="control.errors?.maxlength">
    <span *ngIf="!englishOnly; else withoutTranslation">
      {{'ui.common.validation.error.maxlength' | translate }} {{control.errors?.maxlength.requiredLength}}.
    </span>
    <ng-template #withoutTranslation>
      Maximum length required: {{control.errors?.maxlength.requiredLength}}.
    </ng-template>
  </ng-container>
  <ng-container *ngIf="control.errors?.match">
    <span *ngIf="!englishOnly; else withoutTranslation">
      {{'ui.common.validation.error.match' | translate }}
    </span>
    <ng-template #withoutTranslation>
      These fields do not match.
    </ng-template>
  </ng-container>
  <ng-container *ngIf="control.errors?.whiteSpace">
    <span *ngIf="!englishOnly; else withoutTranslation">
      {{'ui.common.validation.error.white-space' | translate }}
    </span>
    <ng-template #withoutTranslation>
      The field should not start from a space.
    </ng-template>
  </ng-container>
  <ng-container *ngIf="control.errors?.max">
    <span *ngIf="!englishOnly; else withoutTranslation">
      {{'ui.common.validation.error.max' | translate }} {{control.errors?.max.max}}.
    </span>
    <ng-template #withoutTranslation>
      Maximum value:
    </ng-template>
  </ng-container>
  <ng-container *ngIf="control.errors?.min">
    <span *ngIf="!englishOnly; else withoutTranslation">
      {{'ui.common.validation.error.min' | translate }} {{control.errors?.min.min}}.
    </span>
    <ng-template #withoutTranslation>
      Minimum value:
    </ng-template>
  </ng-container>
  <ng-container *ngIf="control.errors?.sameTime">
    <span *ngIf="!englishOnly; else withoutTranslation">
      {{'ui.common.validation.error.same-time' | translate }}
    </span>
    <ng-template #withoutTranslation>
      Times can not be identical.
    </ng-template>
  </ng-container>
  <ng-container *ngIf="control.errors?.duplicate">
    <span *ngIf="!englishOnly; else withoutTranslation">
      {{'ui.common.validation.error.duplicate' | translate}}
    </span>
    <ng-template #withoutTranslation>
      Shifts can not be duplicated.
    </ng-template>
  </ng-container>
  <ng-container *ngIf="control.errors?.endTimeBeforeStart">
    <span *ngIf="!englishOnly; else withoutTranslation">
      {{'ui.common.validation.error.endTimeBeforeStart' | translate }}
    </span>
    <ng-template #withoutTranslation>
      End time is before start
    </ng-template>
  </ng-container>
  <ng-container *ngIf="!control.errors?.duplicate && control.errors?.overlapped">
    <span *ngIf="!englishOnly; else withoutTranslation">
      {{'ui.common.validation.error.overlapped' | translate}}
    </span>
    <ng-template #withoutTranslation>
      Shifts can not overlap.
    </ng-template>
  </ng-container>
  <ng-container *ngIf="control.errors?.routeControlPointsTime">
    <span *ngIf="!englishOnly; else withoutTranslation">
      {{'ui.common.validation.error.routeControlPointsTime' | translate}}
    </span>
    <ng-template #withoutTranslation>
      Maximum time cannot be lower than minimum time.
    </ng-template>
  </ng-container>
  <ng-container *ngIf="control.errors?.routeControlPointsTimeEqual">
    <span *ngIf="!englishOnly; else withoutTranslation">
      {{'ui.common.validation.error.routeControlPointsTimeEqual' | translate}}
    </span>
    <ng-template #withoutTranslation>
      Maximum time cannot be equal to minimum time.
    </ng-template>
  </ng-container>
  <ng-container *ngIf="control.errors?.strong">
    <span *ngIf="!englishOnly; else withoutTranslation">
      {{'ui.common.validation.error.strong' | translate}}
    </span>
    <ng-template #withoutTranslation>
      The password must be at least 8 characters long and contain at least one number, a capital letter and a special
      character.
    </ng-template>
  </ng-container>
  <ng-container *ngIf="control.errors?.pattern">
    <span *ngIf="control.errors?.pattern.requiredPattern === onlyLettersAndNumbers">
      {{'ui.common.validation.error.only-letters-and-numbers' | translate}}
    </span>
    <span *ngIf="control.errors?.pattern.requiredPattern === onlyNumbers">
      {{'ui.common.validation.error.only-numbers' | translate}}
    </span>
    <span *ngIf="control.errors?.pattern.requiredPattern === ISOLetters">
      {{'ui.common.validation.error.iso-letters' | translate}}
    </span>
    <span *ngIf="control.errors?.pattern.requiredPattern === email">
      {{'ui.common.validation.error.email' | translate}}
    </span>
  </ng-container>
  <ng-container *ngIf="control.errors?.hasDuplicates">
    <span *ngIf="!englishOnly; else withoutTranslation">
      {{'ui.common.validation.error.hasDuplicates' | translate}}
    </span>
    <ng-template #withoutTranslation>
      This input group can not contain any duplicates.
    </ng-template>
  </ng-container>
  <ng-container *ngIf="control.errors?.sameEmployeeSelected">
    <span *ngIf="!englishOnly; else withoutTranslation">
      {{'ui.common.validation.error.sameEmployeeSelected' | translate}}
    </span>
    <ng-template #withoutTranslation>
      Same employee selected
    </ng-template>
  </ng-container>
</p>
