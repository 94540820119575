import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { AV2AuthState } from "../../modules/auth/store/models/state.model";
import { AV2Permission } from "../../commons/models/user";
import { getUserRoles } from "src/app/modules/auth/store/selectors/auth.selectors";
import { take } from "rxjs/operators";
import { AV2HasAccessAdditional } from "../enums/permissions.enum";

@Directive({ selector: '[hasAccessIf]' })
export class HasAccessDirective implements OnInit {

  constructor(private template: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private store$: Store<AV2AuthState>) {
  }

  neededRoles: string[] = [];
  reverse: boolean = false;
  orMode: boolean = false;

  @Input()
  set hasAccessIfReverse(reverse: boolean) {
    this.reverse = reverse;
  }

  // Use this flag if one of multiple permissions can give access (eg. for menu group)
  @Input()
  set hasAccessIfOrMode(orMode: boolean) {
    this.orMode = orMode;
  }

  @Input()
  set hasAccessIf(neededRole: string | string[]) {
    this.neededRoles = Array.isArray(neededRole) ? neededRole : [neededRole];
  }

  ngOnInit(): void {
    this.store$.pipe(select(getUserRoles), take(1)).subscribe(permissions => {
      let hasAccessArray: boolean[] = [];
      this.neededRoles?.forEach(role =>
        role === AV2HasAccessAdditional.NOT_CHECK || role === AV2HasAccessAdditional.NOT_IMPLEMENTED ?
          hasAccessArray.push(true) : hasAccessArray.push(this.ifHasAccess(permissions, role)));
      const hasAccess = this.orMode ? hasAccessArray.some(i => i) : hasAccessArray.every(i => i);
      if (hasAccess) {
        this.reverse ? this.hideView() : this.showView();
      } else {
        this.reverse ? this.showView() : this.hideView();
      }
    });
  }

  private ifHasAccess(permissions: AV2Permission[], neededRole: string) {
    return permissions?.some(item => item.name === neededRole);
  }

  private showView() {
    this.viewContainer.createEmbeddedView(this.template);
  }

  private hideView() {
    this.viewContainer.clear();
  }
}
