import { Action, createReducer, on } from "@ngrx/store";
import * as AuthActions from "../actions/auth.actions";
import { INITIAL_AUTH_STATE } from "../consts/initial-store.const";
import { AV2AuthState } from "../models/state.model";
import * as MyProfileActions from "../../../settings/modules/my-profile/store/actions/my-profile.actions";
import { loadClientTypeByClientIdSuccess } from "src/app/modules/client/store/actions/client.actions";
import { loadAllTimezonesSuccess } from "../actions/auth.actions";
import { PERMISSIONS } from "src/app/shared/enums";


export function authReducer(state: AV2AuthState, action: Action): AV2AuthState {
  return reducer(state, action);
}

export const reducer = createReducer(
  INITIAL_AUTH_STATE,
  on(AuthActions.signInSuccess, (state, action) => ({
    ...state,
    appUser: action.user,
    accessToken: action.accessToken,
    role: {
      ...action.role,
      // TODO: Line below for test
      // permissions:
      //   action.role.permissions
      // .filter(r => r.name !== PERMISSIONS.AV2LicensePermissionsEnum.LICENSE_READ_ROLE)
      // .filter(r => r.name !== PERMISSIONS.AV2AlarmPermissionsEnum.ALARM_READ_ROLE)
      // .filter(r => r.name !== PERMISSIONS.AV2PatrolPermissionsEnum.PATROL_READ_ROLE)
      // .filter(r => r.name !== PERMISSIONS.AV2SitePermissionsEnum.SITE_READ_ROLE)
      // .filter(r => r.name !== PERMISSIONS.AV2ClientUserPermissionsEnum.CLIENT_USER_READ_ROLE)
      // .filter(r => r.name !== PERMISSIONS.AV2DevicePermissionsEnum.DEVICE_READ_ROLE)
      // .filter(r => r.name !== PERMISSIONS.AV2ClientPermissionsEnum.CLIENT_READ_ROLE)
      // .filter(r => r.name !== PERMISSIONS.AV2TaskPermissionsEnum.TASK_READ_ROLE)
    },
    isInfobarVisible: false
  })),
  on(AuthActions.logoutSuccess,
    AuthActions.logoutFailed,
    (state, action) => ({
      ...state,
      appUser: INITIAL_AUTH_STATE.appUser,
      accessToken: INITIAL_AUTH_STATE.accessToken,
      role: INITIAL_AUTH_STATE.role
    })),
  on(AuthActions.refreshTokenSuccess, (state, action) => {
      return {
        ...state,
        accessToken: action.accessToken
      }
  }),
  on(AuthActions.checkResetPasswordUUIDSuccess, (state, action) => ({
    ...state,
    uuid: action.existUuid
  })),
  on(AuthActions.showAuthInfobar, (state, action) => ({
    ...state,
    isInfobarVisible: true
  })),
  on(MyProfileActions.updateProfileSettingsSuccess, (state, action) => ({
    ...state,
    appUser: {
      ...state.appUser,
      userSettingsDto: action.settings
    }
  })
  ),
  on(AuthActions.sendResetPasswordEmail, (state, action) => ({
    ...state,
    isInfobarVisible: false
  })),
  on(MyProfileActions.updateProfileImageSuccess, (state, action) => ({
    ...state,
    appUser: {
      ...state.appUser,
      photo: action.image
    }
  })),
  on(AuthActions.clearAuthData, (state, action) => ({
    ...state,
    clientType: null as any,
    accessToken: undefined
  })),
  on(loadClientTypeByClientIdSuccess, (state, action) => ({
    ...state,
    clientType: action.clientType
  })),
  on(loadAllTimezonesSuccess, (state, action) => ({
    ...state,
    timezones: action.timezones
  })),
  on(AuthActions.loadImpersonatedUserSuccess, (state, action) => ({
    ...state,
    impersonatedUserTechnicalName: action.impersonatedUserTechnicalName,
    impersonatedUserClientId: action.impersonatedUserClientId,
    impersonatedUserName: action.impersonatedUserName,
  })),
  on(AuthActions.clearImpersonatedUser, (state, action) => ({
    ...state,
    impersonatedUserTechnicalName: undefined,
    impersonatedUserClientId: undefined,
    impersonatedUserName: undefined,
  })),
  on(AuthActions.acceptConditionsSuccess, (state, action) => ({
    ...state,
    appUser: {
      ...state.appUser,
      acceptedTerms: true
    }
  }))
);
