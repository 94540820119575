<form [formGroup]="changePasswordFormGroup" class="form-container">
    <h2 class="form-title">Set new password</h2>
    <h5 class="form-body">The password must be at least 8 characters long and contain at least one number, a capital
        letter and a special character.</h5>
    <div class="change-password-input">
        <label for="newPassword">New password</label>
        <p-password formControlName="newPassword" class="password-input " [feedback]="false">
        </p-password>
        <app-error-validation [englishOnly]="true" [form]="changePasswordFormGroup" [controlName]="'newPassword'"></app-error-validation>
        <div class="repeat-password">
            <label for="newPasswordReply">Repeat new password</label>
            <p-password formControlName="newPasswordReply" class="password-input" [feedback]="false">
            </p-password>
            <app-error-validation [englishOnly]="true" [form]="changePasswordFormGroup" [controlName]="'newPasswordReply'">
            </app-error-validation>
        </div>
    </div>
    <button pButton pRipple type="submit" label="Reset password" class="change-password-button"
        [disabled]="changePasswordFormGroup.invalid" (click)="resetPassword()"></button>
    <div class="additional-field">
        <a class="av2-link-button" [av2routerLink]="NAVI.AUTH_SIGN_IN_PATH">
            Back to sing in.
        </a>
    </div>
</form>