
export default (o, c, f) => {

  c.prototype.forBackend = function(withCustomTimeZone = false) {
    if (withCustomTimeZone) {
      return this.tz(f.zoneId).format(`YYYY-MM-DD[T]HH:mm:ssZ[[${f.zoneId}]]`);
    }
    return this.utc().format();
  };

}
