import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import {Store} from '@ngrx/store';
import {signIn} from '../../store/actions/auth.actions';
import { NAVI } from 'src/app/commons/consts';
import {Observable} from 'rxjs';
import {getInfoBarVisibility} from '../../store/selectors/auth.selectors';
import {setTheme} from "../../../../shared/modules/ui/store/actions/ui.actions";
import {AV2Theme} from "../../../../commons/enums/theme";
import {ActivatedRoute} from "@angular/router";
import {AUTH_PATH, DASHBOARD_PATH} from "../../../../commons/consts/navigation.const";


@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  NAVI = NAVI;
  signInFormGroup?: UntypedFormGroup;
  infobarVisibility$? : Observable<boolean>;
  isLocalHost = false;

  constructor(private store$: Store,private route: ActivatedRoute,) {
    this.isLocalHost = window.location.hostname === 'localhost'
  }

  ngOnInit(): void {
    this.initForm();
  }

  private initForm() {
    this.store$.dispatch(setTheme({theme: AV2Theme.LIGHT}))
    this.signInFormGroup = new UntypedFormGroup({
      username: new UntypedFormControl('',Validators.required),
      password: new UntypedFormControl('',Validators.required)
    });
    this.infobarVisibility$ = this.store$.select(getInfoBarVisibility);
  }

  signIn() {
    if (this.signInFormGroup && this.signInFormGroup.valid) {
      const username = this.signInFormGroup.get('username')?.value;
      const password = this.signInFormGroup.get('password')?.value;
      let returnUrl = this.route.snapshot.queryParams['returnUrl'] || DASHBOARD_PATH;
      if ((returnUrl as string).startsWith(AUTH_PATH)) returnUrl = DASHBOARD_PATH;
      this.store$.dispatch(
        signIn({ username, password, returnUrl })
      );
    }
  }
}
