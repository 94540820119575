export default (o, c, f) => {

  c.prototype.setAppTimeMutable = function(hours, min, sec) {
    this.setAppHoursMutable(hours, min, sec);
  };
  c.prototype.setAppHoursMutable = function(hours, min = null, sec = null) {
    this.$d = this.clone().tz().set("h", hours).set("ms", 0).utc().toDate();
    this.init();
    this.$u = undefined;
    min !== null && this.setAppMinutesMutable(min, sec, true);
  };
  c.prototype.setAppMinutesMutable = function(min, sec = null, insideCall = false) {
    this.$d = this.clone().tz().set("m", min).set("ms", 0).utc().toDate();
    this.init();
    this.$u = undefined;
    sec !== null && this.setAppSecondsMutable(sec);
  };
  c.prototype.setAppSecondsMutable = function(sec) {
    this.$d = this.clone().set("s", sec).set("ms", 0).toDate();
    this.init();
    this.$u = undefined;
  };

  c.prototype.setAppTime = function(hours, min, sec) {
    let r = this.clone();
    r.setAppTimeMutable(hours, min, sec);
    return r;
  };
  c.prototype.setAppHours = function(hours, min = null, sec = null) {
    let r = this.clone();
    r.setAppHoursMutable(hours, min, sec);
    return r;
  };
  c.prototype.setAppMinutes = function(min, sec = null) {
    let r = this.clone();
    r.setAppMinutesMutable(min, sec);
    return r;
  };
  c.prototype.setAppSeconds = function(sec) {
    let r = this.clone();
    r.setAppSecondsMutable(sec);
    return r;
  };


  c.prototype.getAppHours = function() {
    return this.clone().tz().hour();
  };
  c.prototype.getAppMinutes = function() {
    return this.clone().tz().minute();
  };
  c.prototype.getAppSeconds = function() {
    return this.clone().tz().second();
  };

}
