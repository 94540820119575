import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'resolveUndefinedString'
})
export class ResolveUndefinedStringPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (value === undefined || value === null) return String()
    return String(value)
  }

}
