import {Injectable} from '@angular/core';
import dayjs from "dayjs";

@Injectable({
  providedIn: 'root'
})
export class EmployeeTimetableHelperService {

  static getWorkDurationString(endTime: Date, startTime: Date): string {
    let workingDayLength = '';

    const diff = EmployeeTimetableHelperService.calcDiff(endTime, startTime);

    let hoursAmount = Math.floor(diff / 60);
    if (hoursAmount == 24) hoursAmount = 0;
    let minutesAmount = Math.floor(diff % 60);
    workingDayLength = `${hoursAmount}h ${minutesAmount}min`;
    return workingDayLength;
  }

  static getWorkDurationNumber(endTime: Date, startTime: Date): number {
    return EmployeeTimetableHelperService.calcDiff(endTime, startTime);
  }

  static calcDiff(endTime: Date, startTime: Date): number {
    let diff = 0;
    if (startTime && endTime) {
      const start = dayjs(startTime);
      const end = dayjs(endTime);
      if (this.isOverlaping(start, end)) {
        diff = this.calculateOverlapingDiff(start, end);
      } else {
        diff = end.diff(start, 'minutes');
      }
    }
    return diff;
  }

  private static isOverlaping(start: dayjs.Dayjs, end: dayjs.Dayjs): boolean {
    return end.isBefore(start);
  }

  private static calculateOverlapingDiff(start: dayjs.Dayjs, end: dayjs.Dayjs): number {
    const diffH = (24 - start.hour() + end.hour()) * 60;
    let diffM = end.minute() - start.minute();
    if (start.minute() > end.minute()) {
      diffM = -(start.minute() - end.minute());
    }
    return diffH + diffM;
  }

}
