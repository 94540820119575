import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { InfoDialogService } from './info-dialog.service';
import {
  ConfirmDialogSubject,
  ConfirmRefreshSessionDialogSubject,
  ConfirmWithInputDialogSubject,
  InfoDialogSubject,
  InfoDialogType
} from './models/info-dialog';
import {CountdownData} from "../helpers/dateUtils";

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss']
})
export class InfoDialogComponent implements OnInit, OnDestroy {
  InfoDialogType = InfoDialogType;

  iconDictionaryPath: string = '../../assets/images/icons/';
  dialogVisibility: boolean = false;
  message: string = '';
  icon?: string;
  accept?: () => void;
  acceptWithInput?: (inputValue: string) => void;
  inputValue?: string;
  inputLabel?: string;
  inputMaxLength?: number;
  inputPlaceholder?: string;
  confirmLabel?: string;
  rejectLabel?: string;
  type?: InfoDialogType;
  requiredInput?: boolean = false;
  refreshSessionCountdownSub?: Subscription;
  refreshSessionCountdown?: CountdownData;
  countdownTitle?: string

  infoDialogSubscription?: Subscription;
  confirmDialogSubscription?: Subscription;
  confirmRefreshSessionDialogSubscription?: Subscription;
  confirmationWithInputDialogSubscription?: Subscription;

  constructor(private infoDialogService: InfoDialogService) { }

  ngOnInit(): void {
    this.addDialogListener();
  }

  private addDialogListener() {
    this.infoDialogSubscription = this.infoDialogService.infoDialog.subscribe(dialogData => this.showInfoDialog(dialogData));
    this.confirmDialogSubscription = this.infoDialogService.confirmation.subscribe(dialogData => this.showConfirmDialog(dialogData));
    this.confirmRefreshSessionDialogSubscription = this.infoDialogService.confirmationRefreshSession.subscribe(dialogData => this.showRefreshSessionConfirmDialog(dialogData))
    this.confirmationWithInputDialogSubscription = this.infoDialogService.confirmationWithInput.subscribe(dialogData => this.showConfirmWithInputDialog(dialogData));
  }

  private showInfoDialog(dialogData: InfoDialogSubject) {
    this.type = dialogData.type;
    this.message = dialogData.message;
    this.icon = dialogData.icon;
    this.dialogVisibility = true;
  }

  private showConfirmDialog(dialogData: ConfirmDialogSubject) {
    this.type = InfoDialogType.CONFIRM;
    this.message = dialogData.message;
    this.icon = dialogData.iconName;
    this.accept = dialogData.accept;
    this.rejectLabel = dialogData.rejectLabel;
    dialogData.confirmLabel && (this.confirmLabel = dialogData.confirmLabel);
    this.dialogVisibility = true;
  }

  private showRefreshSessionConfirmDialog(dialogData: ConfirmRefreshSessionDialogSubject) {
    this.type = InfoDialogType.CONFIRM_REFRESH_SESSION;
    this.refreshSessionCountdownSub = dialogData.counter.subscribe(counter => {
      this.refreshSessionCountdown = counter
      if(counter.minutes <= 0 && counter.seconds <= 0) {
        this.closeDialog()
      }
    })
    this.countdownTitle = dialogData.title;
    this.message = dialogData.message;
    this.icon = dialogData.iconName;
    this.accept = dialogData.accept;
    this.rejectLabel = dialogData.rejectLabel;
    dialogData.confirmLabel && (this.confirmLabel = dialogData.confirmLabel);
    this.dialogVisibility = true;
  }

  private showConfirmWithInputDialog(dialogData: ConfirmWithInputDialogSubject) {
    this.type = InfoDialogType.CONFIRM_WITH_INPUT;
    this.message = dialogData.message;
    this.icon = dialogData.iconName;
    this.acceptWithInput = dialogData.accept;
    this.inputLabel = dialogData.inputLabel;
    this.inputPlaceholder = dialogData.inputePlaceholder;
    this.inputMaxLength = dialogData.inputMaxLength;
    dialogData.rejectLabel && (this.rejectLabel = dialogData.rejectLabel);
    dialogData.confirmLabel && (this.confirmLabel = dialogData.confirmLabel);
    this.dialogVisibility = true;
    this.requiredInput = dialogData.requiredInput;
  }

  confirm() {
    this.accept && this.accept();
    this.closeDialog();
  }

  confirmWithInput() {
    if (this.requiredInput && !this.inputValue)
      return;

    this.acceptWithInput && this.acceptWithInput(this.inputValue ?? '');
    this.closeDialog();
  }

  closeDialog() {
    this.countdownTitle = undefined
    this.refreshSessionCountdown = undefined
    this.inputValue = '';
    this.dialogVisibility = false;
    this.refreshSessionCountdownSub?.unsubscribe()
  }

  ngOnDestroy() {
    this.inputValue = '';
    this.confirmDialogSubscription && this.confirmDialogSubscription.unsubscribe();
    this.infoDialogSubscription && this.infoDialogSubscription.unsubscribe();
    this.confirmationWithInputDialogSubscription && this.confirmationWithInputDialogSubscription.unsubscribe();
    this.confirmRefreshSessionDialogSubscription?.unsubscribe();
    this.refreshSessionCountdownSub?.unsubscribe();
  }

}
